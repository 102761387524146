import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { debounceTime, map } from "rxjs/operators";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { Member } from "../../../core/models/members/member";
import { State } from "../../../core/models/masters/state";
import { District } from "../../../core/models/masters/district";
import { Qualification } from "../../../core/models/masters/qualification";
import { Occupation } from "../../../core/models/masters/occupation";
import { MembersService } from "../../../core/services/members/members.service";
import { StatesService } from "../../../core/services/masters/states.service";
import { DistrictsService } from "../../../core/services/masters/districts.service";
import { QualificationsService } from "../../../core/services/masters/qualifications.service";
import { OccupationsService } from "../../../core/services/masters/occupations.service";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MembersList } from "../../../core/models/reports/members/members-list";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-memebers-list-by-kuwait",
  templateUrl: "./memebers-list-by-kuwait.component.html",
  styleUrls: ["./memebers-list-by-kuwait.component.css"],
})
export class MemebersListByKuwaitComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  areas$: Observable<State[]>;
  blocks$: Observable<District[]>;
  qualifications$: Observable<Qualification[]>;
  occupations$: Observable<Occupation[]>;
  memberList: MembersList[];
  listCount: any;

  userDetails: UserDetails;
  menuId: number;

  memebersListByKuwaitForm = new FormGroup({
    Area: new FormControl(0),
    AreaName: new FormControl(""),
    Block: new FormControl(0),
    BlockName: new FormControl(""),
    Qualification: new FormControl(0),
    QualificationName: new FormControl(""),
    Occupation: new FormControl(0),
    OccupationName: new FormControl(""),
    Status: new FormControl(""),
  });

  Status = [
    { Status: "", StatusName: "All" },
    { Status: "A", StatusName: "Active" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "E", StatusName: "Expired" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  constructor(
    private memberService: MembersService,
    private stateService: StatesService,
    private districtService: DistrictsService,
    private qualificationService: QualificationsService,
    private occupationServive: OccupationsService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.areas$ = this.stateService.getStates();
    this.qualifications$ = this.qualificationService.getQualifications();
    this.occupations$ = this.occupationServive.getOccupations();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 81600;

    this.listCount = 0;
  }

  getBlocks(area: number) {
    this.blocks$ = this.districtService.getStateDistricts(area);
  }
  resetFilters() {
    this.memebersListByKuwaitForm.reset({
      Area: 0,
      AreaName: "",
      Block: 0,
      BlockName: "",
      Qualification: 0,
      QualificationName: "",
      Occupation: 0,
      OccupationName: "",
      Status: "",
    });
  }

  getMemberListByKuwait() {
    this.spinnerService.show();
    this.reportGeneratorService
      .getMemberListByKuwait(
        this.memebersListByKuwaitForm.value.Area,
        this.memebersListByKuwaitForm.value.Block,
        this.memebersListByKuwaitForm.value.Qualification,
        this.memebersListByKuwaitForm.value.Occupation,
        this.memebersListByKuwaitForm.value.Status
      )
      .subscribe(
        (response: MembersList[]) => {
          this.memberList = response;
          this.listCount = this.memberList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members List By Kuwait");

    XLSX.writeFile(wb, "Members List By Kuwait.xlsx");
  }
}
