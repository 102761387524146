import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { Country } from "../../../core/models/masters/country";
import { State } from "../../../core/models/masters/state";
import { District } from "../../../core/models/masters/district";
import { Panchayath } from "../../../core/models/masters/panchayath";
import { PostOffice } from "../../../core/models/masters/post-office";
import { MembersService } from "../../../core/services/members/members.service";
import { CountriesService } from "../../../core/services/masters/countries.service";
import { StatesService } from "../../../core/services/masters/states.service";
import { DistrictsService } from "../../../core/services/masters/districts.service";
import { PanchayathsService } from "../../../core/services/masters/panchayaths.service";
import { PostOfficesService } from "../../../core/services/masters/post-offices.service";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MembersList } from "../../../core/models/reports/members/members-list";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-memebers-list-by-india",
  templateUrl: "./memebers-list-by-india.component.html",
  styleUrls: ["./memebers-list-by-india.component.css"],
})
export class MemebersListByIndiaComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  countries$: Observable<Country[]>;
  states$: Observable<State[]>;
  districts$: Observable<District[]>;
  panchayaths$: Observable<Panchayath[]>;
  postoffices$: Observable<PostOffice[]>;
  listCount: any;

  memberList: MembersList[];
  userDetails: UserDetails;
  menuId: number;

  memebersListByIndiaForm = new FormGroup({
    Country: new FormControl(0),
    CountryName: new FormControl(""),
    State: new FormControl(0),
    StateName: new FormControl(""),
    District: new FormControl(0),
    DistrictName: new FormControl(""),
    Panchayath: new FormControl(0),
    PanchayathName: new FormControl(""),
    PostOffice: new FormControl(0),
    PostOfficeName: new FormControl(""),
    Status: new FormControl(""),
  });

  Status = [
    { Status: "X", StatusName: "All" },
    { Status: "A", StatusName: "Active" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "E", StatusName: "Expired" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  constructor(
    private memberService: MembersService,
    private countryService: CountriesService,
    private stateService: StatesService,
    private districtService: DistrictsService,
    private panchayathService: PanchayathsService,
    private postOfficeService: PostOfficesService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.countries$ = this.countryService.getCountries();
    this.postoffices$ = this.postOfficeService.getPostoffices();
    this.postoffices$ = this.postOfficeService.getDistrictPostoffices(0);
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 81500;

    this.listCount = 0;
  }
  getStates(country: number) {
    this.states$ = this.stateService.getCountryStates(country);
    this.districts$ = this.districtService.getStateDistricts(0);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
    this.postoffices$ = this.postOfficeService.getDistrictPostoffices(0);
  }

  getDistricts(state: number) {
    this.districts$ = this.districtService.getStateDistricts(state);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
    this.postoffices$ = this.postOfficeService.getDistrictPostoffices(0);
  }

  getPanchayaths(district: number) {
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(district);
    this.postoffices$ = this.postOfficeService.getDistrictPostoffices(district);
  }

  resetFilters() {
    this.memebersListByIndiaForm.reset({
      Country: 0,
      CountryName: "",
      State: 0,
      StateName: "",
      District: 0,
      DistrictName: "",
      Panchayath: 0,
      PanchayathName: "",
      PostOffice: 0,
      PostOfficeName: "",
      Status: "",
    });
  }

  getMemberListByOrg() {
    this.spinnerService.show();
    this.reportGeneratorService
      .getMemberListByInd(
        this.memebersListByIndiaForm.value.Country,
        this.memebersListByIndiaForm.value.State,
        this.memebersListByIndiaForm.value.District,
        this.memebersListByIndiaForm.value.Panchayath,
        this.memebersListByIndiaForm.value.PostOffice,
        this.memebersListByIndiaForm.value.Status
      )
      .subscribe(
        (response: MembersList[]) => {
          this.memberList = response;
          this.listCount = this.memberList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members List By India");

    XLSX.writeFile(wb, "Members List By India.xlsx");
  }
}
