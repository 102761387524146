import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import { Occupation } from "../../core/models/masters/occupation";
import { OccupationsService } from "../../core/services/masters/occupations.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-occupation",
  templateUrl: "./occupation.component.html",
  styleUrls: ["./occupation.component.css"],
})
export class OccupationComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;

  occupationForm = new FormGroup({
    Id: new FormControl(0),
    Name: new FormControl(""),
    Active: new FormControl(true),
  });

  constructor(
    private occupationService: OccupationsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 19400;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getOccupation(id);
      }
    });
  }

  cancelOccupation() {
    this.router.navigate(["./", { outlets: { pages: ["occupations"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getOccupation(Id: number) {
    this.occupationService.getOccupation(Id).subscribe(
      (occupation: Occupation) => this.editOccupation(occupation),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["occupations"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editOccupation(occupation: Occupation) {
    this.occupationForm.patchValue({
      Id: occupation.Id,
      Name: occupation.Name,
      Active: occupation.Active,
    });
  }

  saveOccupation(reset: boolean) {
    const occupation: Occupation = this.occupationForm.value as Occupation;
    if (occupation.Id < 1) {
      console.log(occupation);
      this.occupationService.addOccupation(occupation).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetOccupation();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["occupations"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.occupationService.modifyOccupation(occupation).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetOccupation();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["occupations"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetOccupation() {
    this.occupationForm.reset({
      Id: 0,
      Name: "",
      Active: true,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.occupationForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.occupationForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
