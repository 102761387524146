import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Talent } from '../../models/masters/talent';
import { HttpResponse } from '../../models/utils/http-response';

@Injectable({
  providedIn: 'root'
})
export class TalentsService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getTalents(): Observable<Talent[]> {
    return this.http.get<Talent[]>(environment.root + '/Talent');
  }

  getTalentsForMember(): any {
    return this.http.get(environment.root + '/Talent');
  }

  getTalent(id: number): Observable<Talent> {
    return this.http.get<Talent>(environment.root + '/Talent/' + id);
  }

  addTalent(talent: Talent): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Talent/Add', talent, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyTalent(talent: Talent): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Talent/Modify', talent, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteTalent(id: number): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Talent/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getTalentBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('searchBy', searchBy.toString())
      .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Talents: Talent[]}>
      (environment.root + '/Talent/GetAllTalentBySearch', { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
