import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Zone } from '../../models/masters/zone';
import { HttpResponse } from '../../models/utils/http-response';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getZones(): Observable<Zone[]> {
    return this.http.get<Zone[]>(environment.root + '/Zone');
  }

  getZone(id: number): Observable<Zone> {
    return this.http.get<Zone>(environment.root + '/Zone/' + id);
  }

  addZone(zone: Zone): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Zone/Add', zone, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyZone(zone: Zone): Observable<HttpResponse>  {
    return this.http.post<HttpResponse>(environment.root + '/Zone/Modify', zone, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteZone(id: number): Observable<HttpResponse>  {
    return this.http.post<HttpResponse>(environment.root + '/Zone/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getZoneBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('searchBy', searchBy.toString())
      .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Zones: Zone[]}>
      (environment.root + '/Zone/GetAllZoneBySearch', { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

}
