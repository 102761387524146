import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MembersService } from "../../services/members/members.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  userName = "Administrator";

  profileImage: SafeResourceUrl;

  constructor(
    private memberService: MembersService,
    private readonly sanitizer: DomSanitizer
  ) {
    this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
      "../../../../assets/images/avatar.png"
    );
  }

  ngOnInit() {
    this.userName = JSON.parse(localStorage.getItem("userDetails")).Name;
    this.downloadProfilePicture();
  }

  downloadProfilePicture() {
    let attachType: string;
    let attachName: string;

    if (parseInt(JSON.parse(localStorage.getItem("userDetails")).Member) > 0) {
      this.memberService
        .downloadProfilePicture(
          JSON.parse(localStorage.getItem("userDetails")).Member
        )
        .subscribe((response: any) => {
          console.log(response);
          const resBlob = new Blob([response], { type: "image/jpeg" });
          this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(resBlob)
          );
        });
    }
  }
}
