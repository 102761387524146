import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { Branch } from "../../../core/models/masters/branch";
import { Unit } from "../../../core/models/masters/unit";
import { MembersService } from "../../../core/services/members/members.service";
import { ZonesService } from "../../../core/services/masters/zones.service";
import { BranchesService } from "../../../core/services/masters/branches.service";
import { UnitsService } from "../../../core/services/masters/units.service";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MembersList } from "../../../core/models/reports/members/members-list";
import { MemberList } from "src/app/core/models/members/member-List";
import { Qualification } from "src/app/core/models/masters/qualification";
import { Occupation } from "src/app/core/models/masters/occupation";
import { QualificationsService } from "src/app/core/services/masters/qualifications.service";
import { OccupationsService } from "src/app/core/services/masters/occupations.service";
import { District } from "src/app/core/models/masters/district";
import { Panchayath } from "src/app/core/models/masters/panchayath";
import { PostOffice } from "src/app/core/models/masters/post-office";
import { PostOfficesService } from "src/app/core/services/masters/post-offices.service";
import { DistrictsService } from "src/app/core/services/masters/districts.service";
import { PanchayathsService } from "src/app/core/services/masters/panchayaths.service";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Zone } from "src/app/core/models/masters/zone";
import { StatesService } from "src/app/core/services/masters/states.service";
import { State } from "src/app/core/models/masters/state";

@Component({
  selector: "app-member-details-kerala",
  templateUrl: "./member-details-kerala.component.html",
  styleUrls: ["./member-details-kerala.component.css"],
})
export class MemberDetailsKeralaComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  states$: Observable<State[]>;
  districts$: Observable<District[]>;
  panchayaths$: Observable<Panchayath[]>;
  postoffices$: Observable<PostOffice[]>;

  userDetails: UserDetails;
  menuId: number;
  memberList: MembersList[];
  listCount: any;

  memebersListForm = new FormGroup({
    Zone: new FormControl(0),
    ZoneName: new FormControl(""),
    Branch: new FormControl(0),
    BranchName: new FormControl(""),
    State: new FormControl(0),
    StateName: new FormControl(""),
    District: new FormControl(0),
    DistrictName: new FormControl(""),
    Panchayath: new FormControl(0),
    PanchayathName: new FormControl(""),
    PostOffice: new FormControl(0),
    PostOfficeName: new FormControl(""),
  });

  constructor(
    private memberService: MembersService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private stateService: StatesService,
    private districtService: DistrictsService,
    private panchayathService: PanchayathsService,
    private postofficeService: PostOfficesService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.zones$ = this.zoneService.getZones();
    this.states$ = this.stateService.getCountryStates(1);
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 80200;

    this.listCount = 0;
  }

  resetFilters() {
    this.memebersListForm.reset({
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      State: 0,
      StateName: "",
      District: 0,
      DistrictName: "",
      Panchayath: 0,
      PanchayathName: "",
      PostOffice: 0,
      PostOfficeName: "",
    });
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
  }

  getDistrincts(state: number) {
    this.districts$ = this.districtService.getStateDistricts(state);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
    this.postoffices$ = this.postofficeService.getDistrictPostoffices(0);
  }

  getPanchayathsAndPO(district: number) {
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(district);
    this.postoffices$ = this.postofficeService.getDistrictPostoffices(district);
  }

  getMemberDetailsKerala() {
    this.spinnerService.show();
    this.reportGeneratorService
      .GetMemberDetailsKerala(
        this.memebersListForm.value.Zone,
        this.memebersListForm.value.Branch,
        this.memebersListForm.value.State,
        this.memebersListForm.value.District,
        this.memebersListForm.value.Panchayath,
        this.memebersListForm.value.PostOffice
      )
      .subscribe(
        (response: MembersList[]) => {
          this.memberList = response;
          this.listCount = this.memberList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Member Details India");

    XLSX.writeFile(wb, "Member Details India.xlsx");
  }
}
