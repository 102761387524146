import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { UserDetails } from "src/app/core/models/system/user-details";
import { concat, Observable, of, Subject } from "rxjs";
import { Branch } from "src/app/core/models/masters/branch";
import { Unit } from "src/app/core/models/masters/unit";
import { MemberList } from "src/app/core/models/members/member-List";
import { BranchesService } from "src/app/core/services/masters/branches.service";
import { UnitsService } from "src/app/core/services/masters/units.service";
import { MembersService } from "src/app/core/services/members/members.service";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { Fbs } from "src/app/core/models/schemes/fbs";
import { Membership } from "src/app/core/models/schemes/membership";
import { Fine } from "src/app/core/models/schemes/fine";
import { FbsService } from "src/app/core/services/schemes/fbs.service";
import { FineService } from "src/app/core/services/schemes/fine.service";
import { MembershipsService } from "src/app/core/services/schemes/memberships.service";
import { SchemeSummary } from "src/app/core/models/reports/fbs/scheme-summary";
import { SchemeSummarySearch } from "src/app/core/models/reports/fbs/scheme-summary-search";
import { Zone } from "src/app/core/models/masters/zone";
import { ZonesService } from "src/app/core/services/masters/zones.service";

@Component({
  selector: "app-scheme-summary",
  templateUrl: "./scheme-summary.component.html",
  styleUrls: ["./scheme-summary.component.css"],
})
export class SchemeSummaryComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  receiptList: SchemeSummary[];
  listCount: any;
  userDetails: UserDetails;
  menuId: number;
  public schemeSummaryForm: FormGroup;

  Referrer: number;
  ReferrerName: string;
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  fbss$: Observable<Fbs[]>;
  memberships$: Observable<Membership[]>;
  fines$: Observable<Fine[]>;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  Type = [
    { Type: "S", TypeName: "Fbs" },
    { Type: "M", TypeName: "Membership" },
    { Type: "F", TypeName: "Fine" },
  ];

  constructor(
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private memberService: MembersService,
    private fbsService: FbsService,
    private zoneService: ZonesService,
    private membershipService: MembershipsService,
    private fineService: FineService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 82700;
    this.zones$ = this.zoneService.getZones();
    this.fbss$ = this.fbsService.getFbses();
    this.memberships$ = this.membershipService.getMemberships();
    this.fines$ = this.fineService.getFines();

    this.loadStarClubMembers();
    this.listCount = 0;

    this.schemeSummaryForm = new FormGroup({
      Zone: new FormControl(0),
      ZoneName: new FormControl(""),
      Branch: new FormControl(0),
      BranchName: new FormControl(""),
      Unit: new FormControl(0),
      UnitName: new FormControl(""),
      Referrer: new FormControl({ Id: 0, Name: "" }),
      ReferrerName: new FormControl(""),
      Type: new FormControl(""),
      TypeId: new FormControl(0),
      TypeIdName: new FormControl(""),
    });
  }

  resetFilters() {
    this.schemeSummaryForm.reset({
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      Unit: 0,
      UnitName: "",
      Referrer: { Id: 0, Name: "" },
      ReferrerName: "",
      Type: "S",
      TypeId: 0,
      TypeIdName: "",
    });
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }

  getSchemeSummary() {
    if (this.schemeSummaryForm.value.Referrer === null) {
      this.schemeSummaryForm.value.Referrer = { Id: 0, Name: "" };
    } else {
      if (this.schemeSummaryForm.value.Referrer === 0) {
        this.schemeSummaryForm.value.Referrer = { Id: 0, Name: "" };
      }
    }

    const searchFields: SchemeSummarySearch = {
      Zone: this.schemeSummaryForm.value.Zone,
      Branch: this.schemeSummaryForm.value.Branch,
      Unit: this.schemeSummaryForm.value.Unit,
      Referrer: this.schemeSummaryForm.value.Referrer.Id,
      Type: this.schemeSummaryForm.value.Type,
      TypeId: this.schemeSummaryForm.value.TypeId,
    };
    this.spinnerService.show();
    this.reportGeneratorService.getSchemeSummary(searchFields).subscribe(
      (response: SchemeSummary[]) => {
        this.receiptList = response;
        this.listCount = this.receiptList.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Scheme Summary");

    XLSX.writeFile(wb, "Scheme Summary.xlsx");
  }
}
