import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ReceiptHistoryComponent } from "./receipt-history/receipt-history.component";
import { CoreModule } from "src/app/core/core.module";
import { MemberDetailMembershipFbsComponent } from "./member-detail-membership-fbs/member-detail-membership-fbs.component";

@NgModule({
  declarations: [ReceiptHistoryComponent, MemberDetailMembershipFbsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
})
export class ReceiptsModule {}
