import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Member } from "../../models/members/member";
import { StarClubMember } from "../../models/members/star-club-member";
import { CivilIDDetails } from "../../models/members/civil-id-details";
import { MemberTalent } from "../../models/members/member";
import { MemberList } from "../../models/members/member-List";
import { MemberTalentList } from "../../models/members/member-talent-list";
import { StatusUpdate } from "./status-update";

@Injectable({
  providedIn: "root",
})
export class MembersService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private httpExportOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    responseType: "blob" as const,
  };

  constructor(private http: HttpClient) {}

  getMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(environment.root + "/Member");
  }

  getAllStarClubMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(
      environment.root + "/Member/GetStarClubMembers"
    );
  }
  getNormalMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(
      environment.root + "/Member/GetNormalMembers"
    );
  }

  getStarClubMembers(): Observable<StarClubMember[]> {
    return this.http.get<StarClubMember[]>(
      environment.root + "/Member/GetStarClubMembers"
    );
  }

  getAllMembersList(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/Member/GetAllMembersList/",
      { params }
    );
  }

  GetAllMembersListForTransfer(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/Member/GetAllMembersListForTransfer/",
      { params }
    );
  }

  getStarClubMembersList(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/Member/GetStarClubMembersList/",
      { params }
    );
  }

  getExpiredMembersList(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/Member/GetExpiredMembersList/",
      { params }
    );
  }

  getMemberDetailsWithFamily(memberId: number): Observable<any> {
    const params = new HttpParams().set("memberId", memberId.toString());
    return this.http.get<any>(
      environment.root + "/Member/GetMemberDetailsWithFamilyKKMA/",
      {
        params,
      }
    );
  }

  getMemberTalentList(member: number): Observable<MemberTalentList[]> {
    const params = new HttpParams().set("member", member.toString());
    return this.http.get<MemberTalentList[]>(
      environment.root + "/Member/GetMemberTalentList/",
      { params }
    );
  }

  getMember(id: number): Observable<Member> {
    return this.http.get<Member>(environment.root + "/Member/" + id);
  }

  getSubMembers(id: number): Observable<Member[]> {
    const params = new HttpParams().set("member", id.toString());
    return this.http.get<Member[]>(
      environment.root + "/Member/GetSubMembers/",
      { params }
    );
  }

  getStarClub(id: number): Observable<Member> {
    return this.http.get<Member>(environment.root + "/Member/" + id);
  }

  getKKMAId(branch: number): Observable<CivilIDDetails> {
    const params = new HttpParams().set("branch", branch.toString());
    return this.http.get<CivilIDDetails>(
      environment.root + "/Member/GetKKMAId",
      { params }
    );
  }

  getMemberTalents(member: number) {
    const params = new HttpParams().set("member", member.toString());
    return this.http.get<{ Talents: MemberTalent[] }>(
      environment.root + "/Member/GetAllMemberTalents",
      { params }
    );
  }

  getMembersBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      Members: Member[];
    }>(environment.root + "/Member/GetAllMembersBySearch", { params });
  }

  getStarClubMembersBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      Members: Member[];
    }>(environment.root + "/Member/GetAllStarClubMembersBySearch", { params });
  }

  addMember(member: Member) {
    return this.http
      .post(environment.root + "/Member/Add", member, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyMember(member: Member) {
    return this.http
      .post(environment.root + "/Member/Modify", member, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyStatus(member: StatusUpdate) {
    return this.http
      .post(environment.root + "/Member/ModifyStatus", member, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteMember(id: number) {
    return this.http
      .post(environment.root + "/Member/Delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyStarClub(member: StarClubMember) {
    return this.http
      .post(
        environment.root + "/Member/ModifyStarClub",
        member,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  downloadProfilePicture(member: number) {
    return this.http
      .post(
        environment.root + "/Member/DownloadProfilePicture/" + member,
        {},
        this.httpExportOptions
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(error);
    }
    return throwError(error.error.message);
  }
}
