import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Scheme } from '../../models/masters/scheme';
import { HttpResponse } from '../../models/utils/http-response';

@Injectable({
  providedIn: 'root'
})
export class SchemesService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getSchemes(): Observable<Scheme[]> {
    return this.http.get<Scheme[]>(environment.root + '/Scheme');
  }

  getScheme(id: number): Observable<Scheme> {
    return this.http.get<Scheme>(environment.root + '/Scheme/' + id);
  }

  addScheme(scheme: Scheme): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Scheme/Add', scheme, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyScheme(scheme: Scheme): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Scheme/Modify', scheme, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteScheme(id: number): Observable<HttpResponse> {
    return this.http.post<HttpResponse>(environment.root + '/Scheme/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSchemeBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('searchBy', searchBy.toString())
      .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Schemes: Scheme[]}>
      (environment.root + '/Scheme/GetAllSchemeBySearch', { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
