import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Fbs } from '../../models/schemes/fbs';
import { StatusUpdate } from '../members/status-update';

@Injectable({
  providedIn: 'root'
})
export class FbsService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getFbses(): Observable<Fbs[]> {
    return this.http.get<Fbs[]>(environment.root + '/Fbs');
  }

  getFbs(id: number): Observable<Fbs> {
    return this.http.get<Fbs>(environment.root + '/Fbs/' + id);
  }

  getFbssBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('pageSize', pageSize.toString())
    .set('searchBy', searchBy.toString())
    .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Fbss: Fbs[]}>
          (environment.root + '/Fbs/GetAllGetFbssBySearch', { params });
  }

  addFbs(fbs: Fbs) {
    return this.http.post(environment.root + '/Fbs/Add', fbs, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyFbs(fbs: Fbs) {
    return this.http.post(environment.root + '/Fbs/Modify', fbs, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  modifyStatus(transfer: StatusUpdate) {
    return this.http.post(environment.root + '/Fbs/ModifyStatus', transfer, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteFbs(id: number) {
    return this.http.post(environment.root + '/Fbs/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
