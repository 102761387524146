import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";

import { UsersService } from "./../../services/system/users.service";
import { HttpResponse, HttpResult } from "../../models/utils/http-response";
import { UserDetails } from "../../models/system/user-details";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  userDetails: UserDetails;

  loginForm = new FormGroup({
    Id: new FormControl(""),
    Password: new FormControl(""),
  });

  constructor(private userService: UsersService, private router: Router) {}

  ngOnInit() {}

  onSubmit() {
    this.userService.login(this.loginForm.value).subscribe(
      (res: HttpResponse) => {
        if (res.result === HttpResult.Success) {
          localStorage.setItem("userDetails", JSON.stringify(res.message));
          this.userDetails = JSON.parse(JSON.stringify(res.message));
          console.log(res.message);
          if (this.userDetails.ResetPasswordOnLogin) {
            this.router.navigateByUrl("home/(pages:change-password)");
          } else {
            this.router.navigateByUrl("home/(pages:dashboard)");
          }
        } else {
          this.displayHTMLFeedback("warning", res.result + "!", res.message);
        }
      },
      (err) => {
        if (err.status === 400) {
          this.displayFeedback(
            "warning",
            "Incorrect username or password",
            "Authentication failed.!"
          );
        } else {
          this.displayFeedback("error", "Error!", err);
        }
      }
    );
  }

  resetLogin() {
    this.loginForm.reset({
      Id: "",
      Password: "",
    });
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
