import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { StatusUpdate } from "./status-update";
import {
  RejoinMember,
  RejoinMemberView,
} from "../../models/members/rejoin-member";
import { RejoinMemberInvoices } from "../../models/members/rejoin-member-invoices";

@Injectable({
  providedIn: "root",
})
export class RejoinMemberService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private httpExportOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    responseType: "blob" as const,
  };

  constructor(private http: HttpClient) {}

  getRejoinRejoinMember(): Observable<RejoinMember[]> {
    return this.http.get<RejoinMember[]>(environment.root + "/RejoinMember");
  }

  getRejoinMember(id: number): Observable<RejoinMember> {
    return this.http.get<RejoinMember>(
      environment.root + "/RejoinMember/" + id
    );
  }

  generateInvoiceForRejoinMember(
    member: number
  ): Observable<RejoinMemberInvoices> {
    const params = new HttpParams().set("member", member.toString());
    return this.http.get<RejoinMemberInvoices>(
      environment.root + "/RejoinMember/GetRejoinMemberInvoices/",
      { params }
    );
  }

  addRejoinMember(member: RejoinMember) {
    return this.http
      .post(environment.root + "/RejoinMember/Add", member, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyRejoinMember(member: RejoinMember) {
    return this.http
      .post(environment.root + "/RejoinMember/Modify", member, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyStatus(member: StatusUpdate) {
    return this.http
      .post(
        environment.root + "/RejoinMember/ModifyStatus",
        member,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  deleteRejoinMember(id: number) {
    return this.http
      .post(environment.root + "/RejoinMember/Delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getRejoinMembersBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      RejoinMembers: RejoinMemberView[];
    }>(environment.root + "/RejoinMember/GetAllRejoinMemberBySearch", {
      params,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(error);
    }
    return throwError(error.error.message);
  }
}
