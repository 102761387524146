import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MembershipComponent } from "./membership/membership.component";
import { MembershipsComponent } from "./memberships/memberships.component";
import { FbsComponent } from "./fbs/fbs.component";
import { FbssComponent } from "./fbss/fbss.component";
import { FineComponent } from "./fine/fine.component";
import { FinesComponent } from "./fines/fines.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    MembershipComponent,
    MembershipsComponent,
    FbsComponent,
    FbssComponent,
    FineComponent,
    FinesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    CoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [MembershipsComponent, FbssComponent, FinesComponent],
})
export class SchemesModule {}
