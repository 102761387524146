import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Transfer } from "../../models/members/transfer";
import { StatusUpdate } from "./status-update";

@Injectable({
  providedIn: "root",
})
export class TransfersService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getTransfers(): Observable<Transfer[]> {
    return this.http.get<Transfer[]>(environment.root + "/Transfer");
  }

  getTransfer(id: number): Observable<Transfer> {
    return this.http.get<Transfer>(environment.root + "/Transfer/" + id);
  }

  getTransfersBySearch(page, pageSize, searchBy, searchText, type) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString())
      .set("type", type.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      Transfers: Transfer[];
    }>(environment.root + "/Transfer/GetAllGetTransfersBySearch", { params });
  }

  addTransfer(transfer: Transfer) {
    return this.http
      .post(environment.root + "/Transfer/Add", transfer, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyTransfer(transfer: Transfer) {
    return this.http
      .post(environment.root + "/Transfer/Modify", transfer, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyStatus(transfer: StatusUpdate) {
    return this.http
      .post(
        environment.root + "/Transfer/ModifyStatus",
        transfer,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  deleteTransfer(id: number) {
    return this.http
      .post(environment.root + "/Transfer/Delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError("Something bad happened; please try again later.");
  }
}
