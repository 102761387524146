import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { FbsHistory } from "../../../core/models/reports/schemes/fbs-history";
import { FbsHistorySearch } from "src/app/core/models/reports/schemes/fbs-history-search";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-fbs-history",
  templateUrl: "./fbs-history.component.html",
  styleUrls: ["./fbs-history.component.css"],
})
export class FbsHistoryComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  FromDate: NgbDateStruct;
  ToDate: NgbDateStruct;
  fbsList: FbsHistory[];
  listCount: any;

  userDetails: UserDetails;
  menuId: number;

  fbsHistoryForm = new FormGroup({
    FromDate: new FormControl(new Date()),
    ToDate: new FormControl(new Date()),
    Status: new FormControl(""),
  });

  Status = [
    { Status: "", StatusName: "All" },
    { Status: "O", StatusName: "Open" },
    { Status: "A", StatusName: "Approved" },
    { Status: "C", StatusName: "Closed" },
    { Status: "L", StatusName: "Cancelled" },
  ];

  constructor(
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.FromDate = this.calendar.getToday();
    this.ToDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 81900;

    this.listCount = 0;
  }

  resetFilters() {
    this.fbsHistoryForm.reset({
      FromDate: new Date(),
      ToDate: new Date(),
      Status: "",
    });
  }

  getFbsHistory() {
    const searchFields: FbsHistorySearch = {
      FromDate: this.fbsHistoryForm.value.FromDate,
      ToDate: this.fbsHistoryForm.value.ToDate,
      Status: this.fbsHistoryForm.value.Status,
    };
    this.spinnerService.show();
    this.reportGeneratorService.getFbsHistory(searchFields).subscribe(
      (response: FbsHistory[]) => {
        this.fbsList = response;
        this.listCount = this.fbsList.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FBS History");

    XLSX.writeFile(wb, "FBS History.xlsx");
  }
}
