import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import Swal, { SweetAlertType } from 'sweetalert2';
import { Country } from '../../core/models/masters/country';
import { CountriesService } from '../../core/services/masters/countries.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;

  countryForm = new FormGroup({
    Id: new FormControl(0),
    Code: new FormControl(''),
    Name: new FormControl(''),
    Active: new FormControl(true)
  });

  constructor(private countryService: CountriesService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 14000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      if (id) {
        this.getCountry(id);
      }
    });
  }

  cancelCountry() {
    this.router.navigate(['./', { outlets: { pages: ['countries']} }], { relativeTo: this.route.parent });
  }

  getCountry(Id: number) {
    this.countryService.getCountry(Id).subscribe(
      (country: Country) => this.editCountry(country),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['countries']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editCountry(country: Country) {
    this.countryForm.patchValue({
      Id: country.Id,
      Code: country.Code,
      Name: country.Name,
      Active: country.Active
    });
  }

  saveCountry(reset: boolean) {
    const country: Country = this.countryForm.value as Country;
    if (country.Id < 1) {
      this.countryService.addCountry(country)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetCountry();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['countries']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.countryService.modifyCountry(country)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetCountry();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['countries']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  resetCountry() {
    this.countryForm.reset({
      Id: 0,
      Code: '',
      Name: '',
      Active: true
    });
  }

  hasAdd() {
    let privilage = false;

      this.userDetails.Menus.forEach(value => {
         if (value.Id === this.menuId) {
           if(value.PrivilegeAdd && this.countryForm.value.Id < 1)
              privilage = true;
            else if(value.PrivilegeModify && this.countryForm.value.Id > 0)
              privilage = true;
         }
      });

      return privilage;
    }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
