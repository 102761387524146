import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Membership } from '../../models/schemes/membership';
import { StatusUpdate } from '../members/status-update';

@Injectable({
  providedIn: 'root'
})
export class MembershipsService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getMemberships(): Observable<Membership[]> {
    return this.http.get<Membership[]>(environment.root + '/Membership');
  }

  getMembership(id: number): Observable<Membership> {
    return this.http.get<Membership>(environment.root + '/Membership/' + id);
  }

  getMembershipsBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('pageSize', pageSize.toString())
    .set('searchBy', searchBy.toString())
    .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Memberships: Membership[]}>
          (environment.root + '/Membership/GetAllGetMembershipsBySearch', { params });
  }

  addMembership(membership: Membership) {
    return this.http.post(environment.root + '/Membership/Add', membership, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyMembership(membership: Membership) {
    return this.http.post(environment.root + '/Membership/Modify', membership, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  modifyStatus(transfer: StatusUpdate) {
    return this.http.post(environment.root + '/Membership/ModifyStatus', transfer, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteMembership(id: number) {
    return this.http.post(environment.root + '/Membership/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
