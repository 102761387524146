import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import Swal, { SweetAlertType } from "sweetalert2";
import { User } from "../../core/models/system/user";
import { Role } from "../../core/models/system/role";
import { Member } from "../../core/models/members/member";
import { Zone } from "../../core/models/masters/zone";
import { Branch } from "../../core/models/masters/branch";
import { Unit } from "../../core/models/masters/unit";
import { UsersService } from "../../core/services/system/users.service";
import { RolesService } from "../../core/services/system/roles.service";
import { MembersService } from "../../core/services/members/members.service";
import { ZonesService } from "../../core/services/masters/zones.service";
import { BranchesService } from "../../core/services/masters/branches.service";
import { UnitsService } from "../../core/services/masters/units.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { MemberList } from "src/app/core/models/members/member-List";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit {
  Id: number;
  Member: number;
  MemberName: string;
  SelectedValue: boolean;

  roles$: Observable<Role[]>;
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  members$: Member[];

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  public userForm: FormGroup;
  public branchList: FormArray;

  constructor(
    private userService: UsersService,
    private roleService: RolesService,
    private memberService: MembersService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.roles$ = this.roleService.getRoles();
    this.zones$ = this.zoneService.getZones();
    this.SelectedValue = true;

    this.userForm = new FormGroup({
      Id: new FormControl({ Id: 0, Name: "" }),
      Name: new FormControl(""),
      Email: new FormControl(""),
      Password: new FormControl(""),
      Role: new FormControl(0),
      RoleName: new FormControl(""),
      Member: new FormControl({ Id: 0, Name: "" }),
      MemberName: new FormControl(""),
      IsStarClub: new FormControl(false),
      MembershipBranch: new FormControl(false),
      MembershipZone: new FormControl(false),
      MembershipCentral: new FormControl(false),
      FbsBranch: new FormControl(false),
      FbsZone: new FormControl(false),
      FbsCentral: new FormControl(false),
      FineBranch: new FormControl(false),
      FineZone: new FormControl(false),
      FineCentral: new FormControl(false),
      Active: new FormControl(true),
      Zone: new FormControl(0),
      Branch: new FormControl(0),
      Unit: new FormControl(0),
      HasAccess: new FormControl(false),
      Branches: this.formBuilder.array([]),
    });
    this.branchList = this.userForm.get("Branches") as FormArray;

    this.route.paramMap.subscribe((params) => {
      const expID = params.get("id");
      if (expID) {
        this.getUser(expID);
      } else {
        this.getUser("0");
      }
    });

    this.loadMembers();
  }

  cancelUser() {
    this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getUser(Id: string) {
    this.userService.getUser(Id).subscribe(
      (user: User) => this.editUser(user),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editUser(user: User) {
    console.log(user);
    this.userForm.patchValue({
      Id: user.Id,
      Name: user.Name,
      Email: user.Email,
      Password: user.Password,
      Role: user.Role,
      RoleName: user.RoleName,
      Member: { Id: user.Member, Name: user.MemberName },
      MemberName: user.MemberName,
      IsStarClub: user.IsStarClub,
      MembershipBranch: user.MembershipBranch,
      MembershipZone: user.MembershipZone,
      MembershipCentral: user.MembershipCentral,
      FbsBranch: user.FbsBranch,
      FbsZone: user.FbsZone,
      FbsCentral: user.FbsCentral,
      FineBranch: user.FineBranch,
      FineZone: user.FineZone,
      FineCentral: user.FineCentral,
      Active: user.Active,
    });
    this.createFormBranch(user);
    if (user.Id !== "" && user.Role !== 0) {
      this.Id = 1;
    } else {
      this.Id = 0;
    }
  }

  saveUser(reset: boolean) {
    const user: User = {
      Id: this.userForm.value.Id,
      Name: this.userForm.value.Name,
      Email: this.userForm.value.Email,
      Password: this.userForm.value.Password,
      Role: this.userForm.value.Role,
      RoleName: this.userForm.value.RoleName,
      Member: this.userForm.value.Member.Id,
      MemberName: this.userForm.value.Member.Name,
      IsStarClub: this.userForm.value.IsStarClub,
      MembershipBranch: this.userForm.value.MembershipBranch,
      MembershipZone: this.userForm.value.MembershipZone,
      MembershipCentral: this.userForm.value.MembershipCentral,
      FbsBranch: this.userForm.value.FbsBranch,
      FbsZone: this.userForm.value.FbsZone,
      FbsCentral: this.userForm.value.FbsCentral,
      FineBranch: this.userForm.value.FineBranch,
      FineZone: this.userForm.value.FineZone,
      FineCentral: this.userForm.value.FineCentral,
      Active: this.userForm.value.Active,
      CreatedBy: "",
      EditedBy: "",
      Branches: this.userForm.value.Branches,
    };
    if (this.Id < 1) {
      console.log(user);
      this.userService.addUser(user).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetUser();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.userService.modifyUser(user).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetUser();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetUser() {
    for (let i = this.userForm.value.Branches.length - 1; i >= 0; i--) {
      this.branchList.removeAt(i);
    }
    this.getUser("0");
  }

  setMemberValues($event) {
    this.userForm.patchValue({
      Member: $event.item.Id,
      MemberName: $event.item.FullName,
    });
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.getUnits(0);
    this.setZoneVisibility(zone);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
    this.setBranchVisibility(branch);
  }

  get branchFormGroup() {
    return this.userForm.get("Branches") as FormArray;
  }

  createFormBranch(user: User) {
    console.log(user.Branches);
    for (const branch of user.Branches) {
      this.branchList.push(
        this.createBranchWithValues(
          branch.Id,
          branch.UserId,
          branch.Zone,
          branch.ZoneName,
          branch.Branch,
          branch.BranchName,
          branch.Unit,
          branch.UnitName,
          branch.HasAccess
        )
      );
    }
  }

  createBranchWithValues(
    Id: number,
    UserId: string,
    zone: number,
    ZoneName: string,
    Branch: number,
    BranchName: string,
    Unit: number,
    UnitName: string,
    HasAccess: boolean
  ): FormGroup {
    return this.formBuilder.group({
      Id: new FormControl(Id),
      UserId: new FormControl(UserId),
      Zone: new FormControl(zone),
      ZoneName: new FormControl(ZoneName),
      Branch: new FormControl(Branch),
      BranchName: new FormControl(BranchName),
      Unit: new FormControl(Unit),
      UnitName: new FormControl(UnitName),
      HasAccess: new FormControl(HasAccess),
      Visibility: new FormControl(true),
    });
  }

  selectAllBranches() {
    let branchList: any[];
    branchList = this.userForm.value.Branches;
    for (const branch of branchList) {
      if (branch.Visibility == true) {
        branch.HasAccess = this.SelectedValue;
      }
    }
    this.userForm.patchValue({
      Branches: branchList,
    });
    this.SelectedValue = !this.SelectedValue;
  }

  selectAllOrg() {
    let branchList: any[];
    branchList = this.userForm.value.Branches;
    for (const branch of branchList) {
      branch.HasAccess = this.userForm.value.IsStarClub;
    }
    this.userForm.patchValue({
      Branches: branchList,
    });
  }

  setZoneVisibility(zone: number) {
    let branchList: any[];
    zone = this.userForm.value.Zone;
    branchList = this.userForm.value.Branches;
    let condition = false;

    for (const branch of branchList) {
      if (branch.Zone == zone || zone == 0) {
        branch.Visibility = true;
      } else {
        branch.Visibility = false;
      }
    }
    this.userForm.patchValue({
      Branches: branchList,
    });
  }

  setBranchVisibility(branch: number) {
    let branchList: any[];
    branch = this.userForm.value.Branch;
    branchList = this.userForm.value.Branches;
    let condition = false;
    if (branch == 0) {
      this.setZoneVisibility(0);
    } else {
      for (const branch1 of branchList) {
        if (branch1.Branch == branch) {
          branch1.Visibility = true;
        } else {
          branch1.Visibility = false;
        }
      }
      this.userForm.patchValue({
        Branches: branchList,
      });
    }
  }

  setUnitVisibility(unit: number) {
    let branchList: any[];
    unit = this.userForm.value.Unit;
    branchList = this.userForm.value.Branches;
    let condition = false;
    if (unit == 0) {
      this.setBranchVisibility(0);
    } else {
      for (const branch of branchList) {
        if (branch.Unit == unit) {
          branch.Visibility = true;
        } else {
          branch.Visibility = false;
        }
      }
      this.userForm.patchValue({
        Branches: branchList,
      });
    }
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getMemberDetails() {
    if (this.userForm.value.Member != null) {
      this.memberService.getMember(this.userForm.value.Member.Id).subscribe(
        (member: Member) => this.setCurrentDetails(member),
        (err: any) => console.log(err)
      );
    } else {
      this.memberService.getMember(0).subscribe(
        (member: Member) => this.setCurrentDetails(member),
        (err: any) => console.log(err)
      );
    }
    /*
  if (this.userForm.value.Member != null) {
    let member: number;
    member = this.userForm.value.Member.Id;

    this.userService.getUserByMember(member).subscribe(
      (user: User) => this.editUser(user),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
      }
    );
  }
  */
  }

  setCurrentDetails(member: Member) {
    this.userForm.patchValue({
      Id: member.KKMAID.substring(3, 9),
      Name: member.FullName,
      Email: member.Email,
      Password: member.CivilID,
      IsStarClub: member.IsStarClubMember,
    });
  }

  resetPassword() {
    if (this.userForm.value.Id === null || this.userForm.value.Id === "") {
      this.displayFeedback(
        "warning",
        "Please select a user to reset password!",
        ""
      );
    } else {
      this.router.navigate(
        [
          "./",
          {
            outlets: { pages: ["reset-user-password", this.userForm.value.Id] },
          },
        ],
        { relativeTo: this.route.parent }
      );
    }
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
