import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    userDetails: UserDetails;

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {
                let token = null;
                if (localStorage.getItem('userDetails') != null) {
                    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
                    token = this.userDetails.Token;
                } else {
                    token = null;
                }

                if (token) {
                    const cloned = req.clone({
                        headers: req.headers.set('Authorization',
                        'Bearer ' + token)
                    });
                    return next.handle(cloned);
                } else {
                    return next.handle(req);
                }
    }
}
