export const environment = {
  production: false,
  root: "https://app.kkma.net/kkma-api/api",
  fileSystem: "https://app.kkma.net/kkma-api/FileSystem",
  //root: "http://localhost:57333/api",
  //
  //for demo
  // root: "https://demo.fsuite.net/kkma-api/api",
  // fileSystem: "https://demo.fsuite.net/kkma-api/filesystem",
};
//http://localhost:54475/api
