import { Component, OnInit, ContentChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

import { RevicedInvoice } from "../../core/models/receipts/reviced-invoice";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { RevicedInvoiceService } from "../../core/services/receipts/reviced-invoice.service";

@Component({
  selector: "app-reviced-invoices",
  templateUrl: "./reviced-invoices.component.html",
  styleUrls: ["./reviced-invoices.component.css"],
})
export class RevicedInvoicesComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  @ContentChild(NgbPagination, /* TODO: add static flag */ null)
  pagination: NgbPagination;

  page = 1;
  pageSize = 25;
  collectionSize = 0;
  listItemFrom = 1;
  listItemTo = 25;
  searchBy = "Full_Name";
  searchText = "";

  invoices: RevicedInvoice[];

  constructor(
    private revicedInvoiceService: RevicedInvoiceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.pageSize = 25;
    this.searchBy = "MEMBERNAME";
    this.searchText = "";
    this.collectionSize = 0;
    this.listItemFrom = 1;
    this.listItemTo = 25;

    this.setInvoiceList();
  }

  loadInvoice(id: number) {
    this.router.navigate(
      ["./", { outlets: { pages: ["reviced-invoice", id] } }],
      { relativeTo: this.route.parent }
    );
  }

  deleteInvoice(id: number) {
    if (confirm("Are you sure to delete the selected reviced invoice?")) {
      this.revicedInvoiceService.deleteRevicedInvoice(id).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Deleted Successfully!", "");
            this.setInvoiceList();
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getPageFromService(pages) {
    this.page = pages;
    this.setInvoiceList();
  }

  setPageCount(pageSizes) {
    this.pageSize = pageSizes;
    this.setInvoiceList();
  }

  onSearchTextChange() {
    this.setInvoiceList();
  }

  setInvoiceList() {
    this.spinnerService.show();
    this.revicedInvoiceService
      .getReceiptsBySearch(
        this.page,
        this.pageSize,
        this.searchBy,
        this.searchText
      )
      .subscribe(
        (response) => {
          this.collectionSize = response.TotalItems;
          this.listItemFrom = response.ListItemFrom;
          this.listItemTo = response.ListItemTo;
          this.invoices = response.Invoices as RevicedInvoice[];
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
  }

  auditReceipt(id: number) {
    alert("Audit Display");
  }

  trackReceipt(index, item) {
    return item;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Receipts");

    XLSX.writeFile(wb, "Receipts.xlsx");
  }
  auditInvoice(id: number) {}
  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
