import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { Member } from "../../../core/models/members/member";
import { MembersService } from "../../../core/services/members/members.service";
import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MemberList } from "src/app/core/models/members/member-List";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-schemes-by-member",
  templateUrl: "./schemes-by-member.component.html",
  styleUrls: ["./schemes-by-member.component.css"],
})
export class SchemesByMemberComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  Member: number;
  MemberName: string;

  schemeByMemberList: any;
  listCount: any;
  userDetails: UserDetails;
  menuId: number;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;
  showStatus: boolean = false;

  schemeByMemberForm = new FormGroup({
    Member: new FormControl({ Id: 0, Name: "" }),
    MemberName: new FormControl(""),
    Status: new FormControl("A"),
  });

  constructor(
    private memberService: MembersService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {
    this.getSchemeByMember();
  }

  ngOnInit() {
    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 82200;

    this.listCount = 0;
  }

  resetFilters() {
    this.schemeByMemberForm.reset({
      Member: { Id: 0, Name: "" },
      MemberName: "",
      Status: "A",
    });
    this.showStatus = false;
  }

  setSelectedItem($event) {
    this.schemeByMemberForm.patchValue({
      Member: $event.item.Id,
      MemberName: $event.item.FullName,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  onMemberChange() {
    if (this.schemeByMemberForm.value.Member != null) {
      this.memberService
        .getMember(this.schemeByMemberForm.value.Member.Id)
        .subscribe((member: Member) => this.setMember(member));
    } else {
      this.showStatus = false;
    }
  }

  setMember(member: Member) {
    const dateDiff = this.dateDifference(member.RejoinDate, member.KKMADate);
    if (dateDiff > 1) {
      this.showStatus = true;
    } else {
      this.showStatus = false;
    }
  }

  dateDifference(fromDate: Date, toDate: Date) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    return (
      Math.floor(
        (Date.UTC(
          fromDate.getFullYear(),
          fromDate.getMonth(),
          fromDate.getDate()
        ) -
          Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())) /
          (1000 * 60 * 60 * 24)
      ) + 1
    );
  }

  getSchemeByMember() {
    if (this.schemeByMemberForm.value.Member === null) {
      this.schemeByMemberForm.value.Member = { Id: 0, Name: "" };
    } else {
      if (this.schemeByMemberForm.value.Member === 0) {
        this.schemeByMemberForm.value.Member = { Id: 0, Name: "" };
      }
    }
    this.spinnerService.show();
    this.reportGeneratorService
      .getSchemesByMember(
        this.schemeByMemberForm.value.Member.Id,
        this.schemeByMemberForm.value.Status
      )
      .subscribe(
        (response: any[]) => {
          this.schemeByMemberList = response;
          this.listCount = this.schemeByMemberList.SchemeList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Schemes By Member");

    XLSX.writeFile(wb, "Schemes By Member.xlsx");
  }
}
