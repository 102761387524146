import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ZoneComponent } from "./zone/zone.component";
import { ZonesComponent } from "./zones/zones.component";
import { BranchComponent } from "./branch/branch.component";
import { BranchesComponent } from "./branches/branches.component";
import { UnitComponent } from "./unit/unit.component";
import { UnitsComponent } from "./units/units.component";
import { CountryComponent } from "./country/country.component";
import { CountriesComponent } from "./countries/countries.component";
import { StateComponent } from "./state/state.component";
import { StatesComponent } from "./states/states.component";
import { DistrictComponent } from "./district/district.component";
import { DistrictsComponent } from "./districts/districts.component";
import { PanchayathComponent } from "./panchayath/panchayath.component";
import { PanchayathsComponent } from "./panchayaths/panchayaths.component";
import { PostOfficeComponent } from "./post-office/post-office.component";
import { PostOfficesComponent } from "./post-offices/post-offices.component";
import { RelationComponent } from "./relation/relation.component";
import { RelationsComponent } from "./relations/relations.component";
import { QualificationComponent } from "./qualification/qualification.component";
import { QualificationsComponent } from "./qualifications/qualifications.component";
import { OccupationComponent } from "./occupation/occupation.component";
import { OccupationsComponent } from "./occupations/occupations.component";
import { TalentComponent } from "./talent/talent.component";
import { TalentsComponent } from "./talents/talents.component";
import { SchemeComponent } from "./scheme/scheme.component";
import { SchemesComponent } from "./schemes/schemes.component";

@NgModule({
  declarations: [
    ZoneComponent,
    ZonesComponent,
    BranchComponent,
    BranchesComponent,
    UnitComponent,
    UnitsComponent,
    CountryComponent,
    CountriesComponent,
    StateComponent,
    StatesComponent,
    DistrictComponent,
    DistrictsComponent,
    PanchayathComponent,
    PanchayathsComponent,
    PostOfficeComponent,
    PostOfficesComponent,
    RelationComponent,
    RelationsComponent,
    QualificationComponent,
    QualificationsComponent,
    OccupationComponent,
    OccupationsComponent,
    TalentComponent,
    TalentsComponent,
    SchemeComponent,
    SchemesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgbModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [
    ZonesComponent,
    BranchesComponent,
    UnitsComponent,
    CountriesComponent,
    StatesComponent,
    DistrictsComponent,
    PanchayathsComponent,
    PostOfficesComponent,
    RelationsComponent,
    QualificationsComponent,
    OccupationsComponent,
    TalentsComponent,
    ZoneComponent,
  ],
})
export class MastersModule {}
