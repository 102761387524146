import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { Member } from "../../../core/models/members/member";
import { Relation } from "../../../core/models/masters/relation";
import { Qualification } from "../../../core/models/masters/qualification";
import { MembersService } from "../../../core/services/members/members.service";
import { RelationsService } from "../../../core/services/masters/relations.service";
import { QualificationsService } from "../../../core/services/masters/qualifications.service";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MemberFamilyList } from "../../../core/models/reports/members/member-family-list";
import { MemberList } from "src/app/core/models/members/member-List";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-memebers-family-list",
  templateUrl: "./memebers-family-list.component.html",
  styleUrls: ["./memebers-family-list.component.css"],
})
export class MemebersFamilyListComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  Member: number;
  MemberName: string;
  relations$: Observable<Relation[]>;
  qualifications$: Observable<Qualification[]>;
  memberFamilyList: MemberFamilyList[];
  listCount: any;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  userDetails: UserDetails;
  menuId: number;

  memebersFamilyListForm = new FormGroup({
    Relation: new FormControl(0),
    RelationName: new FormControl(""),
    Qualification: new FormControl(0),
    QualificationName: new FormControl(""),
    Member: new FormControl({ Id: 0, Name: "" }),
    MemberName: new FormControl(""),
    Status: new FormControl(""),
  });

  Status = [
    { Status: "", StatusName: "All" },
    { Status: "A", StatusName: "Active" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "E", StatusName: "Expired" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  constructor(
    private memberService: MembersService,
    private relationService: RelationsService,
    private qualificationService: QualificationsService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.relations$ = this.relationService.getRelations();
    this.qualifications$ = this.qualificationService.getQualifications();

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 81300;

    this.listCount = 0;
  }

  resetFilters() {
    this.memebersFamilyListForm.reset({
      Relation: 0,
      RelationName: "",
      Qualification: 0,
      QualificationName: "",
      Member: { Id: 0, Name: "" },
      MemberName: "",
      Status: "",
    });
    this.Member = 0;
    this.MemberName = "";
  }

  setSelectedItem($event) {
    this.memebersFamilyListForm.patchValue({
      Member: $event.item.Id,
      MemberName: $event.item.FullName,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getMemberFamilyList() {
    if (this.memebersFamilyListForm.value.Member === null) {
      this.memebersFamilyListForm.value.Member = { Id: 0, Name: "" };
    } else {
      if (this.memebersFamilyListForm.value.Member === 0) {
        this.memebersFamilyListForm.value.Member = { Id: 0, Name: "" };
      }
    }
    this.spinnerService.show();
    this.reportGeneratorService
      .getMemberFamilyList(
        this.memebersFamilyListForm.value.Member.Id,
        this.memebersFamilyListForm.value.Relation,
        this.memebersFamilyListForm.value.Qualification,
        this.memebersFamilyListForm.value.Status
      )
      .subscribe(
        (response: MemberFamilyList[]) => {
          this.memberFamilyList = response;
          this.listCount = this.memberFamilyList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members Family List");

    XLSX.writeFile(wb, "Members Family List.xlsx");
  }
}
