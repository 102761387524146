import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgSelectModule } from "@ng-select/ng-select";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { CoreModule } from "./core/core.module";
import { MastersModule } from "./masters/masters.module";
import { MembersModule } from "./members/members.module";
import { SchemesModule } from "./schemes/schemes.module";
import { ReceiptsModule } from "./receipts/receipts.module";
import { ReportsModule } from "./reports/reports.module";
import { SystemModule } from "./system/system.module";
import { SharedModule } from "./shared/shared.module";
import { AuthInterceptor } from "./auth/services/auth.interceiptor";
import { NgxPrintModule } from "ngx-print";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    SweetAlert2Module.forRoot(),
    NgSelectModule,
    CoreModule,
    NgxPrintModule,
    MastersModule,
    MembersModule,
    SchemesModule,
    ReceiptsModule,
    ReportsModule,
    SystemModule,
    SharedModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
