import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import { Role } from "../../core/models/system/role";
import { RoleMenu } from "../../core/models/system/role";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { RolesService } from "../../core/services/system/roles.service";
import { Observable } from "rxjs";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-role",
  templateUrl: "./role.component.html",
  styleUrls: ["./role.component.css"],
})
export class RoleComponent implements OnInit {
  public roleForm: FormGroup;
  public menuList: FormArray;
  roles$: Observable<Role[]>;

  userDetails: UserDetails;
  menuId: number;
  constructor(
    private roleService: RolesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 91000;

    this.roles$ = this.roleService.getRoles();
    this.roleForm = new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl(""),
      Active: new FormControl(true),
      RoleMenus: this.formBuilder.array([]),
      AllPrivilegeAdd: new FormControl(false),
      AllPrivilegeModify: new FormControl(false),
      AllPrivilegeDelete: new FormControl(false),
      AllPrivilegeView: new FormControl(false),
      AllPrivilegeExport: new FormControl(false),
      AllPrivilegeAudit: new FormControl(false),
      CopyRoleMenu: new FormControl(false),
      CopyRole: new FormControl(0),
      CopyRoleName: new FormControl(""),
    });

    this.menuList = this.roleForm.get("RoleMenus") as FormArray;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getRole(expID);
      } else {
        this.getRole(0);
      }
    });
  }

  cancelRole() {
    this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getRole(Id: number) {
    this.roleService.getRole(Id).subscribe(
      (role: Role) => this.editRole(role),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editRole(role: Role) {
    this.roleForm.patchValue({
      Id: role.Id,
      Name: role.Name,
      Active: role.Active,
      RoleMenus: [],
    });
    this.createFormMenus(role.RoleMenus);
  }

  saveRole(reset: boolean) {
    const role: Role = {
      Id: this.roleForm.value.Id,
      Name: this.roleForm.value.Name,
      Active: this.roleForm.value.Active,
      RoleMenus: this.roleForm.value.RoleMenus,
      CreatedBy: "",
      EditedBy: "",
    };
    if (role.Id < 1) {
      console.log(role);
      this.roleService.addRole(role).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRole();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.roleService.modifyRole(role).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRole();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetRole() {
    for (let i = this.roleForm.value.RoleMenus.length - 1; i >= 0; i--) {
      this.menuList.removeAt(i);
    }
    this.getRole(0);
  }

  get roleMenuFormGroup() {
    return this.roleForm.get("RoleMenus") as FormArray;
  }

  createFormMenus(menus: RoleMenu[]) {
    for (const menu of menus) {
      this.menuList.push(
        this.createRoleMenuWithValues(
          menu.Id,
          menu.Menu,
          menu.MenuName,
          menu.MenuHasScreen,
          menu.PrivilegeAdd,
          menu.PrivilegeModify,
          menu.PrivilegeDelete,
          menu.PrivilegeView,
          menu.PrivilegeExport,
          menu.PrivilegeAudit
        )
      );
    }
  }

  createRoleMenuWithValues(
    Id: number,
    Menu: number,
    MenuName: string,
    MenuHasScreen: boolean,
    PrivilegeAdd: boolean,
    PrivilegeModify: boolean,
    PrivilegeDelete: boolean,
    PrivilegeView: boolean,
    PrivilegeExport: boolean,
    PrivilegeAudit: boolean
  ): FormGroup {
    return this.formBuilder.group({
      Id: new FormControl(Id),
      Menu: new FormControl(Menu),
      MenuName: new FormControl(MenuName),
      MenuHasScreen: new FormControl(MenuHasScreen),
      PrivilegeAdd: new FormControl(PrivilegeAdd),
      PrivilegeModify: new FormControl(PrivilegeModify),
      PrivilegeDelete: new FormControl(PrivilegeDelete),
      PrivilegeView: new FormControl(PrivilegeView),
      PrivilegeExport: new FormControl(PrivilegeExport),
      PrivilegeAudit: new FormControl(PrivilegeAudit),
    });
  }

  trackMenu(index, item) {
    return item;
  }

  selectAllMenus(type: string) {
    let menuList: any[];
    menuList = this.roleForm.value.RoleMenus;
    for (const menu of menuList) {
      if (type === "Add") {
        menu.PrivilegeAdd = this.roleForm.value.AllPrivilegeAdd;
      } else if (type === "Modify") {
        menu.PrivilegeModify = this.roleForm.value.AllPrivilegeModify;
      } else if (type === "Delete") {
        menu.PrivilegeDelete = this.roleForm.value.AllPrivilegeDelete;
      } else if (type === "View") {
        menu.PrivilegeView = this.roleForm.value.AllPrivilegeView;
      } else if (type === "Export") {
        menu.PrivilegeExport = this.roleForm.value.AllPrivilegeExport;
      } else if (type === "Audit") {
        menu.PrivilegeAudit = this.roleForm.value.AllPrivilegeAudit;
      }
    }
    this.roleForm.patchValue({
      RoleMenus: menuList,
    });
  }

  copyRole() {
    if (this.roleForm.value.CopyRole < 1) {
      this.displayHTMLFeedback("warning", "Please select a role" + "!", "");
    } else {
      this.roleService.getRole(this.roleForm.value.CopyRole).subscribe(
        (role: Role) => this.setRole(role),
        (err: any) => {
          this.displayFeedback(
            "error",
            "Error!",
            "Something went wrong. Try again!"
          );
        }
      );
    }
  }

  setRole(role: Role) {
    console.log(role);
    for (let i = this.roleForm.value.RoleMenus.length - 1; i >= 0; i--) {
      this.menuList.removeAt(i);
    }
    this.createFormMenus(role.RoleMenus);
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.roleForm.value.Id < 1) privilage = true;
        else if (value.PrivilegeModify && this.roleForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
