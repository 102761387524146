import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./core/components/login/login.component";
import { LogoutComponent } from "./core/components/logout/logout.component";
import { HomeComponent } from "./core/components/home/home.component";
import { DashbaordComponent } from "./core/components/dashbaord/dashbaord.component";
import { ResetPasswordComponent } from "./core/components/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./core/components/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./core/components/change-password/change-password.component";
import { ResetUserPasswordComponent } from "./system/reset-user-password/reset-user-password.component";

import { ZonesComponent } from "./masters/zones/zones.component";
import { BranchesComponent } from "./masters/branches/branches.component";
import { UnitsComponent } from "./masters/units/units.component";
import { CountriesComponent } from "./masters/countries/countries.component";
import { StatesComponent } from "./masters/states/states.component";
import { DistrictsComponent } from "./masters/districts/districts.component";
import { PanchayathsComponent } from "./masters/panchayaths/panchayaths.component";
import { PostOfficesComponent } from "./masters/post-offices/post-offices.component";
import { RelationsComponent } from "./masters/relations/relations.component";
import { QualificationsComponent } from "./masters/qualifications/qualifications.component";
import { OccupationsComponent } from "./masters/occupations/occupations.component";
import { TalentsComponent } from "./masters/talents/talents.component";
import { SchemesComponent } from "./masters/schemes/schemes.component";
import { MembersComponent } from "./members/members/members.component";
import { StarClubMembersComponent } from "./members/star-club-members/star-club-members.component";
import { TransfersComponent } from "./members/transfers/transfers.component";
import { MembershipsComponent } from "./schemes/memberships/memberships.component";
import { FbssComponent } from "./schemes/fbss/fbss.component";
import { FinesComponent } from "./schemes/fines/fines.component";
import { ReceiptsMembershipComponent } from "./receipts/receipts-membership/receipts-membership.component";
import { ReceiptsFbsComponent } from "./receipts/receipts-fbs/receipts-fbs.component";
import { ReceiptsFineComponent } from "./receipts/receipts-fine/receipts-fine.component";
import { RolesComponent } from "./system/roles/roles.component";
import { UsersComponent } from "./system/users/users.component";
import { SettingsComponent } from "./system/settings/settings.component";

import { ZoneComponent } from "./masters/zone/zone.component";
import { BranchComponent } from "./masters/branch/branch.component";
import { UnitComponent } from "./masters/unit/unit.component";
import { CountryComponent } from "./masters/country/country.component";
import { StateComponent } from "./masters/state/state.component";
import { DistrictComponent } from "./masters/district/district.component";
import { PanchayathComponent } from "./masters/panchayath/panchayath.component";
import { PostOfficeComponent } from "./masters/post-office/post-office.component";
import { RelationComponent } from "./masters/relation/relation.component";
import { QualificationComponent } from "./masters/qualification/qualification.component";
import { OccupationComponent } from "./masters/occupation/occupation.component";
import { TalentComponent } from "./masters/talent/talent.component";
import { SchemeComponent } from "./masters/scheme/scheme.component";
import { MemberComponent } from "./members/member/member.component";
import { StarClubMemberComponent } from "./members/star-club-member/star-club-member.component";
import { TransferComponent } from "./members/transfer/transfer.component";
import { MembershipComponent } from "./schemes/membership/membership.component";
import { FbsComponent } from "./schemes/fbs/fbs.component";
import { FineComponent } from "./schemes/fine/fine.component";
import { ReceiptMembershipComponent } from "./receipts/receipt-membership/receipt-membership.component";
import { ReceiptFbsComponent } from "./receipts/receipt-fbs/receipt-fbs.component";
import { ReceiptFineComponent } from "./receipts/receipt-fine/receipt-fine.component";
import { RevicedInvoiceComponent } from "./receipts/reviced-invoice/reviced-invoice.component";
import { ReceiptApprovalComponent } from "./receipts/receipt-approval/receipt-approval.component";
import { RoleComponent } from "./system/role/role.component";
import { UserComponent } from "./system/user/user.component";

import { MemebersListComponent } from "./reports/members/memebers-list/memebers-list.component";
import { MemebersFamilyListComponent } from "./reports/members/memebers-family-list/memebers-family-list.component";
import { MemebersListByOrgComponent } from "./reports/members/memebers-list-by-org/memebers-list-by-org.component";
import { MemebersListByIndiaComponent } from "./reports/members/memebers-list-by-india/memebers-list-by-india.component";
import { MemebersListByKuwaitComponent } from "./reports/members/memebers-list-by-kuwait/memebers-list-by-kuwait.component";
import { MembershipHistoryComponent } from "./reports/schemes/membership-history/membership-history.component";
import { MembershipByMemberComponent } from "./reports/schemes/membership-by-member/membership-by-member.component";
import { FbsHistoryComponent } from "./reports/schemes/fbs-history/fbs-history.component";
import { FbsByMemberComponent } from "./reports/schemes/fbs-by-member/fbs-by-member.component";
import { SchemesHistoryComponent } from "./reports/schemes/schemes-history/schemes-history.component";
import { SchemesByMemberComponent } from "./reports/schemes/schemes-by-member/schemes-by-member.component";
import { ReceiptHistoryComponent } from "./reports/receipts/receipt-history/receipt-history.component";
import { RevicedInvoicesComponent } from "./receipts/reviced-invoices/reviced-invoices.component";
import { MemberDetailsComponent } from "./reports/members/member-details/member-details.component";
import { MemberDetailsKeralaComponent } from "./reports/members/member-details-kerala/member-details-kerala.component";
import { MemberTalentComponent } from "./reports/members/member-talent/member-talent.component";
import { MemberNotRenewedComponent } from "./reports/members/member-not-renewed/member-not-renewed.component";
import { MemberRenewedComponent } from "./reports/members/member-renewed/member-renewed.component";
import { MemberAreaComponent } from "./reports/members/member-area/member-area.component";
import { MemberSchemeDuesComponent } from "./reports/members/member-scheme-dues/member-scheme-dues.component";

import { AuthGuard } from "./../app/auth/guards/auth.guards";
import { MemberSchemesComponent } from "./reports/members/member-schemes/member-schemes.component";
import { MemberSchemeStatusComponent } from "./reports/members/member-scheme-status/member-scheme-status.component";
import { MemberDetailsDashboardComponent } from "./reports/members/member-details-dashboard/member-details-dashboard.component";
import { FbsDashboardComponent } from "./reports/schemes/fbs-dashboard/fbs-dashboard.component";
import { CollectionSheetComponent } from "./reports/fbs/collection-sheet/collection-sheet.component";
import { CollectionSummaryComponent } from "./reports/fbs/collection-summary/collection-summary.component";
import { StarClubPerfomanceComponent } from "./reports/fbs/star-club-perfomance/star-club-perfomance.component";
import { SchemeSummaryComponent } from "./reports/fbs/scheme-summary/scheme-summary.component";
import { CancelInvoicesComponent } from "./receipts/cancel-invoices/cancel-invoices.component";
import { CancelInvoiceComponent } from "./receipts/cancel-invoice/cancel-invoice.component";
import { MemberDetailMembershipFbsComponent } from "./reports/receipts/member-detail-membership-fbs/member-detail-membership-fbs.component";
import { RejoinMembersComponent } from "./members/rejoin-members/rejoin-members.component";
import { RejoinMemberComponent } from "./members/rejoin-member/rejoin-member.component";
import { MemberRejoinComponent } from "./reports/members/member-rejoin/member-rejoin.component";
import { StatusChangeComponent } from "./members/status-change/status-change.component";
import { StatusChangesComponent } from "./members/status-changes/status-changes.component";
import { MemberFamilyDetailsComponent } from "./reports/members/member-family-details/member-family-details.component";
import { VendorComponent } from "./system/vendor/vendor.component";
import { VendorsComponent } from "./system/vendors/vendors.component";
import { ExpiredMemberDetailsComponent } from "./reports/members/expired-member-details/expired-member-details.component";
import { UpdateInvoiceComponent } from "./receipts/update-invoice/update-invoice.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "reset-password/:id", component: ResetPasswordComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        component: DashbaordComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "zones",
        component: ZonesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "branches",
        component: BranchesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "units",
        component: UnitsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "countries",
        component: CountriesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "states",
        component: StatesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "districts",
        component: DistrictsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "panchayaths",
        component: PanchayathsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "post-offices",
        component: PostOfficesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "relations",
        component: RelationsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "qualifications",
        component: QualificationsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "occupations",
        component: OccupationsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "talents",
        component: TalentsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "schemes",
        component: SchemesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members",
        component: MembersComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "rejoin-members",
        component: RejoinMembersComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "star-club-members",
        component: StarClubMembersComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "transfers",
        component: TransfersComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "status-changes",
        component: StatusChangesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "memberships",
        component: MembershipsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fbss",
        component: FbssComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fines",
        component: FinesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipts-membership",
        component: ReceiptsMembershipComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipts-fbs",
        component: ReceiptsFbsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipts-fine",
        component: ReceiptsFineComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipts-approval",
        component: ReceiptApprovalComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "reviced-invoices",
        component: RevicedInvoicesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "cancel-invoices",
        component: CancelInvoicesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "update-invoices",
        component: UpdateInvoiceComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "roles",
        component: RolesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "settings",
        component: SettingsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "vendors",
        component: VendorsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members-list",
        component: MemebersListComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members-family-list",
        component: MemebersFamilyListComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members-family-details",
        component: MemberFamilyDetailsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members-by-org",
        component: MemebersListByOrgComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members-by-india",
        component: MemebersListByIndiaComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "members-by-kuwait",
        component: MemebersListByKuwaitComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "membership-history",
        component: MembershipHistoryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "membership-by-member",
        component: MembershipByMemberComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-rejoin",
        component: MemberRejoinComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fbs-history",
        component: FbsHistoryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fbs-by-member",
        component: FbsByMemberComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "schemes-history",
        component: SchemesHistoryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "schemes-by-member",
        component: SchemesByMemberComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipts-history",
        component: ReceiptHistoryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "collection-sheet",
        component: CollectionSheetComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "collection-summary",
        component: CollectionSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "star-club-performance",
        component: StarClubPerfomanceComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "scheme-summary",
        component: SchemeSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-details",
        component: MemberDetailsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-details-kerala",
        component: MemberDetailsKeralaComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-by-talent",
        component: MemberTalentComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-not-renewed",
        component: MemberNotRenewedComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-renewed",
        component: MemberRenewedComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-by-area",
        component: MemberAreaComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-scheme-dues",
        component: MemberSchemeDuesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-schemes",
        component: MemberSchemesComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-scheme-status",
        component: MemberSchemeStatusComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-details-dashboard",
        component: MemberDetailsDashboardComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fbs-details-dashboard",
        component: FbsDashboardComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },

      {
        path: "zone/:id",
        component: ZoneComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "branch/:id",
        component: BranchComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "unit/:id",
        component: UnitComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "country/:id",
        component: CountryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "state/:id",
        component: StateComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "district/:id",
        component: DistrictComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "panchayath/:id",
        component: PanchayathComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "post-office/:id",
        component: PostOfficeComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "relation/:id",
        component: RelationComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "qualification/:id",
        component: QualificationComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "occupation/:id",
        component: OccupationComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "talent/:id",
        component: TalentComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "scheme/:id",
        component: SchemeComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member/:id",
        component: MemberComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "rejoin-member/:id",
        component: RejoinMemberComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "star-club-member/:id",
        component: StarClubMemberComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "transfer/:id",
        component: TransferComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "status-change/:id",
        component: StatusChangeComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "membership/:id",
        component: MembershipComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fbs/:id",
        component: FbsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "fine/:id",
        component: FineComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipt-membership/:id",
        component: ReceiptMembershipComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipt-fbs/:id",
        component: ReceiptFbsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipt-fine/:id",
        component: ReceiptFineComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "reviced-invoice/:id",
        component: RevicedInvoiceComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "cancel-invoice/:id",
        component: CancelInvoiceComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "expired-member-detail/:id",
        component: ExpiredMemberDetailsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-detail-membership-fbs",
        component: MemberDetailMembershipFbsComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "role/:id",
        component: RoleComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "user/:id",
        component: UserComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "vendor/:id",
        component: VendorComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "reset-user-password/:id",
        component: ResetUserPasswordComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
