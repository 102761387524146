import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MembershipHistory } from "../../../core/models/reports/schemes/membsership-history";
import { MembershipHistorySearch } from "src/app/core/models/reports/schemes/membership-history-search";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-membership-history",
  templateUrl: "./membership-history.component.html",
  styleUrls: ["./membership-history.component.css"],
})
export class MembershipHistoryComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  FromDate: NgbDateStruct;
  ToDate: NgbDateStruct;
  membershipList: MembershipHistory[];
  listCount: any;
  userDetails: UserDetails;
  menuId: number;

  memebershipHistoryForm = new FormGroup({
    FromDate: new FormControl(new Date()),
    ToDate: new FormControl(new Date()),
    Status: new FormControl(""),
  });

  Status = [
    { Status: "", StatusName: "All" },
    { Status: "O", StatusName: "Open" },
    { Status: "A", StatusName: "Approved" },
    { Status: "C", StatusName: "Closed" },
    { Status: "L", StatusName: "Cancelled" },
  ];

  constructor(
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.FromDate = this.calendar.getToday();
    this.ToDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 81700;

    this.listCount = 0;
  }

  resetFilters() {
    this.memebershipHistoryForm.reset({
      FromDate: new Date(),
      ToDate: new Date(),
      Status: "",
    });
  }

  getMembershipHistory() {
    const searchFields: MembershipHistorySearch = {
      FromDate: this.memebershipHistoryForm.value.FromDate,
      ToDate: this.memebershipHistoryForm.value.ToDate,
      Status: this.memebershipHistoryForm.value.Status,
    };

    this.spinnerService.show();
    this.reportGeneratorService.getMembershipHistory(searchFields).subscribe(
      (response: MembershipHistory[]) => {
        this.membershipList = response;
        this.listCount = this.membershipList.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Membership History");

    XLSX.writeFile(wb, "Membership History.xlsx");
  }
}
