import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal, { SweetAlertType } from 'sweetalert2';
import { District } from '../../core/models/masters/district';
import { Panchayath } from '../../core/models/masters/panchayath';
import { DistrictsService } from '../../core/services/masters/districts.service';
import { PanchayathsService } from '../../core/services/masters/panchayaths.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-panchayath',
  templateUrl: './panchayath.component.html',
  styleUrls: ['./panchayath.component.css']
})
export class PanchayathComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;
  districts$: Observable<District[]>;

  panchayathForm = new FormGroup({
    Id: new FormControl(0),
    Code: new FormControl(''),
    Name: new FormControl(''),
    District: new FormControl(0),
    DistrictName: new FormControl(''),
    Active: new FormControl(true)
  });

  constructor(private panchayathService: PanchayathsService, private districtService: DistrictsService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.districts$ = this.districtService.getDistricts();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 17000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      if (id) {
        this.getPanchayath(id);
      }
    });
  }

  cancelPanchayath() {
    this.router.navigate(['./', { outlets: { pages: ['panchayaths']} }], { relativeTo: this.route.parent });
  }

  getPanchayath(Id: number) {
    this.panchayathService.getPanchayath(Id).subscribe(
      (panchayath: Panchayath) => this.editPanchayath(panchayath),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['panchayaths']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editPanchayath(panchayath: Panchayath) {
    this.panchayathForm.patchValue({
      Id: panchayath.Id,
      Code: panchayath.Code,
      Name: panchayath.Name,
      District: panchayath.District,
      DistrictName: panchayath.DistrictName,
      Active: panchayath.Active
    });
  }

  savePanchayath(reset: boolean) {
    const panchayath: Panchayath = this.panchayathForm.value as Panchayath;
    if (panchayath.Id < 1) {
      this.panchayathService.addPanchayath(panchayath)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetPanchayath();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['panchayaths']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.panchayathService.modifyPanchayath(panchayath)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetPanchayath();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['panchayaths']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  resetPanchayath() {
    this.panchayathForm.reset({
      Id: 0,
      Code: '',
      Name: '',
      District: 0,
      DistrictName: '',
      Active: true
    });
  }

  hasAdd() {
    let privilage = false;

      this.userDetails.Menus.forEach(value => {
         if (value.Id === this.menuId) {
           if(value.PrivilegeAdd && this.panchayathForm.value.Id < 1)
              privilage = true;
            else if(value.PrivilegeModify && this.panchayathForm.value.Id > 0)
              privilage = true;
         }
      });

      return privilage;
    }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
