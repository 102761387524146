import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import { Relation } from "../../core/models/masters/relation";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { RelationsService } from "../../core/services/masters/relations.service";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-relation",
  templateUrl: "./relation.component.html",
  styleUrls: ["./relation.component.css"],
})
export class RelationComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;

  relationForm = new FormGroup({
    Id: new FormControl(0),
    Name: new FormControl(""),
    Active: new FormControl(true),
  });

  constructor(
    private relationService: RelationsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 19200;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getRelation(id);
      }
    });
  }

  cancelRelation() {
    this.router.navigate(["./", { outlets: { pages: ["relations"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getRelation(Id: number) {
    this.relationService.getRelation(Id).subscribe(
      (relation: Relation) => this.editRelation(relation),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["relations"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editRelation(relation: Relation) {
    this.relationForm.patchValue({
      Id: relation.Id,
      Name: relation.Name,
      Active: relation.Active,
    });
  }

  saveRelation(reset: boolean) {
    const relation: Relation = this.relationForm.value as Relation;
    if (relation.Id < 1) {
      this.relationService.addRelation(relation).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRelation();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["relations"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.relationService.modifyRelation(relation).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRelation();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["relations"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetRelation() {
    this.relationForm.reset({
      Id: 0,
      Name: "",
      Active: true,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.relationForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.relationForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
