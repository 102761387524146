import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Fine } from '../../models/schemes/fine';
import { StatusUpdate } from '../members/status-update';

@Injectable({
  providedIn: 'root'
})
export class FineService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getFines(): Observable<Fine[]> {
    return this.http.get<Fine[]>(environment.root + '/Fine');
  }

  getFine(id: number): Observable<Fine> {
    return this.http.get<Fine>(environment.root + '/Fine/' + id);
  }

  getFinesBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
    .set('page', page.toString())
    .set('pageSize', pageSize.toString())
    .set('searchBy', searchBy.toString())
    .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Fines: Fine[]}>
          (environment.root + '/Fine/GetAllGetFinesBySearch', { params });
  }

  addFine(fine: Fine) {
    return this.http.post(environment.root + '/Fine/Add', fine, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyFine(fine: Fine) {
    return this.http.post(environment.root + '/Fine/Modify', fine, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  modifyStatus(transfer: StatusUpdate) {
    return this.http.post(environment.root + '/Fine/ModifyStatus', transfer, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteFine(id: number) {
    return this.http.post(environment.root + '/Fine/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
