import { Component, OnInit, ContentChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

import { Occupation } from "../../core/models/masters/occupation";
import { OccupationsService } from "../../core/services/masters/occupations.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-occupations",
  templateUrl: "./occupations.component.html",
  styleUrls: ["./occupations.component.css"],
})
export class OccupationsComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  @ContentChild(NgbPagination, /* TODO: add static flag */ null)
  pagination: NgbPagination;

  userDetails: UserDetails;
  menuId: number;

  page = 1;
  pageSize = 25;
  collectionSize = 0;
  listItemFrom = 1;
  listItemTo = 25;
  searchBy = "Name";
  searchText = "";

  occupations: Occupation[];

  constructor(
    private occupationService: OccupationsService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.pageSize = 25;
    this.searchBy = "Name";
    this.searchText = "";
    this.collectionSize = 0;
    this.listItemFrom = 1;
    this.listItemTo = 25;

    this.setOccupationList();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 19400;
  }

  loadOccupation(id: number) {
    this.router.navigate(["./", { outlets: { pages: ["occupation", id] } }], {
      relativeTo: this.route.parent,
    });
  }

  deleteOccupation(id: number) {
    this.occupationService.deleteOccupation(id).subscribe(
      (response: HttpResponse) => {
        if (response.result === HttpResult.Success) {
          this.displayFeedback("success", "Deleted Successfully!", "");
          this.setOccupationList();
        } else {
          this.displayFeedback(
            "warning",
            response.result + "!",
            response.message
          );
        }
      },
      (error) => {
        this.displayFeedback("error", "Error!", error);
      }
    );
  }

  getPageFromService(pages) {
    this.page = pages;
    this.setOccupationList();
  }

  setPageCount(pageSizes) {
    this.pageSize = pageSizes;
    this.setOccupationList();
  }

  onSearchTextChange() {
    this.setOccupationList();
  }

  setOccupationList() {
    this.spinnerService.show();
    this.occupationService
      .getOccupationBySearch(
        this.page,
        this.pageSize,
        this.searchBy,
        this.searchText
      )
      .subscribe(
        (response) => {
          this.collectionSize = response.TotalItems;
          this.listItemFrom = response.ListItemFrom;
          this.listItemTo = response.ListItemTo;
          this.occupations = response.Occupations as Occupation[];
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd) privilage = true;
      }
    });

    return privilage;
  }

  hasView() {
    let privilage = false;
    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeView || value.PrivilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasDelete() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeDelete) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  hasAudit() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAudit) privilage = true;
      }
    });

    return privilage;
  }

  auditOccupation(id: number) {
    alert("Audit Display");
  }

  trackOccupation(index, item) {
    return item;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Occupations");

    XLSX.writeFile(wb, "Occupations.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
