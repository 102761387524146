import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MembersModule } from "./members/members.module";
import { SchemesModule } from "./schemes/schemes.module";
import { ReceiptsModule } from "./receipts/receipts.module";
import { FbsModule } from "./fbs/fbs.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [MembersModule, SchemesModule, ReceiptsModule, FbsModule],
})
export class ReportsModule {}
