import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject, concat, of } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import {
  debounceTime,
  map,
  debounce,
  switchMap,
  catchError,
  tap,
  distinctUntilChanged,
} from "rxjs/operators";
import { HttpClient, HttpRequest, HttpEventType } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import Swal, { SweetAlertType } from "sweetalert2";
import { StatusUpdate } from "src/app/core/services/members/status-update";
import { UserDetails } from "src/app/core/models/system/user-details";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Member } from "src/app/core/models/members/member";
import { MemberList } from "src/app/core/models/members/member-List";
import {
  RejoinMember,
  RejoinMemberInvoice,
} from "src/app/core/models/members/rejoin-member";
import { RejoinMemberService } from "src/app/core/services/members/rejoin-member.service";
import { MembersService } from "src/app/core/services/members/members.service";

@Component({
  selector: "app-rejoin-member",
  templateUrl: "./rejoin-member.component.html",
  styleUrls: ["./rejoin-member.component.css"],
})
export class RejoinMemberComponent implements OnInit {
  @ViewChild("fileProfileImage", { static: true }) fileProfileImage: ElementRef;
  profileImageUrl = "";
  selectedProfilePicture: any = null;

  userDetails: UserDetails;
  menuId: number;

  profileImage: SafeResourceUrl;

  public message: string;

  KKMADate: NgbDateStruct;
  RejoinDate: NgbDateStruct;

  members$: Observable<Member[]>;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  public memberForm: FormGroup;
  public invoiceList: FormArray;

  Id: number;
  Member: number;
  MemberName: string;

  approvedStatus: boolean;
  rejectedStatus: boolean;

  constructor(
    private memberService: RejoinMemberService,
    private membService: MembersService,
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.Id = 0;
    this.KKMADate = this.calendar.getToday();
    this.RejoinDate = this.calendar.getToday();

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 24000;

    this.memberForm = this.formBuilder.group({
      Id: new FormControl(0),
      Member: new FormControl({ Id: 0, Name: "" }),
      MemberName: new FormControl(""),
      KKMADate: new FormControl(new Date()),
      RejoinDate: new FormControl(new Date()),
      OldKKMAId: new FormControl(""),
      NewKKMAId: new FormControl(""),
      Remarks: new FormControl(""),
      Status: new FormControl("O"),
      StatusName: new FormControl("Open"),
      Invoices: this.formBuilder.array([]),
    });
    this.invoiceList = this.memberForm.get("Invoices") as FormArray;

    this.members$ = this.membService.getMembers();

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getRejoinMember(expID);
      }
    });
  }

  cancelRejoinMember() {
    this.router.navigate(["./", { outlets: { pages: ["rejoin-members"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getRejoinMember(Id: number) {
    this.memberService.getRejoinMember(Id).subscribe(
      (member: RejoinMember) => this.editRejoinMember(member),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(
          ["./", { outlets: { pages: ["rejoin-members"] } }],
          {
            relativeTo: this.route.parent,
          }
        );
      }
    );
  }

  editRejoinMember(member: RejoinMember) {
    this.memberForm.patchValue({
      Id: member.Id,
      Member: { Id: member.Member, Name: member.MemberName },
      KKMADate: member.KKMADate,
      MemberName: member.MemberName,
      RejoinDate: member.RejoinDate,
      OldKKMAId: member.OldKKMAId,
      NewKKMAId: member.NewKKMAId,
      Remarks: member.Remarks,
      Status: member.Status,
      Invoices: [],
    });
    this.Id = member.Id;
    this.Member = member.Member;
    this.MemberName = member.MemberName;

    this.createFormInvoice(member.Invoices);
    this.KKMADate = this.ngbDateAdapter.fromModel(new Date(member.KKMADate));
    this.RejoinDate = this.ngbDateAdapter.fromModel(
      new Date(member.RejoinDate)
    );
    this.statusMenuVisible(member.Id, member.Status);
  }

  saveRejoinMember(reset: boolean) {
    const member: RejoinMember = {
      Id: this.memberForm.value.Id,
      Member: this.memberForm.value.Member.Id,
      MemberName: this.memberForm.value.Member.Name,
      KKMADate: this.memberForm.value.KKMADate,
      RejoinDate: this.memberForm.value.RejoinDate,
      OldKKMAId: this.memberForm.value.OldKKMAId,
      NewKKMAId: this.memberForm.value.NewKKMAId,
      Remarks: this.memberForm.value.Remarks,
      Status: this.memberForm.value.Status,
      CreatedBy: "",
      CreatedOn: new Date(),
      EditedBy: "",
      EditedOn: new Date(),
      Invoices: this.memberForm.value.Invoices,
    };
    if (member.Id < 1) {
      this.memberService.addRejoinMember(member).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            this.memberForm.value.Id = response.message.substr(25);

            if (reset) {
              this.resetRejoinMember();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["rejoin-members"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.memberService.modifyRejoinMember(member).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRejoinMember();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["rejoin-members"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  updateStatus(stat) {
    if (this.memberForm.value.Id < 1) {
      this.displayFeedback(
        "error",
        "Error!",
        "Please select a RJANMB to update status!"
      );
    } else {
      const transfer: StatusUpdate = {
        Id: this.memberForm.value.Id,
        Status: stat,
        EditedBy: "",
      };

      this.memberService.modifyStatus(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Status Updated Successfully!", "");
            this.memberForm.patchValue({
              Status: stat,
              StatusName:
                stat === "O"
                  ? "Open"
                  : stat === "A"
                  ? "Approved"
                  : stat === "R"
                  ? "Rejected"
                  : "Open",
            });
            this.statusMenuVisible(transfer.Id, stat);
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetRejoinMember() {
    this.memberForm.reset({
      Id: 0,
      Member: { Id: 0, Name: "" },
      MemberName: "",
      KKMADate: new Date(),
      RejoinDate: new Date(),
      OldKKMAId: "",
      NewKKMAId: "",
      Remarks: "",
      Status: "O",
      StatusName: "Open",
    });
    this.removeInvoicesOnReset();
  }

  get invoiceFormGroup() {
    return this.memberForm.get("Invoices") as FormArray;
  }

  createFormInvoice(receipt: RejoinMemberInvoice[]) {
    console.log(receipt);
    for (const invoice of receipt) {
      this.invoiceList.push(
        this.createInvoiceWithValues(
          invoice.Id,
          invoice.HId,
          invoice.Invoice,
          invoice.Type,
          invoice.TypeId,
          invoice.Description,
          invoice.Amount,
          invoice.ExpiryDate,
          invoice.Status,
          invoice.CreatedBy,
          invoice.CreatedOn,
          invoice.EditedBy,
          invoice.EditedOn
        )
      );
    }
  }

  createInvoiceWithValues(
    Id: number,
    HId: number,
    Invoice: number,
    Type: string,
    TypeId: number,
    Description: string,
    Amount: number,
    ExpiryDate: Date,
    Status: string,
    createdBy: string,
    createdOn: Date,
    editedBy: string,
    editedOn: Date
  ): FormGroup {
    return this.formBuilder.group({
      Id: new FormControl(Id),
      HId: new FormControl(HId),
      Invoice: new FormControl(Invoice),
      Type: new FormControl(Type),
      TypeId: new FormControl(TypeId),
      Description: new FormControl(Description),
      Amount: new FormControl(Amount.toFixed(3)),
      ExpiryDate: new FormControl(ExpiryDate),
      Status: new FormControl(Status),
      createdBy: new FormControl(createdBy),
      createdOn: new FormControl(createdOn),
      editedBy: new FormControl(editedBy),
      editedOn: new FormControl(editedOn),
    });
  }

  createInvoice(): FormGroup {
    return this.formBuilder.group({
      Id: new FormControl(0),
      HId: new FormControl(0),
      Invoice: new FormControl(0),
      Type: new FormControl(""),
      TypeId: new FormControl(0),
      Description: new FormControl(""),
      Amount: new FormControl(0),
      ExpiryDate: new FormControl(new Date()),
      Status: new FormControl(""),
      createdBy: new FormControl(""),
      createdOn: new FormControl(new Date()),
      editedBy: new FormControl(""),
      editedOn: new FormControl(new Date()),
    });
  }

  addInvoice() {
    this.invoiceList.push(this.createInvoice());
  }

  removeInvoicesOnReset() {
    for (let i = this.memberForm.value.Invoices.length - 1; i >= 0; i--) {
      this.invoiceList.removeAt(i);
    }
  }

  removeInvoice(index) {
    this.invoiceList.removeAt(index);
  }

  getInvoices() {
    let member: number;
    member = this.memberForm.value.Member.Id;
    this.removeInvoicesOnReset();

    this.membService
      .getMember(member)
      .subscribe((starClubMember: Member) => this.setMember(starClubMember));
    this.generateInvoice(member);
  }

  setMember(member: Member) {
    this.memberForm.patchValue({
      KKMADate: member.KKMADate,
      OldKKMAId: member.KKMAID,
    });
  }

  generateInvoice(member: number) {
    this.memberService.generateInvoiceForRejoinMember(member).subscribe(
      (response) => {
        this.createFormInvoice(response.Invoices);
        this.hasAdd();
      },
      (err: any) => console.log(err)
    );
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.membService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  showFileDialogue() {
    if (this.fileProfileImage.nativeElement && document.createEvent) {
      const evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      this.fileProfileImage.nativeElement.dispatchEvent(evt);
    }
  }

  statusMenuVisible(id, status) {
    if (id < 0) {
      this.approvedStatus = false;
      this.rejectedStatus = false;
    } else {
      if (status === "O") {
        this.approvedStatus = true;
        this.rejectedStatus = true;
      } else {
        this.approvedStatus = false;
        this.rejectedStatus = false;
      }
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.memberForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.memberForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
