import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormArray, FormBuilder } from "@angular/forms";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";
import { Observable, of, concat, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  tap,
  catchError,
  switchMap,
  distinctUntilChanged,
  debounceTime,
} from "rxjs/operators";

import { CancelInvoice } from "../../core/models/receipts/cancel-invoice";
import { CancelInvoiceService } from "../../core/services/receipts/cancel-invoice.service";
import { InvoiceList } from "src/app/core/models/receipts/InvoiceList";

@Component({
  selector: "app-cancel-invoice",
  templateUrl: "./cancel-invoice.component.html",
  styleUrls: ["./cancel-invoice.component.css"],
})
export class CancelInvoiceComponent implements OnInit {
  public cancelInvoiceForm: FormGroup;
  ExpiryDate: NgbDateStruct;

  invoiceLoading = false;
  invoice$: Observable<InvoiceList[]>;
  invoiceInput$ = new Subject<string>();
  selectedIvoice: any;
  ZeroValue = 0;

  constructor(
    private cancelInvoiceService: CancelInvoiceService,
    private ngbDateAdapter: NgbStringAdapterService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.ExpiryDate = this.calendar.getToday();

    this.cancelInvoiceForm = new FormGroup({
      Id: new FormControl({ Id: 0, Name: "" }),
      ExpiryDate: new FormControl(new Date()),
      Description: new FormControl(""),
      Amount: new FormControl(this.ZeroValue.toFixed(3)),
    });

    this.loadInvoices();

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getInvoice(id);
      }
    });
  }

  cancelInvoice() {
    this.router.navigate(["./", { outlets: { pages: ["cancel-invoices"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getInvoice(Id: number) {
    this.cancelInvoiceService.getInvoice(Id).subscribe(
      (receipt: CancelInvoice) => this.editInvoice(receipt),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(
          ["./", { outlets: { pages: ["cancel-invoices"] } }],
          { relativeTo: this.route.parent }
        );
      }
    );
  }

  editInvoice(invoice: CancelInvoice) {
    this.cancelInvoiceForm.patchValue({
      Id: { Id: invoice.Id, Name: invoice.InvoiceNumber },
      ExpiryDate: invoice.ExpiryDate,
      Description: invoice.Description,
      Amount: invoice.Amount.toFixed(3),
    });
    this.ExpiryDate = this.ngbDateAdapter.fromModel(
      new Date(invoice.ExpiryDate)
    );
  }

  saveInvoice(reset: boolean) {
    const invoice: CancelInvoice = {
      Id: this.cancelInvoiceForm.value.Id.Id,
      InvoiceNumber: this.cancelInvoiceForm.value.Id.Name,
      ExpiryDate: this.cancelInvoiceForm.value.ExpiryDate,
      Description: this.cancelInvoiceForm.value.Description,
      Amount: this.cancelInvoiceForm.value.Amount,
      Member: 0,
      MemberName: "",
      Status: "L",
    };
    if (invoice.Id > 1) {
      this.cancelInvoiceService.modifyCacnel(invoice).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Modified Successfully!", "");
            if (reset) {
              this.resetInvoice();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["cancel-invoices"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetInvoice() {
    this.cancelInvoiceForm.reset({
      Id: { Id: 0, Name: "" },
      ExpiryDate: new Date(),
      Description: "",
      Amount: 0,
    });
  }

  getInvoiceDetail() {
    let invoice: number;
    if (this.cancelInvoiceForm.value.Id != null) {
      invoice = this.cancelInvoiceForm.value.Id.Id;
      this.getInvoice(invoice);
    }
  }

  private loadInvoices() {
    this.invoice$ = concat(
      of([]),
      this.invoiceInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.invoiceLoading = true)),
        switchMap((term) =>
          this.cancelInvoiceService.getInvoiceListForCancel(term).pipe(
            catchError(() => of([])),
            tap(() => (this.invoiceLoading = false))
          )
        )
      )
    );
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
