import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Receipt, ReceiptInvoice } from "../../models/receipts/receipt";
import { StatusUpdate } from "../members/status-update";
import { GenerateInvoiceStatus } from "../../models/receipts/generate-invoice-status";
import { ReceiptApprovalList } from "../../models/receipts/Receipt-approval-list";
import { ReceiptInvoices } from "../../models/receipts/receipt-invoices";
import { UpdateInvoice } from "../../models/receipts/update-invoice";

@Injectable({
  providedIn: "root",
})
export class ReceiptsService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getReceipts(): Observable<Receipt[]> {
    return this.http.get<Receipt[]>(environment.root + "/Receipt");
  }

  getReceipt(id: number): Observable<Receipt> {
    return this.http.get<Receipt>(environment.root + "/Receipt/" + id);
  }

  getReceiptsBySearch(page, pageSize, type, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("type", type.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      Receipts: Receipt[];
    }>(environment.root + "/Receipt/GetAllGetReceiptsBySearch", { params });
  }

  addReceipt(receipt: Receipt) {
    return this.http
      .post(environment.root + "/Receipt/Add", receipt, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyReceipt(receipt: Receipt) {
    return this.http
      .post(environment.root + "/Receipt/Modify", receipt, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyStatus(receipt: StatusUpdate) {
    return this.http
      .post(
        environment.root + "/Receipt/ModifyStatus",
        receipt,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  approve(receipts: StatusUpdate[]) {
    return this.http
      .post(environment.root + "/Receipt/Approve", receipts, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteReceipt(id: number) {
    return this.http
      .post(environment.root + "/Receipt/Delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  checkExpiredInvoiceExistsOrNot(member): Observable<any> {
    const params = new HttpParams().set("member", member);

    return this.http.get<any>(
      environment.root + "/Receipt/CheckExpiredInvoiceExistOrNot",
      { params }
    );
  }

  generateInvoice(member, genValue, type): Observable<ReceiptInvoices> {
    const status: GenerateInvoiceStatus = {
      Member: member,
      Status: genValue,
      Type: type,
    };

    return this.http.post<ReceiptInvoices>(
      environment.root + "/Receipt/GetInvoices",
      status,
      this.httpOptions
    );
  }

  getReceiptsForApproval(searchFields): Observable<ReceiptApprovalList[]> {
    return this.http.post<ReceiptApprovalList[]>(
      environment.root + "/Receipt/GetReceiptsForApproval",
      searchFields
    );
  }

  GetInvoicesForUpdate(member): Observable<UpdateInvoice[]> {
    const params = new HttpParams().set("member", member);
    return this.http.get<UpdateInvoice[]>(
      environment.root + "/Receipt/GetInvoicesForUpdate",
      { params }
    );
  }

  updateInvoice(invoices: UpdateInvoice[]) {
    return this.http
      .post(
        environment.root + "/Receipt/UpdateInvoice",
        invoices,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
