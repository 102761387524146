import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import Swal, { SweetAlertType } from 'sweetalert2';
import { Observable, of, concat, Subject } from 'rxjs';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbStringAdapterService } from 'src/app/shared/services/ngb-string-adapter.service';
import { tap, catchError, switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { RevicedInvoice } from '../../core/models/receipts/reviced-invoice';
import { RevicedInvoiceService } from '../../core/services/receipts/reviced-invoice.service';
import { InvoiceList } from 'src/app/core/models/receipts/InvoiceList';

@Component({
  selector: 'app-reviced-invoice',
  templateUrl: './reviced-invoice.component.html',
  styleUrls: ['./reviced-invoice.component.css']
})
export class RevicedInvoiceComponent implements OnInit {

  public revicedInvoiceForm: FormGroup;
  ExpiryDate: NgbDateStruct;
  ReviceDate: NgbDateStruct;

  invoiceLoading = false;
  invoice$: Observable<InvoiceList[]>;
  invoiceInput$ = new Subject<string>();
  selectedIvoice: any;
  ZeroValue = 0;

  constructor(private revicedInvoiceService: RevicedInvoiceService,
              private ngbDateAdapter: NgbStringAdapterService,
              private formBuilder: FormBuilder, private calendar: NgbCalendar,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.ExpiryDate = this.calendar.getToday();
    this.ReviceDate = this.calendar.getToday();

    this.revicedInvoiceForm = new FormGroup({
      Id: new FormControl({Id: 0, Name: ''}),
      ExpiryDate: new FormControl(new Date()),
      Description: new FormControl(''),
      Amount: new FormControl(this.ZeroValue.toFixed(3)),
      BalanceAmount: new FormControl(this.ZeroValue.toFixed(3)),
      ReviceDate: new FormControl(new Date()),
      ReviceNotes: new FormControl('')
  });

    this.loadInvoices();

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      if (id) {
        this.getInvoice(id);
      }
    });
  }

  cancelInvoice() {
    this.router.navigate(['./', { outlets: { pages: ['reviced-invoices']} }], { relativeTo: this.route.parent });
  }

  getInvoice(Id: number) {
    this.revicedInvoiceService.getInvoice(Id).subscribe(
      (receipt: RevicedInvoice) => this.editInvoice(receipt),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['reviced-invoices']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editInvoice(invoice: RevicedInvoice) {
    this.revicedInvoiceForm.patchValue({
      Id: {Id: invoice.Id, Name: invoice.InvoiceNumber},
      ExpiryDate: invoice.ExpiryDate,
      Description: invoice.Description,
      Amount: invoice.Amount.toFixed(3),
      BalanceAmount: invoice.BalanceAmount.toFixed(3),
      ReviceDate: invoice.ReviceDate,
      ReviceNotes: invoice.ReviceNotes,
    });
    this.ExpiryDate = this.ngbDateAdapter.fromModel(new Date(invoice.ExpiryDate));
    this.ReviceDate = this.ngbDateAdapter.fromModel(new Date(invoice.ReviceDate));
  }

  saveInvoice(reset: boolean) {
    console.log(this.revicedInvoiceForm.value.ExpiryDate);
    console.log(this.revicedInvoiceForm.value.ReviceDate);
    const invoice: RevicedInvoice = {
      Id: this.revicedInvoiceForm.value.Id.Id,
      InvoiceNumber: this.revicedInvoiceForm.value.Id.Name,
      ExpiryDate: this.revicedInvoiceForm.value.ExpiryDate,
      Description: this.revicedInvoiceForm.value.Description,
      Amount: this.revicedInvoiceForm.value.Amount,
      BalanceAmount: this.revicedInvoiceForm.value.BalanceAmount,
      ReviceDate: this.revicedInvoiceForm.value.ReviceDate,
      ReviceNotes: this.revicedInvoiceForm.value.ReviceNotes,
      Member: 0,
      MemberName: '',
      HasRevice: true
    };
    if (invoice.Id < 1) {
      this.revicedInvoiceService.modifyRevice(invoice)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetInvoice();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['reviced-invoices']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.revicedInvoiceService.modifyRevice(invoice)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetInvoice();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['reviced-invoices']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  resetInvoice() {
    this.revicedInvoiceForm.reset({
      Id: {Id: 0, Name: ''},
      ExpiryDate: new Date(),
      Description: '',
      Amount: 0,
      BalanceAmount: 0,
      ReviceDate: new Date(),
      ReviceNotes: ''
    });
  }

  getInvoiceDetail() {
    let invoice: number;
    if (this.revicedInvoiceForm.value.Id != null) {
      invoice = this.revicedInvoiceForm.value.Id.Id;
      this.getInvoice(invoice);
    }
  }

  private loadInvoices() {
    this.invoice$ = concat(
      of([]),
      this.invoiceInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.invoiceLoading = true),
        switchMap(
          term => this.revicedInvoiceService.getExpiredInvoiceList(term).pipe(
            catchError(() => of([])),
            tap(() => this.invoiceLoading = false)
          )
        )
      )
    );
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
