import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject, concat, of } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import {
  debounceTime,
  map,
  debounce,
  switchMap,
  catchError,
  tap,
  distinctUntilChanged,
} from "rxjs/operators";
import { HttpClient, HttpRequest, HttpEventType } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import Swal, { SweetAlertType } from "sweetalert2";

import { Member } from "../../core/models/members/member";
import { Zone } from "../../core/models/masters/zone";
import { Branch } from "../../core/models/masters/branch";
import { Unit } from "../../core/models/masters/unit";
import { Qualification } from "../../core/models/masters/qualification";
import { Country } from "../../core/models/masters/country";
import { State } from "../../core/models/masters/state";
import { District } from "../../core/models/masters/district";
import { Panchayath } from "../../core/models/masters/panchayath";
import { PostOffice } from "../../core/models/masters/post-office";
import { Relation } from "../../core/models/masters/relation";
import { Occupation } from "../../core/models/masters/occupation";
import { Talent } from "../../core/models/masters/talent";

import { MembersService } from "../../core/services/members/members.service";
import { ZonesService } from "../../core/services/masters/zones.service";
import { BranchesService } from "../../core/services/masters/branches.service";
import { UnitsService } from "../../core/services/masters/units.service";
import { QualificationsService } from "../../core/services/masters/qualifications.service";
import { CountriesService } from "../../core/services/masters/countries.service";
import { StatesService } from "../../core/services/masters/states.service";
import { DistrictsService } from "../../core/services/masters/districts.service";
import { PanchayathsService } from "../../core/services/masters/panchayaths.service";
import { PostOfficesService } from "../../core/services/masters/post-offices.service";
import { RelationsService } from "../../core/services/masters/relations.service";
import { OccupationsService } from "../../core/services/masters/occupations.service";
import { TalentsService } from "../../core/services/masters/talents.service";
import { MemberTalent } from "../../core/models/members/member";
import { MemberList } from "src/app/core/models/members/member-List";
import { MemberTalentList } from "src/app/core/models/members/member-talent-list";
import { StatusUpdate } from "src/app/core/services/members/status-update";
import { stringify } from "@angular/compiler/src/util";
import { UserDetails } from "src/app/core/models/system/user-details";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-member",
  templateUrl: "./member.component.html",
  styleUrls: ["./member.component.css"],
})
export class MemberComponent implements OnInit {
  @ViewChild("fileProfileImage", { static: true }) fileProfileImage: ElementRef;
  profileImageUrl = "";
  selectedProfilePicture: any = null;

  userDetails: UserDetails;
  menuId: number;

  profileImage: SafeResourceUrl;

  public message: string;

  EmergencyContact1Type = [
    { EmergencyContact1Type: "R", EmergencyContact1TypeName: "Relative" },
    { EmergencyContact1Type: "F", EmergencyContact1TypeName: "Friend" },
  ];
  EmergencyContact2Type = [
    { EmergencyContact2Type: "R", EmergencyContact2TypeName: "Relative" },
    { EmergencyContact2Type: "F", EmergencyContact2TypeName: "Friend" },
  ];
  Status = [
    { Status: "A", StatusName: "Active" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "E", StatusName: "Expired" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  BloodGroup = [
    { BloodGroup: "OP", BloodGroupName: "O-positive" },
    { BloodGroup: "ON", BloodGroupName: "O-negative" },
    { BloodGroup: "AP", BloodGroupName: "A-positive" },
    { BloodGroup: "AN", BloodGroupName: "A-negative" },
    { BloodGroup: "BP", BloodGroupName: "B-positive" },
    { BloodGroup: "BN", BloodGroupName: "B-negative" },
    { BloodGroup: "ABP", BloodGroupName: "AB-positive" },
    { BloodGroup: "ABN", BloodGroupName: "AB-negative" },
  ];

  KKMADate: NgbDateStruct;
  DateOfBirth: NgbDateStruct;
  RejoinDate: NgbDateStruct;

  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  qualifications$: Observable<Qualification[]>;
  countries$: Observable<Country[]>;
  states$: Observable<State[]>;
  districts$: Observable<District[]>;
  panchayaths$: Observable<Panchayath[]>;
  postoffices$: Observable<PostOffice[]>;
  relations$: Observable<Relation[]>;
  occupations$: Observable<Occupation[]>;
  areas$: Observable<State[]>;
  blocks$: Observable<District[]>;
  streets$: Observable<Panchayath[]>;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  talents$: Observable<MemberTalentList[]>;
  selectedTalents: any;

  public memberForm: FormGroup;
  public familyList: FormArray;
  public talentList: FormArray;

  Id: number;
  Referrer: number;
  ReferrerName: string;

  approvedStatus: boolean;
  rejectedStatus: boolean;
  _rejoinDate: boolean = false;
  closeResult: string;

  _pKKMAId: string;
  _pFullName: string;
  _pZone: string;
  _pKKMADate: Date;
  _pBranch: string;
  _pUnit: string;
  _pStarClub: string;
  _isRenewed: boolean;
  _idExpiryDate: string;

  constructor(
    private memberService: MembersService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private qualificationService: QualificationsService,
    private countryService: CountriesService,
    private stateService: StatesService,
    private districtService: DistrictsService,
    private panchayathService: PanchayathsService,
    private postofficeService: PostOfficesService,
    private relationService: RelationsService,
    private occupationService: OccupationsService,
    private talentService: TalentsService,
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private readonly sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {
    this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
      "../../../assets/images/profile-img.jpg"
    );
  }

  ngOnInit() {
    this.Id = 0;
    this._rejoinDate = false;
    this.zones$ = this.zoneService.getZones();
    this.qualifications$ = this.qualificationService.getQualifications();
    this.countries$ = this.countryService.getCountries();
    this.relations$ = this.relationService.getRelations();
    this.occupations$ = this.occupationService.getOccupations();
    this.areas$ = this.stateService.getCountryStates(2);

    this.loadStarClubMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 21000;

    this.memberForm = this.formBuilder.group({
      Id: new FormControl(0),
      KKMAID: new FormControl(""),
      KKMADate: new FormControl(new Date()),
      KKMAZone: new FormControl(0),
      KKMAZoneName: new FormControl(""),
      KKMABranch: new FormControl(0),
      KKMABranchName: new FormControl(""),
      KKMAUnit: new FormControl(0),
      KKMAUnitName: new FormControl(""),
      Referrer: new FormControl({ Id: 0, Name: "" }),
      ReferrerName: new FormControl(""),
      FullName: new FormControl(""),
      DateOfBirth: new FormControl(new Date()),
      CivilID: new FormControl(""),
      PassportNumber: new FormControl(""),
      PACINumber: new FormControl(""),
      Phone: new FormControl(""),
      Mobile: new FormControl(""),
      ConfirmMobile: new FormControl(""),
      Email: new FormControl(""),
      WhatsApp: new FormControl(""),
      Facebook: new FormControl(""),
      Skype: new FormControl(""),
      IsResidentInKuwait: new FormControl(true),
      Street: new FormControl(0),
      Block: new FormControl(0),
      BlockName: new FormControl(""),
      Area: new FormControl(0),
      AreaName: new FormControl(""),
      Occupation: new FormControl(0),
      OccupationName: new FormControl(""),
      Employer: new FormControl(""),
      Qualification: new FormControl(0),
      QualificationName: new FormControl(""),
      WorkPhone: new FormControl(""),
      WorkExtension: new FormControl(""),
      WorkEmail: new FormControl(""),
      BloodGroup: new FormControl("OP"),
      IsReadyToDonateBlood: new FormControl(true),
      EmergencyContact1: new FormControl(""),
      EmergencyContact1Type: new FormControl("R"),
      EmergencyContact1TypeName: new FormControl(""),
      EmergencyContact1Phone: new FormControl(""),
      EmergencyContact2: new FormControl(""),
      EmergencyContact2Type: new FormControl("R"),
      EmergencyContact2TypeName: new FormControl(""),
      EmergencyContact2Phone: new FormControl(""),
      HAHouseName: new FormControl(""),
      HAPlace: new FormControl(""),
      HAPostOffice: new FormControl(0),
      HAPostOfficeName: new FormControl(""),
      HAPinCode: new FormControl(""),
      HAPanchayath: new FormControl(0),
      HAPanchayathName: new FormControl(""),
      HADistrict: new FormControl(0),
      HADistrictName: new FormControl(""),
      HAState: new FormControl(0),
      HAStateName: new FormControl(""),
      HACountry: new FormControl(0),
      HACountryName: new FormControl(""),
      HAPhone: new FormControl(""),
      HAMobile: new FormControl(""),
      ConfirmHAMobile: new FormControl(""),
      Organizations: new FormControl(""),
      Extracurriculars: new FormControl(""),
      TalentList: new FormControl(""),
      Photo: new FormControl(""),
      Status: new FormControl("I"),
      StatusName: new FormControl("Inactive"),
      AppStatus: new FormControl("O"),
      AppStatusName: new FormControl("Open"),
      RejoinDate: new FormControl(new Date()),
      families: this.formBuilder.array([]),
      talents: this.formBuilder.array([]),
    });
    this.familyList = this.memberForm.get("families") as FormArray;
    this.talentList = this.memberForm.get("talents") as FormArray;

    this._pKKMAId = "";
    this._pFullName = "";
    this._pZone = "";
    this._pKKMADate = new Date();
    this._pBranch = "";
    this._pUnit = "";
    this._pStarClub = "";
    this._isRenewed = false;
    this._idExpiryDate = "";

    this.route.paramMap.subscribe((params) => {
      this.KKMADate = this.calendar.getToday();
      this.DateOfBirth = this.calendar.getToday();
      this.RejoinDate = this.calendar.getToday();

      const expID = +params.get("id");
      if (expID) {
        this.getMember(expID);
      } else {
        this.talents$ = this.memberService.getMemberTalentList(0);
      }
    });
  }

  cancelMember() {
    this.router.navigate(["./", { outlets: { pages: ["members"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getMember(Id: number) {
    this.memberService.getMember(Id).subscribe(
      (member: Member) => this.editMember(member),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["members"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editMember(member: Member) {
    this.memberForm.patchValue({
      Id: member.Id,
      KKMAID: member.KKMAID,
      KKMADate: member.KKMADate,
      KKMAZone: member.KKMAZone,
      KKMAZoneName: member.KKMAZoneName,
      KKMABranch: member.KKMABranch,
      KKMABranchName: member.KKMABranchName,
      KKMAUnit: member.KKMAUnit,
      KKMAUnitName: member.KKMAUnitName,
      Referrer: { Id: member.Referrer, Name: member.ReferrerName },
      ReferrerName: member.ReferrerName,
      FullName: member.FullName,
      DateOfBirth: member.DateOfBirth,
      CivilID: member.CivilID,
      PassportNumber: member.PassportNumber,
      PACINumber: member.PACINumber,
      Phone: member.Phone,
      Mobile: member.Mobile,
      ConfirmMobile: member.ConfirmMobile,
      Email: member.Email,
      WhatsApp: member.WhatsApp,
      Facebook: member.Facebook,
      Skype: member.Skype,
      IsResidentInKuwait: member.IsResidentInKuwait,
      Street: member.Street,
      Block: member.Block,
      Area: member.Area,
      Occupation: member.Occupation,
      Employer: member.Employer,
      Qualification: member.Qualification,
      QualificationName: member.QualificationName,
      WorkPhone: member.WorkPhone,
      WorkExtension: member.WorkExtension,
      WorkEmail: member.WorkEmail,
      BloodGroup: member.BloodGroup,
      IsReadyToDonateBlood: member.IsReadyToDonateBlood,
      EmergencyContact1: member.EmergencyContact1,
      EmergencyContact1Type: member.EmergencyContact1Type,
      EmergencyContact1Phone: member.EmergencyContact1Phone,
      EmergencyContact2: member.EmergencyContact2,
      EmergencyContact2Type: member.EmergencyContact2Type,
      EmergencyContact2Phone: member.EmergencyContact2Phone,
      HAHouseName: member.HAHouseName,
      HAPlace: member.HAPlace,
      HACountry: member.HACountry,
      HACountryName: member.HACountryName,
      HAState: member.HAState,
      HAStateName: member.HAStateName,
      HADistrict: member.HADistrict,
      HADistrictName: member.HADistrictName,
      HAPostOffice: member.HAPostOffice,
      HAPostOfficeName: member.HAPostOfficeName,
      HAPinCode: member.HAPinCode,
      HAPanchayath: member.HAPanchayath,
      HAPanchayathName: member.HAPanchayathName,
      HAPhone: member.HAPhone,
      HAMobile: member.HAMobile,
      ConfirmHAMobile: member.ConfirmHAMobile,
      Organizations: member.Organizations,
      Extracurriculars: member.Extracurriculars,
      Photo: member.Photo,
      Status: member.Status,
      AppStatus: member.AppStatus,
      AppStatusName:
        member.AppStatus === "O"
          ? "Open"
          : member.AppStatus === "A"
          ? "Approved"
          : member.AppStatus === "R"
          ? "Rejected"
          : "Open",
      RejoinDate: member.RejoinDate,
      families: [],
      talents: [],
    });
    this.Id = member.Id;
    this.Referrer = member.Referrer;
    this.ReferrerName = member.ReferrerName;

    if (member.KKMADate < member.RejoinDate) {
      this._rejoinDate = true;
    }
    this.selectedStarClub = {
      Id: member.Referrer,
      FullName: member.ReferrerName,
    };

    this.talents$ = this.memberService.getMemberTalentList(0);

    this.setSelectedTalents(member.MemberTalents);
    this.createFormFamily(member);
    this.createFormTalent(member.MemberTalents);
    this.KKMADate = this.ngbDateAdapter.fromModel(new Date(member.KKMADate));
    this.DateOfBirth = this.ngbDateAdapter.fromModel(
      new Date(member.DateOfBirth)
    );
    this.RejoinDate = this.ngbDateAdapter.fromModel(
      new Date(member.RejoinDate)
    );
    if (member.Photo !== "") {
      this.profileImageUrl =
        environment.fileSystem + "/MemberProfile/" + member.Photo;
    } else {
      this.profileImageUrl = null;
    }

    this._pKKMAId = member.KKMAID;
    this._pFullName = member.FullName;
    this._pZone = member.KKMAZoneName;
    this._pKKMADate = member.KKMADate;
    this._pBranch = member.KKMABranchName;
    this._pUnit = member.KKMAUnitName;
    this._pStarClub = member.ReferrerName;
    this._isRenewed = member.IsRenewed;
    this._idExpiryDate = member.IdExpiryDate;

    this.downloadProfilePicture();
    this.statusMenuVisible(member.Id, member.AppStatus);
  }

  saveMember(reset: boolean) {
    const member: Member = {
      Id: this.memberForm.value.Id,
      KKMAID: this.memberForm.value.KKMAID,
      KKMADate: this.memberForm.value.KKMADate,
      KKMAZone: this.memberForm.value.KKMAZone,
      KKMAZoneName: this.memberForm.value.KKMAZoneName,
      KKMABranch: this.memberForm.value.KKMABranch,
      KKMABranchName: this.memberForm.value.KKMABranchName,
      KKMAUnit: this.memberForm.value.KKMAUnit,
      KKMAUnitName: this.memberForm.value.KKMAUnitName,
      IsStarClubMember: false,
      Referrer: this.memberForm.value.Referrer.Id,
      ReferrerName: this.memberForm.value.Referrer.ReferrerName,
      FullName: this.memberForm.value.FullName,
      DateOfBirth: this.memberForm.value.DateOfBirth,
      CivilID: this.memberForm.value.CivilID,
      PassportNumber: this.memberForm.value.PassportNumber,
      PACINumber: this.memberForm.value.PACINumber,
      Phone: this.memberForm.value.Phone,
      Mobile: this.memberForm.value.Mobile,
      ConfirmMobile: this.memberForm.value.ConfirmMobile,
      Email: this.memberForm.value.Email,
      WhatsApp: this.memberForm.value.WhatsApp,
      Facebook: this.memberForm.value.Facebook,
      Skype: this.memberForm.value.Skype,
      IsResidentInKuwait: this.memberForm.value.IsResidentInKuwait,
      Street: this.memberForm.value.Street,
      Block: this.memberForm.value.Block,
      BlockName: this.memberForm.value.BlockName,
      Area: this.memberForm.value.Area,
      AreaName: this.memberForm.value.AreaName,
      Occupation: this.memberForm.value.Occupation,
      OccupationName: this.memberForm.value.OccupationName,
      Employer: this.memberForm.value.Employer,
      Qualification: this.memberForm.value.Qualification,
      QualificationName: this.memberForm.value.QualificationName,
      WorkPhone: this.memberForm.value.WorkPhone,
      WorkExtension: this.memberForm.value.WorkExtension,
      WorkEmail: this.memberForm.value.WorkEmail,
      BloodGroup: this.memberForm.value.BloodGroup,
      IsReadyToDonateBlood: this.memberForm.value.IsReadyToDonateBlood,
      EmergencyContact1: this.memberForm.value.EmergencyContact1,
      EmergencyContact1Type: this.memberForm.value.EmergencyContact1Type,
      EmergencyContact1Phone: this.memberForm.value.EmergencyContact1Phone,
      EmergencyContact2: this.memberForm.value.EmergencyContact2,
      EmergencyContact2Type: this.memberForm.value.EmergencyContact2Type,
      EmergencyContact2Phone: this.memberForm.value.EmergencyContact2Phone,
      HAHouseName: this.memberForm.value.HAHouseName,
      HAPlace: this.memberForm.value.HAPlace,
      HAPostOffice: this.memberForm.value.HAPostOffice,
      HAPostOfficeName: this.memberForm.value.HAPostOfficeName,
      HAPinCode: this.memberForm.value.HAPinCode,
      HAPanchayath: this.memberForm.value.HAPanchayath,
      HAPanchayathName: this.memberForm.value.HAPanchayathName,
      HADistrict: this.memberForm.value.HADistrict,
      HADistrictName: this.memberForm.value.HADistrictName,
      HAState: this.memberForm.value.HAState,
      HAStateName: this.memberForm.value.HAStateName,
      HACountry: this.memberForm.value.HACountry,
      HACountryName: this.memberForm.value.HACountryName,
      HAPhone: this.memberForm.value.HAPhone,
      HAMobile: this.memberForm.value.HAMobile,
      ConfirmHAMobile: this.memberForm.value.ConfirmHAMobile,
      Organizations: this.memberForm.value.Organizations,
      Extracurriculars: this.memberForm.value.Extracurriculars,
      Photo: this.memberForm.value.Photo,
      Status: this.memberForm.value.Status,
      AppStatus: this.memberForm.value.AppStatus,
      RejoinDate: this.memberForm.value.RejoinDate,
      CreatedBy: "",
      CreatedOn: new Date(),
      EditedBy: "",
      EditedOn: new Date(),
      FamilyDetails: this.memberForm.value.families,
      MemberTalents: null, // this.memberForm.value.talents
      IsRenewed: false,
      IdExpiryDate: "",
    };

    const talents: MemberTalent[] = [];

    if (this.selectedTalents) {
      for (const talent of this.selectedTalents) {
        const c: MemberTalent = {
          MTId: talent.Id,
          MTMember: talent.Member,
          MTTalent: talent.Talent,
          MTTalentName: talent.TalentName,
          MTActive: true,
        };
        talents.push(c);
      }
    }
    member.MemberTalents = talents;
    if (member.Id < 1) {
      this.memberService.addMember(member).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            this.memberForm.value.Id = response.message.substr(25);
            if (this.selectedProfilePicture !== null) {
              this.uploadProfilePicture(this.selectedProfilePicture);
            }
            if (reset) {
              this.resetMember();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["members"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.memberService.modifyMember(member).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMember();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["members"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  updateStatus(stat) {
    if (this.memberForm.value.Id < 1) {
      this.displayFeedback(
        "error",
        "Error!",
        "Please select a Member to update status!"
      );
    } else {
      const transfer: StatusUpdate = {
        Id: this.memberForm.value.Id,
        Status: stat,
        EditedBy: "",
      };

      this.memberService.modifyStatus(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Status Updated Successfully!", "");
            this.memberForm.patchValue({
              Status: stat,
              StatusName:
                stat === "O"
                  ? "Open"
                  : stat === "A"
                  ? "Approved"
                  : stat === "R"
                  ? "Rejected"
                  : "Open",
            });
            this.statusMenuVisible(transfer.Id, stat);
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetMember() {
    this.memberForm.reset({
      Id: 0,
      KKMAID: "",
      KKMADate: new Date(),
      KKMAZone: 0,
      KKMAZoneName: "",
      KKMABranch: 0,
      KKMABranchName: "",
      KKMAUnit: 0,
      KKMAUnitName: "",
      KKMAStarClub: "",
      Referrer: new FormControl({ Id: 0, Name: "" }),
      ReferrerName: new FormControl(""),
      FullName: "",
      DateOfBirth: new Date(),
      CivilID: "",
      PassportNumber: "",
      PACINumber: "",
      Phone: "",
      Mobile: "",
      ConfirmMobile: "",
      Email: "",
      WhatsApp: "",
      Facebook: "",
      Skype: "",
      IsResidentInKuwait: true,
      Street: 0,
      Block: 0,
      BlockName: "",
      Area: 0,
      AreaName: "",
      Occupation: 0,
      OccupationName: "",
      Employer: "",
      Qualification: 0,
      QualificationName: "",
      WorkPhone: "",
      WorkExtension: "",
      WorkEmail: "",
      BloodGroup: "OP",
      IsReadyToDonateBlood: true,
      EmergencyContact1: "",
      EmergencyContact1Type: "R",
      EmergencyContact1Phone: "",
      EmergencyContact2: "",
      EmergencyContact2Type: "R",
      EmergencyContact2Phone: "",
      HAHouseName: "",
      HAPlace: "",
      HAPostOffice: 0,
      HAPostOfficeName: "",
      HAPinCode: "",
      HAPanchayath: 0,
      HAPanchayathName: "",
      HADistrict: 0,
      HADistrictName: "",
      HAState: 0,
      HAStateName: "",
      HACountry: 0,
      HACountryName: "",
      HAPhone: "",
      HAMobile: "",
      ConfirmHAMobile: "",
      Organizations: "",
      Extracurriculars: "",
      Photo: "",
      Status: "A",
      StatusName: "Active",
      AppStatus: "O",
      AppStatusName: "Open",
    });
    this._rejoinDate = false;

    this._pKKMAId = "";
    this._pFullName = "";
    this._pZone = "";
    this._pKKMADate = new Date();
    this._pBranch = "";
    this._pUnit = "";
    this._pStarClub = "";
    this._isRenewed = false;
    this._idExpiryDate = "";

    this.removeFamilyOnReset();
    this.getTalents();
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
    this.setKKMAIdToNull();
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
    this.setKKMAIdToNull();
  }

  setKKMAIdToNull() {}

  setSelectedTalents(memeberTalents) {
    // MemberTalentList
    const selectedTalents: MemberTalentList[] = [];

    if (memeberTalents) {
      for (const talent of memeberTalents) {
        if (talent.MTId > 0) {
          const c: MemberTalentList = {
            Id: talent.MTId,
            Member: talent.MTMember,
            Talent: talent.MTTalent,
            TalentName: talent.MTTalentName,
          };
          selectedTalents.push(c);
        }
      }
    }
    this.selectedTalents = selectedTalents;
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  getStates(country: number) {
    this.states$ = this.stateService.getCountryStates(country);
    this.districts$ = this.districtService.getStateDistricts(0);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
    this.postoffices$ = this.postofficeService.getDistrictPostoffices(0);
  }

  getDistricts(state: number) {
    this.districts$ = this.districtService.getStateDistricts(state);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
    this.postoffices$ = this.postofficeService.getDistrictPostoffices(0);
  }

  getPanchayathsAndPO(district: number) {
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(district);
    this.postoffices$ = this.postofficeService.getDistrictPostoffices(district);
  }

  getBlocks(area: number) {
    this.blocks$ = this.districtService.getStateDistricts(area);
    this.streets$ = this.panchayathService.getDistrictPanchayaths(0);
  }

  getStreets(block: number) {
    this.streets$ = this.panchayathService.getDistrictPanchayaths(block);
  }

  setPostOffice(postOffice: number) {
    this.postofficeService.getPinCode(postOffice.toString()).subscribe(
      (response) => {
        console.log(response);
        if (response != null) {
          this.memberForm.patchValue({
            HAPinCode: response.toString(),
          });
        }
      },
      (err: any) => console.log(err)
    );
  }

  generateDateofBirth() {
    if (this.memberForm.value.CivilID.length == 7) {
      const cyear = parseInt(this.memberForm.value.CivilID.substr(1, 2));
      const month = parseInt(this.memberForm.value.CivilID.substr(3, 2)); // months are zero-based
      const day = parseInt(this.memberForm.value.CivilID.substr(5, 2));
      const year = cyear + (cyear > 50 ? 1900 : 2000);

      // Create a Date object using the extracted values
      const dob = new Date(year, month - 1, day);

      this.memberForm.patchValue({
        DateOfBirth: dob,
      });
    }
  }

  get familyFormGroup() {
    return this.memberForm.get("families") as FormArray;
  }

  createFormFamily(member: Member) {
    for (const family of member.FamilyDetails) {
      this.familyList.push(
        this.createFamilyWithValues(
          family.FMId,
          family.FMName,
          family.FMRelation,
          family.FMRelationName,
          family.FMIsAge,
          family.FMAge,
          family.FMDateOfBirth,
          family.FMQualification,
          family.FMQualificationName,
          family.FMOccupation,
          family.FMOccupationName,
          family.FMIsResidentInKuwait,
          family.FMStatus
        )
      );
    }
  }

  createFamilyWithValues(
    FMId: number,
    FMName: string,
    FMRelation: number,
    FMRelationName: string,
    FMIsAge: boolean,
    FMAge: number,
    FMDateOfBirth: Date,
    FMQualification: number,
    FMQualificationName: string,
    FMOccupation: number,
    FMOccupationName: string,
    FMIsResidentInKuwait: boolean,
    FMStatus: string
  ): FormGroup {
    return this.formBuilder.group({
      FMId: new FormControl(FMId),
      FMName: new FormControl(FMName),
      FMRelation: new FormControl(FMRelation),
      FMRelationName: new FormControl(FMRelationName),
      FMIsAge: new FormControl(FMIsAge),
      FMAge: new FormControl(FMAge),
      FMDateOfBirth: new FormControl(FMDateOfBirth),
      FMQualification: new FormControl(FMQualification),
      FMQualificationName: new FormControl(FMQualificationName),
      FMOccupation: new FormControl(FMOccupation),
      FMOccupationName: new FormControl(FMOccupationName),
      FMIsResidentInKuwait: new FormControl(FMIsResidentInKuwait),
      FMStatus: new FormControl(FMStatus),
    });
  }

  createFamily(): FormGroup {
    return this.formBuilder.group({
      FMId: new FormControl(0),
      FMName: new FormControl(""),
      FMRelation: new FormControl(0),
      FMRelationName: new FormControl(""),
      FMIsAge: new FormControl(false),
      FMAge: new FormControl(0),
      FMDateOfBirth: new FormControl(new Date()),
      FMQualification: new FormControl(0),
      FMQualificationName: new FormControl(""),
      FMOccupation: new FormControl(0),
      FMOccupationName: new FormControl(""),
      FMIsResidentInKuwait: new FormControl(false),
      FMStatus: new FormControl("A"),
    });
  }

  addFamily() {
    this.familyList.push(this.createFamily());
  }

  removeFamilyOnReset() {
    for (let i = this.memberForm.value.families.length - 1; i >= 0; i--) {
      this.removeFamily(i);
    }
  }

  removeFamily(index) {
    this.familyList.removeAt(index);
  }

  changeAge(index: number) {
    let familyList: any[];
    familyList = this.memberForm.value.families;
    if (familyList[index].FMIsAge) {
      let d = new Date();

      let age = familyList[index].FMAge;
      var dob = new Date(d.getFullYear() - age, d.getMonth(), d.getDate());
      familyList[index].FMDateOfBirth = dob;
    } else {
      let d = new Date();
      let age = this.dateDifference(d, familyList[index].FMDateOfBirth) / 365;
      familyList[index].FMAge = Math.trunc(age);
    }

    this.memberForm.patchValue({
      families: familyList,
    });
  }

  dateDifference(fromDate: Date, toDate: Date) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    return (
      Math.floor(
        (Date.UTC(
          fromDate.getFullYear(),
          fromDate.getMonth(),
          fromDate.getDate()
        ) -
          Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())) /
          (1000 * 60 * 60 * 24)
      ) + 1
    );
  }

  createFormTalent(member: MemberTalent[]) {
    for (const talent of member) {
      this.talentList.push(
        this.createTalentWithValues(
          talent.MTId,
          talent.MTMember,
          talent.MTTalent,
          talent.MTTalentName,
          talent.MTActive
        )
      );
    }
  }

  createTalentWithValues(
    MTId: number,
    MTMember: number,
    MTTalent: number,
    MTTalentName: string,
    MTActive: boolean
  ): FormGroup {
    return this.formBuilder.group({
      MTId: new FormControl(MTId),
      MTMember: new FormControl(MTMember),
      MTTalent: new FormControl(MTTalent),
      MTTalentName: new FormControl(MTTalentName),
      MTActive: new FormControl(MTActive),
    });
  }

  get talentFormGroup() {
    return this.memberForm.get("talents") as FormArray;
  }

  getKKMAId() {
    if (this.memberForm.value.KKMABranch === 0) {
      alert("Please select a branch");
    } else {
      this.memberService.getKKMAId(this.memberForm.value.KKMABranch).subscribe(
        (response) => {
          this.memberForm.patchValue({
            KKMAID: response.CivilId,
          });
        },
        (err: any) => console.log(err)
      );
    }
  }

  getTalents() {
    this.memberService.getMemberTalents(0).subscribe((response) => {
      this.createFormTalent(response.Talents as MemberTalent[]);
    });
  }

  showFileDialogue() {
    if (this.fileProfileImage.nativeElement && document.createEvent) {
      const evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      this.fileProfileImage.nativeElement.dispatchEvent(evt);
    }
  }

  onSelectProfileImage(event) {
    if (this.memberForm.value.Id > 0) {
      this.uploadProfilePicture(event.target.files);
    } else {
      this.selectedProfilePicture = event.target.files;
    }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.profileImageUrl = reader.result as string;
    };
  }

  uploadProfilePicture(files: any) {
    if (files.length === 0) {
      return;
    }
    const formData = new FormData();

    for (const file of files) {
      formData.append(file.name, file);
    }

    const uploadReq = new HttpRequest(
      "POST",
      environment.root +
        "/Member/UploadProfilePicture/" +
        this.memberForm.value.Id.toString(),
      formData,
      { reportProgress: true }
    );

    this.httpClient.request(uploadReq).subscribe((events) => {
      if (events.type === HttpEventType.Response) {
        this.message = events.body.toString();
        this.downloadProfilePicture();
      }
    });
  }

  downloadProfilePicture() {
    let attachType: string;
    let attachName: string;

    this.memberService
      .downloadProfilePicture(this.memberForm.value.Id)
      .subscribe((response: any) => {
        const resBlob = new Blob([response], { type: "image/jpeg" });
        this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(resBlob)
        );
      });
  }

  statusMenuVisible(id, status) {
    if (id < 0) {
      this.approvedStatus = false;
      this.rejectedStatus = false;
    } else {
      if (status === "O") {
        this.approvedStatus = true;
        this.rejectedStatus = true;
      } else {
        this.approvedStatus = false;
        this.rejectedStatus = false;
      }
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.memberForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.memberForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  onCivilIdChange() {}

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
