import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import Swal, { SweetAlertType } from 'sweetalert2';
import { Unit } from '../../core/models/masters/unit';
import { Branch } from '../../core/models/masters/branch';
import { UnitsService } from '../../core/services/masters/units.service';
import { BranchesService } from '../../core/services/masters/branches.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;
  branches$: Observable<Branch[]>;

  unitForm = new FormGroup({
    Id: new FormControl(0),
    Code: new FormControl(''),
    Name: new FormControl(''),
    Branch: new FormControl(0),
    BranchName: new FormControl(''),
    Active: new FormControl(true)
  });

  constructor(private unitService: UnitsService, private branchService: BranchesService,private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.branches$ = this.branchService.getBranches();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 13000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      if (id) {
        this.getUnit(id);
      }
    });
  }

  cancelUnit() {
    this.router.navigate(['./', { outlets: { pages: ['units']} }], { relativeTo: this.route.parent });
  }

  getUnit(Id: number) {
    this.unitService.getUnit(Id).subscribe(
      (unit: Unit) => this.editUnit(unit),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['units']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editUnit(unit: Unit) {
    this.unitForm.patchValue({
      Id: unit.Id,
      Code: unit.Code,
      Name: unit.Name,
      Branch: unit.Branch,
      BranchName: unit.BranchName,
      Active: unit.Active
    });
  }

  saveUnit(reset: boolean) {
    const unit: Unit = this.unitForm.value as Unit;
    if (unit.Id < 1) {
      this.unitService.addUnit(unit)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetUnit();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['units']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.unitService.modifyUnit(unit)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetUnit();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['units']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  resetUnit() {
    this.unitForm.reset({
      Id: 0,
      Code: '',
      Name: '',
      Branch: 0,
      BranchName: '',
      Active: true
    });
  }

  hasAdd() {
    let privilage = false;

      this.userDetails.Menus.forEach(value => {
         if (value.Id === this.menuId) {
           if(value.PrivilegeAdd && this.unitForm.value.Id < 1)
              privilage = true;
            else if(value.PrivilegeModify && this.unitForm.value.Id > 0)
              privilage = true;
         }
      });

      return privilage;
    }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
