import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import Swal, { SweetAlertType } from 'sweetalert2';
import { Observable } from 'rxjs';
import { District } from 'src/app/core/models/masters/district';
import { PostOffice } from '../../core/models/masters/post-office';
import { PostOfficesService } from '../../core/services/masters/post-offices.service';
import { DistrictsService } from '../../core/services/masters/districts.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-post-office',
  templateUrl: './post-office.component.html',
  styleUrls: ['./post-office.component.css']
})
export class PostOfficeComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;
  districts$: Observable<District[]>;

  postOfficeForm = new FormGroup({
    Id: new FormControl(0),
    Code: new FormControl(''),
    Name: new FormControl(''),
    District: new FormControl(0),
    DistrictName: new FormControl(''),
    Active: new FormControl(true)
  });

  constructor(private postOfficeService: PostOfficesService, private districtService: DistrictsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.districts$ = this.districtService.getDistricts();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 18000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      if (id) {
        this.getPostOffice(id);
      }
    });
  }

  cancelPostOffice() {
    this.router.navigate(['./', { outlets: { pages: ['post-offices']} }], { relativeTo: this.route.parent });
  }

  getPostOffice(Id: number) {
    this.postOfficeService.getPostoffice(Id).subscribe(
      (postOffice: PostOffice) => this.editPostOffice(postOffice),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['post-offices']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editPostOffice(postOffice: PostOffice) {
    this.postOfficeForm.patchValue({
      Id: postOffice.Id,
      Code: postOffice.Code,
      Name: postOffice.Name,
      District: postOffice.District,
      DistrictName: postOffice.DistrictName,
      Active: postOffice.Active
    });
  }

  savePostOffice(reset: boolean) {
    const postOffice: PostOffice = this.postOfficeForm.value as PostOffice;
    if (postOffice.Id < 1) {
      this.postOfficeService.addPostoffice(postOffice)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetPostOffice();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['post-offices']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.postOfficeService.modifyPostoffice(postOffice)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetPostOffice();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['post-offices']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  resetPostOffice() {
    this.postOfficeForm.reset({
      Id: 0,
      Code: '',
      Name: '',
      District: 0,
      DistrictName: '',
      Active: true
    });
  }

  hasAdd() {
    let privilage = false;

      this.userDetails.Menus.forEach(value => {
         if (value.Id === this.menuId) {
           if(value.PrivilegeAdd && this.postOfficeForm.value.Id < 1)
              privilage = true;
            else if(value.PrivilegeModify && this.postOfficeForm.value.Id > 0)
              privilage = true;
         }
      });

      return privilage;
    }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
