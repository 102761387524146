import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { ReceiptHistory } from "../../../core/models/reports/receipts/Receipts-history";
import { ReceiptsHistorySearch } from "src/app/core/models/reports/receipts/receipts-history-search";
import { UserDetails } from "src/app/core/models/system/user-details";
import { concat, Observable, of, Subject } from "rxjs";
import { Branch } from "src/app/core/models/masters/branch";
import { Unit } from "src/app/core/models/masters/unit";
import { MemberList } from "src/app/core/models/members/member-List";
import { Fbs } from "src/app/core/models/schemes/fbs";
import { Fine } from "src/app/core/models/schemes/fine";
import { Membership } from "src/app/core/models/schemes/membership";
import { BranchesService } from "src/app/core/services/masters/branches.service";
import { UnitsService } from "src/app/core/services/masters/units.service";
import { MembersService } from "src/app/core/services/members/members.service";
import { FbsService } from "src/app/core/services/schemes/fbs.service";
import { FineService } from "src/app/core/services/schemes/fine.service";
import { MembershipsService } from "src/app/core/services/schemes/memberships.service";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { Zone } from "src/app/core/models/masters/zone";
import { ZonesService } from "src/app/core/services/masters/zones.service";

@Component({
  selector: "app-receipt-history",
  templateUrl: "./receipt-history.component.html",
  styleUrls: ["./receipt-history.component.css"],
})
export class ReceiptHistoryComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  FromDate: NgbDateStruct;
  ToDate: NgbDateStruct;
  receiptList: ReceiptHistory[];
  listCount: any;
  userDetails: UserDetails;
  menuId: number;
  public receiptHistoryForm: FormGroup;

  Referrer: number;
  ReferrerName: string;
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  fbss$: Observable<Fbs[]>;
  memberships$: Observable<Membership[]>;
  fines$: Observable<Fine[]>;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  Type = [
    { Type: "A", TypeName: "All" },
    { Type: "S", TypeName: "Fbs" },
    { Type: "M", TypeName: "Membership" },
    { Type: "F", TypeName: "Fine" },
  ];

  Status = [
    { Status: "", StatusName: "All" },
    { Status: "O", StatusName: "Open" },
    { Status: "B", StatusName: "Branch Approved" },
    { Status: "Z", StatusName: "Zone Approved" },
    { Status: "A", StatusName: "Central Approved" },
    { Status: "C", StatusName: "Closed" },
    { Status: "L", StatusName: "Cancelled" },
  ];

  MemberType = [
    { MemberType: "A", MemberTypeName: "All" },
    { MemberType: "N", MemberTypeName: "New Member" },
    { MemberType: "R", MemberTypeName: "Renewed Member" },
  ];

  constructor(
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private memberService: MembersService,
    private fbsService: FbsService,
    private membershipService: MembershipsService,
    private fineService: FineService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.FromDate = this.calendar.getToday();
    this.ToDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 82300;

    this.listCount = 0;
    this.loadStarClubMembers();

    this.zones$ = this.zoneService.getZones();
    this.fbss$ = this.fbsService.getFbses();
    this.memberships$ = this.membershipService.getMemberships();
    this.fines$ = this.fineService.getFines();

    this.receiptHistoryForm = new FormGroup({
      FromDate: new FormControl(new Date()),
      ToDate: new FormControl(new Date()),
      ApprovalFromDate: new FormControl(new Date()),
      ApprovalToDate: new FormControl(new Date()),
      Status: new FormControl(""),
      Zone: new FormControl(0),
      ZoneName: new FormControl(""),
      Branch: new FormControl(0),
      BranchName: new FormControl(""),
      Unit: new FormControl(0),
      UnitName: new FormControl(""),
      Referrer: new FormControl({ Id: 0, Name: "" }),
      ReferrerName: new FormControl(""),
      Type: new FormControl("A"),
      TypeId: new FormControl(0),
      TypeIdName: new FormControl(""),
      MemberType: new FormControl("A"),
    });
  }

  resetFilters() {
    this.receiptHistoryForm.reset({
      FromDate: new Date(),
      ToDate: new Date(),
      ApprovalFromDate: new Date(),
      ApprovalToDate: new Date(),
      Status: "",
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      Unit: 0,
      UnitName: "",
      Referrer: { Id: 0, Name: "" },
      ReferrerName: "",
      Type: "A",
      TypeId: 0,
      TypeIdName: "",
      MemberType: "A",
    });
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }

  getReceiptsHistory() {
    if (this.receiptHistoryForm.value.Referrer === null) {
      this.receiptHistoryForm.value.Referrer = { Id: 0, Name: "" };
    } else {
      if (this.receiptHistoryForm.value.Referrer === 0) {
        this.receiptHistoryForm.value.Referrer = { Id: 0, Name: "" };
      }
    }
    const searchFields: ReceiptsHistorySearch = {
      FromDate: this.receiptHistoryForm.value.FromDate,
      ToDate: this.receiptHistoryForm.value.ToDate,
      ApprovalFromDate: this.receiptHistoryForm.value.ApprovalFromDate,
      ApprovalToDate: this.receiptHistoryForm.value.ApprovalToDate,
      Status: this.receiptHistoryForm.value.Status,
      Zone: this.receiptHistoryForm.value.Zone,
      Branch: this.receiptHistoryForm.value.Branch,
      Unit: this.receiptHistoryForm.value.Unit,
      Referrer: this.receiptHistoryForm.value.Referrer.Id,
      Type: this.receiptHistoryForm.value.Type,
      TypeId: this.receiptHistoryForm.value.TypeId,
      MemberType: this.receiptHistoryForm.value.MemberType,
    };
    this.spinnerService.show();
    this.reportGeneratorService.getReceiptsHistory(searchFields).subscribe(
      (response: ReceiptHistory[]) => {
        this.receiptList = response;
        this.listCount = this.receiptList.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  onTypeChange() {
    this.receiptHistoryForm.patchValue({
      TypeId: 0,
    });
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Receipts History");

    XLSX.writeFile(wb, "Receipts History.xlsx");
  }
}
