import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { StarClubMember } from "../../core/models/members/star-club-member";
import { MembersService } from "../../core/services/members/members.service";
import { Member } from "../../core/models/members/member";
import { MemberList } from "src/app/core/models/members/member-List";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-star-club-member",
  templateUrl: "./star-club-member.component.html",
  styleUrls: ["./star-club-member.component.css"],
})
export class StarClubMemberComponent implements OnInit {
  subMembers$: Observable<Member[]>;
  Id: number;
  Member: number;
  MemberName: string;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  userDetails: UserDetails;
  menuId: number;

  starClubMemberForm = new FormGroup({
    Member: new FormControl({ Id: 0, Name: "" }),
    MemberName: new FormControl(""),
    IsStarClubMember: new FormControl(true),
  });

  constructor(
    private memberService: MembersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.Id = 0;

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 22000;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getMember(id, false);
      }
    });
  }

  cancelMember() {
    this.router.navigate(
      ["./", { outlets: { pages: ["star-club-members"] } }],
      { relativeTo: this.route.parent }
    );
  }

  getMember(Id: number, chkValue: boolean) {
    this.memberService.getMember(Id).subscribe(
      (starClubMember: Member) => this.editMember(starClubMember, chkValue),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(
          ["./", { outlets: { pages: ["star-club-members"] } }],
          { relativeTo: this.route.parent }
        );
      }
    );
  }

  editMember(member: Member, chkValue: boolean) {
    this.starClubMemberForm.patchValue({
      Member: { Id: member.Id, Name: member.FullName },
      MemberName: member.FullName,
      IsStarClubMember: chkValue ? chkValue : member.IsStarClubMember,
    });
    this.Id = member.Id;
    this.Member = member.Id;
    this.MemberName = member.FullName;
    this.subMembers$ = this.memberService.getSubMembers(this.Id);
  }

  saveMember(reset: boolean) {
    const starClubMember: StarClubMember = {
      Member: this.starClubMemberForm.value.Member.Id,
      MemberName: this.starClubMemberForm.value.Member.MemberName,
      IsStarClubMember: this.starClubMemberForm.value.IsStarClubMember,
      CreatedBy: "",
    };

    if (starClubMember.Member > 0) {
      this.memberService.modifyStarClub(starClubMember).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMember();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["star-club-members"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetMember() {
    this.starClubMemberForm.reset({
      Member: { Id: 0, Name: "" },
      MemberName: "",
      IsStarClubMember: true,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getSubMembers() {
    if (this.starClubMemberForm.value.Member != null) {
      this.getMember(
        this.starClubMemberForm.value.Member.Id,
        this.starClubMemberForm.value.IsStarClubMember
      );
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.Id < 1) privilage = true;
        else if (value.PrivilegeModify && this.Id > 0) privilage = true;
      }
    });
    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
