import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ReportGeneratorService } from "../../services/reports/report-generator.services";

@Component({
  selector: "app-dashbaord",
  templateUrl: "./dashbaord.component.html",
  styleUrls: ["./dashbaord.component.css"],
})
export class DashbaordComponent implements OnInit {
  dashboard: any = {
    TotalMembers: 0,
    ActiveMembers: 0,
    InactiveMembers: 0,
    OtherMembers: 0,
    Memberships: [],
    Fbss: [],
    OpenMembers: [],
  };
  constructor(
    private reportGeneratorService: ReportGeneratorService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.reportGeneratorService.getDashboard(0).subscribe(
      (response: any) => {
        this.dashboard = response;
      },
      (err: any) => console.log(err)
    );
  }
  refresh(index: number) {
    this.reportGeneratorService.getDashboard(index).subscribe(
      (response: any) => {
        this.dashboard = response;
      },
      (err: any) => console.log(err)
    );
  }

  loadExpiredMember(id: number) {
    this.router.navigate(
      ["./", { outlets: { pages: ["expired-member-detail", id] } }],
      {
        relativeTo: this.route.parent,
      }
    );
  }
}
