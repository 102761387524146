import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import { Vendor } from "../../core/models/system/vendor";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { VendorsService } from "../../core/services/system/vendors.service";
import { Observable } from "rxjs";
import { UserDetails } from "src/app/core/models/system/user-details";
import { District } from "src/app/core/models/masters/district";
import { PostOffice } from "src/app/core/models/masters/post-office";
import { DistrictsService } from "src/app/core/services/masters/districts.service";
import { PostOfficesService } from "src/app/core/services/masters/post-offices.service";

@Component({
  selector: "app-vendor",
  templateUrl: "./vendor.component.html",
  styleUrls: ["./vendor.component.css"],
})
export class VendorComponent implements OnInit {
  public vendorForm: FormGroup;
  public menuList: FormArray;
  districts$: Observable<District[]>;
  postOffices$: Observable<PostOffice[]>;

  userDetails: UserDetails;
  menuId: number;
  constructor(
    private vendorService: VendorsService,
    private districtService: DistrictsService,
    private postOfficeService: PostOfficesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 93000;

    this.districts$ = this.districtService.getDistricts();
    this.vendorForm = new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl(""),
      UserId: new FormControl(""),
      Password: new FormControl(""),
      Address: new FormControl(""),
      District: new FormControl(0),
      DistrictName: new FormControl(""),
      PostOffice: new FormControl(0),
      PostOfficeName: new FormControl(""),
      Phone: new FormControl(""),
      Mobile: new FormControl(""),
      Email: new FormControl(""),
      Active: new FormControl(true),
    });

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getVendor(expID);
      } else {
        this.getVendor(0);
      }
    });
  }

  cancelVendor() {
    this.router.navigate(["./", { outlets: { pages: ["vendors"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getVendor(Id: number) {
    this.vendorService.getVendor(Id).subscribe(
      (vendor: Vendor) => this.editVendor(vendor),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["vendors"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editVendor(vendor: Vendor) {
    this.vendorForm.patchValue({
      Id: vendor.Id,
      Name: vendor.Name,
      UserId: vendor.UserId,
      Password: vendor.Password,
      Address: vendor.Address,
      District: vendor.District,
      DistrictName: vendor.DistrictName,
      PostOffice: vendor.PostOffice,
      PostOfficeName: vendor.PostOfficeName,
      Phone: vendor.Phone,
      Mobile: vendor.Mobile,
      Active: vendor.Active,
      VendorMenus: [],
    });
  }

  saveVendor(reset: boolean) {
    const vendor: Vendor = {
      Id: this.vendorForm.value.Id,
      Name: this.vendorForm.value.Name,
      UserId: this.vendorForm.value.UserId,
      Password: this.vendorForm.value.Password,
      Address: this.vendorForm.value.Address,
      District: this.vendorForm.value.District,
      DistrictName: this.vendorForm.value.DistrictName,
      PostOffice: this.vendorForm.value.PostOffice,
      PostOfficeName: this.vendorForm.value.PostOfficeName,
      Phone: this.vendorForm.value.Phone,
      Mobile: this.vendorForm.value.Mobile,
      Email: this.vendorForm.value.Email,
      Active: this.vendorForm.value.Active,
      CreatedBy: "",
      EditedBy: "",
    };
    if (vendor.Id < 1) {
      console.log(vendor);
      this.vendorService.addVendor(vendor).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetVendor();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["vendors"] } }],
                {
                  relativeTo: this.route.parent,
                }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.vendorService.modifyVendor(vendor).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetVendor();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["vendors"] } }],
                {
                  relativeTo: this.route.parent,
                }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getPostOffices(district: number) {
    console.log(district);
    this.postOffices$ = this.postOfficeService.getDistrictPostoffices(district);
  }
  resetVendor() {
    this.getVendor(0);
  }

  trackMenu(index, item) {
    return item;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.vendorForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.vendorForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
