import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { RevicedInvoice } from "../../models/receipts/reviced-invoice";
import { InvoiceList } from "../../models/receipts/InvoiceList";

@Injectable({
  providedIn: "root",
})
export class RevicedInvoiceService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getInvoice(id: number): Observable<RevicedInvoice> {
    return this.http.get<RevicedInvoice>(
      environment.root + "/RevicedInvoice/" + id
    );
  }

  getExpiredInvoiceList(term: string): Observable<InvoiceList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<InvoiceList[]>(
      environment.root + "/RevicedInvoice/GetExpiredInvoiceList/",
      { params }
    );
  }

  getReceiptsBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      Invoices: RevicedInvoice[];
    }>(environment.root + "/RevicedInvoice/GetAllRevicedInvoiceBySearch", {
      params,
    });
  }

  modifyRevice(invoice: RevicedInvoice) {
    return this.http
      .post(
        environment.root + "/RevicedInvoice/Modify",
        invoice,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  deleteRevicedInvoice(id: number) {
    return this.http
      .post(environment.root + "/RevicedInvoice/Delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
