import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { Receipt } from "../../core/models/receipts/receipt";
import { ReceiptInvoice } from "../../core/models/receipts/receipt";
import { ReceiptScheme } from "../../core/models/receipts/receipt";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";
import { ReceiptsService } from "../../core/services/receipts/receipts.service";
import { Observable, of, concat, Subject } from "rxjs";
import { Scheme } from "../../core/models/masters/scheme";
import { SchemesService } from "../../core//services/masters/schemes.service";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import { Member } from "../../core/models/members/member";
import { MembersService } from "../../core//services/members/members.service";
import {
  tap,
  catchError,
  switchMap,
  distinctUntilChanged,
  debounceTime,
} from "rxjs/operators";
import { MemberList } from "src/app/core/models/members/member-List";
import { StatusUpdate } from "src/app/core/services/members/status-update";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Country } from "src/app/core/models/masters/country";
import { District } from "src/app/core/models/masters/district";
import { Panchayath } from "src/app/core/models/masters/panchayath";
import { State } from "src/app/core/models/masters/state";
import { CountriesService } from "src/app/core/services/masters/countries.service";
import { DistrictsService } from "src/app/core/services/masters/districts.service";
import { PanchayathsService } from "src/app/core/services/masters/panchayaths.service";
import { StatesService } from "src/app/core/services/masters/states.service";
import { OutstandingInvoices } from "src/app/core/models/receipts/outstanding-invoices";

@Component({
  selector: "app-receipt-membership",
  templateUrl: "./receipt-membership.component.html",
  styleUrls: ["./receipt-membership.component.css"],
})
export class ReceiptMembershipComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;
  ReceiptDate: NgbDateStruct;

  public receiptForm: FormGroup;
  public receiptList: FormArray;
  public schemeList: FormArray;
  approvedStatus: boolean;
  closedStatus: boolean;
  cancelledStatus: boolean;

  schemes$: Observable<Scheme[]>;
  members$: Observable<Member[]>;
  countries$: Observable<Country[]>;
  states$: Observable<State[]>;
  districts$: Observable<District[]>;
  panchayaths$: Observable<Panchayath[]>;
  outstandingInvoices: OutstandingInvoices[];

  private mode = "New";

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;
  ZeroValue = 0;

  closeResult: string;
  receiptNumber: string;
  receiptDate: Date;
  member: string;
  referenceNumber: string;
  amount: number;
  description: string;
  KkmaId: string;
  ZoneName: string;
  BranchName: string;
  UnitName: string;
  StarClubMember: string;
  ReceivedName: string;
  ReceivedKkmaId: string;
  ReceivedBranchName: string;
  hasFine: boolean;

  EmergencyContact1Type = [
    { EmergencyContact1Type: "R", EmergencyContact1TypeName: "Relative" },
    { EmergencyContact1Type: "F", EmergencyContact1TypeName: "Friend" },
  ];
  EmergencyContact2Type = [
    { EmergencyContact2Type: "R", EmergencyContact2TypeName: "Relative" },
    { EmergencyContact2Type: "F", EmergencyContact2TypeName: "Friend" },
  ];
  constructor(
    private receiptService: ReceiptsService,
    private schemeService: SchemesService,
    private memberService: MembersService,
    private countryService: CountriesService,
    private stateService: StatesService,
    private districtService: DistrictsService,
    private panchayathService: PanchayathsService,
    private modalService: NgbModal,
    private ngbDateAdapter: NgbStringAdapterService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.hasFine = false;
    this.ReceiptDate = this.calendar.getToday();
    this.countries$ = this.countryService.getCountries();

    this.receiptForm = new FormGroup({
      Id: new FormControl(0),
      Type: new FormControl("M"),
      ReceiptNumber: new FormControl(""),
      ReceiptDate: new FormControl(new Date()),
      ReferenceNumber: new FormControl(""),
      Member: new FormControl({ Id: 0, Name: "" }),
      MemberName: new FormControl(""),
      Amount: new FormControl(this.ZeroValue.toFixed(3)),
      Description: new FormControl(""),
      Status: new FormControl(""),
      CivilID: new FormControl(""),
      ExeMobile: new FormControl(""),
      Mobile: new FormControl(""),
      ConfirmMobile: new FormControl(""),
      WhatsApp: new FormControl(""),
      ConfirmWhatsApp: new FormControl(""),
      HAPanchayath: new FormControl(0),
      HAPanchayathName: new FormControl(""),
      HADistrict: new FormControl(0),
      HADistrictName: new FormControl(""),
      HAState: new FormControl(0),
      HAStateName: new FormControl(""),
      HACountry: new FormControl(0),
      HACountryName: new FormControl(""),
      ExeHAMobile: new FormControl(""),
      HAMobile: new FormControl(""),
      ConfirmHAMobile: new FormControl(""),
      EmergencyContact1: new FormControl(""),
      EmergencyContact1Type: new FormControl("R"),
      EmergencyContact1TypeName: new FormControl(""),
      EmergencyContact1Phone: new FormControl(""),
      EmergencyContact2: new FormControl(""),
      EmergencyContact2Type: new FormControl("R"),
      EmergencyContact2TypeName: new FormControl(""),
      EmergencyContact2Phone: new FormControl(""),
      IsBranchApprover: new FormControl(false),
      IsZoneApprover: new FormControl(false),
      IsCentralApprover: new FormControl(false),
      NextApproval: new FormControl(""),
      StatusName: new FormControl("Open"),
      Invoices: this.formBuilder.array([]),
      schemes: this.formBuilder.array([]),
    });
    this.receiptList = this.receiptForm.get("Invoices") as FormArray;
    this.schemeList = this.receiptForm.get("schemes") as FormArray;

    this.schemes$ = this.schemeService.getSchemes();
    this.members$ = this.memberService.getMembers();

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 61000;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getReceipt(id);
      }
    });
  }

  newReceipt() {
    this.resetReceipt();
  }

  cancelReceipt() {
    this.router.navigate(
      ["./", { outlets: { pages: ["receipts-membership"] } }],
      { relativeTo: this.route.parent }
    );
  }

  getReceipt(Id: number) {
    this.receiptService.getReceipt(Id).subscribe(
      (receipt: Receipt) => this.editReceipt(receipt),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["receipts"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editReceipt(receipt: Receipt) {
    this.mode = "Edit";
    this.receiptForm.patchValue({
      Id: receipt.Id,
      Type: receipt.Type,
      ReceiptNumber: receipt.ReceiptNumber,
      ReceiptDate: receipt.ReceiptDate,
      ReferenceNumber: receipt.ReferenceNumber,
      Member: { Id: receipt.Member, Name: receipt.MemberName },
      MemberName: receipt.MemberName,
      Amount: receipt.Amount.toFixed(3),
      Description: receipt.Description,
      Status: receipt.Status,
      StatusName:
        receipt.Status === "O"
          ? "Open"
          : receipt.Status === "B"
          ? "Branch Approved"
          : receipt.Status === "Z"
          ? "Zone Approved"
          : receipt.Status === "A"
          ? "Approved"
          : receipt.Status === "C"
          ? "Closed"
          : receipt.Status === "L"
          ? "Cancelled"
          : "Open",
      CivilID: receipt.CivilID,
      Mobile: receipt.Mobile,
      ConfirmMobile: receipt.ConfirmMobile,
      ExeMobile: receipt.ConfirmMobile,
      WhatsApp: receipt.WhatsApp,
      ConfirmWhatsApp: receipt.WhatsApp,
      HACountry: receipt.HACountry,
      HACountryName: receipt.HACountryName,
      HAState: receipt.HAState,
      HAStateName: receipt.HAStateName,
      HADistrict: receipt.HADistrict,
      HADistrictName: receipt.HADistrictName,
      HAPanchayath: receipt.HAPanchayath,
      HAPanchayathName: receipt.HAPanchayathName,
      HAMobile: receipt.HAMobile,
      ConfirmHAMobile: receipt.ConfirmHAMobile,
      EmergencyContact1: receipt.EmergencyContact1,
      EmergencyContact1Type: receipt.EmergencyContact1Type,
      EmergencyContact1Phone: receipt.EmergencyContact1Phone,
      EmergencyContact2: receipt.EmergencyContact2,
      EmergencyContact2Type: receipt.EmergencyContact2Type,
      EmergencyContact2Phone: receipt.EmergencyContact2Phone,
      IsBranchApprover: receipt.IsBranchApprover,
      IsZoneApprover: receipt.IsZoneApprover,
      IsCentralApprover: receipt.IsCentralApprover,
      NextApproval: receipt.NextApproval,
      Invoices: [],
      schemes: [],
    });
    this.receiptNumber = receipt.ReceiptNumber;
    this.receiptDate = receipt.ReceiptDate;
    this.member = receipt.MemberName;
    this.referenceNumber = receipt.ReferenceNumber;
    this.amount = receipt.Amount;
    this.description = receipt.Description;
    this.KkmaId = receipt.KkmaId;
    this.ZoneName = receipt.ZoneName;
    this.BranchName = receipt.BranchName;
    this.UnitName = receipt.UnitName;
    this.StarClubMember = receipt.StarClubMember;
    this.ReceivedName = receipt.ReceivedName;
    this.ReceivedKkmaId = receipt.ReceivedKkmaId;
    this.ReceivedBranchName = receipt.ReceivedBranchName;
    this.createFormInvoice(receipt.Invoices);
    this.createFormScheme(receipt);
    this.ReceiptDate = this.ngbDateAdapter.fromModel(
      new Date(receipt.ReceiptDate)
    );
    this.statusMenuVisible(receipt.Id, receipt.Status);
    this.calculateTotal();
  }

  saveReceipt(reset: boolean, edit: boolean) {
    const receipt: Receipt = {
      Id: this.receiptForm.value.Id,
      Type: this.receiptForm.value.Type,
      ReceiptNumber: this.receiptForm.value.ReceiptNumber,
      ReceiptDate: this.receiptForm.value.ReceiptDate,
      ReferenceNumber: this.receiptForm.value.ReferenceNumber,
      Member: this.receiptForm.value.Member.Id,
      MemberName: this.receiptForm.value.Member.Name,
      Amount: this.receiptForm.value.Amount,
      Description: this.receiptForm.value.Description,
      Status: this.receiptForm.value.Status,
      CivilID: this.receiptForm.value.CivilID,
      Mobile: this.receiptForm.value.ConfirmMobile,
      ConfirmMobile: this.receiptForm.value.ConfirmMobile,
      WhatsApp: this.receiptForm.value.ConfirmWhatsApp,
      ConfirmWhatsApp: this.receiptForm.value.ConfirmWhatsApp,
      HAPanchayath: this.receiptForm.value.HAPanchayath,
      HAPanchayathName: this.receiptForm.value.HAPanchayathName,
      HADistrict: this.receiptForm.value.HADistrict,
      HADistrictName: this.receiptForm.value.HADistrictName,
      HAState: this.receiptForm.value.HAState,
      HAStateName: this.receiptForm.value.HAStateName,
      HACountry: this.receiptForm.value.HACountry,
      HACountryName: this.receiptForm.value.HACountryName,
      HAMobile: this.receiptForm.value.ConfirmHAMobile,
      ConfirmHAMobile: this.receiptForm.value.ConfirmHAMobile,
      EmergencyContact1: this.receiptForm.value.EmergencyContact1,
      EmergencyContact1Type: this.receiptForm.value.EmergencyContact1Type,
      EmergencyContact1Phone: this.receiptForm.value.EmergencyContact1Phone,
      EmergencyContact2: this.receiptForm.value.EmergencyContact2,
      EmergencyContact2Type: this.receiptForm.value.EmergencyContact2Type,
      EmergencyContact2Phone: this.receiptForm.value.EmergencyContact2Phone,
      IsBranchApprover: this.receiptForm.value.IsBranchApprover,
      IsZoneApprover: this.receiptForm.value.IsZoneApprover,
      IsCentralApprover: this.receiptForm.value.IsCentralApprover,
      NextApproval: this.receiptForm.value.NextApproval,
      CreatedBy: "",
      CreatedOn: new Date(),
      EditedBy: "",
      EditedOn: new Date(),
      Invoices: this.receiptForm.value.Invoices,
      Schemes: this.receiptForm.value.schemes,
      KkmaId: "",
      ZoneName: "",
      BranchName: "",
      UnitName: "",
      StarClubMember: "",
      ReceivedName: "",
      ReceivedKkmaId: "",
      ReceivedBranchName: "",
    };
    if (receipt.Id < 1) {
      this.receiptService.addReceipt(receipt).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (edit) {
              this.resetReceipt();
              this.getReceipt(response.id);
            } else if (reset) {
              this.resetReceipt();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["receipts-membership"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.receiptService.modifyReceipt(receipt).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (edit) {
              this.resetReceipt();
              this.getReceipt(response.id);
            } else if (reset) {
              this.resetReceipt();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["receipts-membership"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  updateStatus(stat) {
    if (this.receiptForm.value.Id < 1) {
      this.displayFeedback(
        "error",
        "Error!",
        "Please select a receipt to update status!"
      );
    } else {
      if (stat === "A") {
        stat = this.receiptForm.value.NextApproval;
      }

      const transfer: StatusUpdate = {
        Id: this.receiptForm.value.Id,
        Status: stat,
        EditedBy: "",
      };

      this.receiptService.modifyStatus(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Status Updated Successfully!", "");
            this.receiptForm.patchValue({
              Status: stat,
              StatusName:
                stat === "O"
                  ? "Open"
                  : stat === "B"
                  ? "Branch Approved"
                  : stat === "Z"
                  ? "Zone Approved"
                  : stat === "A"
                  ? "Approved"
                  : stat === "C"
                  ? "Closed"
                  : stat === "L"
                  ? "Cancelled"
                  : "Open",
            });
            this.statusMenuVisible(transfer.Id, stat);
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetReceipt() {
    this.receiptForm.reset({
      Id: 0,
      Type: "M",
      ReceiptNumber: "",
      ReceiptDate: new Date(),
      ReferenceNumber: "",
      Member: "",
      MemberName: "",
      Amount: this.ZeroValue.toFixed(3),
      Description: "",
      Status: "",
      StatusName: "Open",
      CivilID: "",
      Mobile: "",
      ConfirmMobile: "",
      WhatsApp: "",
      ConfirmWhatsApp: "",
      HAPanchayath: 0,
      HAPanchayathName: "",
      HADistrict: 0,
      HADistrictName: "",
      HAState: 0,
      HAStateName: "",
      HACountry: 0,
      HACountryName: "",
      HAMobile: "",
      ConfirmHAMobile: "",
      EmergencyContact1: "",
      EmergencyContact1Type: "R",
      EmergencyContact1Phone: "",
      EmergencyContact2: "",
      EmergencyContact2Type: "R",
      EmergencyContact2Phone: "",
      IsBranchApprover: false,
      IsZoneApprover: false,
      IsCentralApprover: false,
      NextApproval: "",
    });
    this.removeInvoicesOnReset();
    this.removeSchemesOnReset();
    this.mode = "New";
  }

  get invoiceFormGroup() {
    return this.receiptForm.get("Invoices") as FormArray;
  }

  createFormInvoice(receipt: ReceiptInvoice[]) {
    for (const invoice of receipt) {
      if (invoice.HasFine) {
        this.hasFine = true;
      }
      this.receiptList.push(
        this.createInvoiceWithValues(
          invoice.RIId,
          invoice.RIInvoice,
          invoice.RIInvDate,
          invoice.RIDescription,
          invoice.RIInvAmount,
          invoice.RIBalAmount,
          invoice.RIAmount
        )
      );
    }
    if (this.hasFine) {
      this.displayFeedback("warning", "Pending Fine Exists!", "");
    }
    this.hasAdd();
    this.calculateTotal();
  }

  createInvoiceWithValues(
    RIId: number,
    RIInvoice: number,
    RIInvDate: string,
    RIDescription: string,
    RIInvAmount: number,
    RIBalAmount: number,
    RIAmount: number
  ): FormGroup {
    return this.formBuilder.group({
      RIId: new FormControl(RIId),
      RIInvoice: new FormControl(RIInvoice),
      RIInvDate: new FormControl(RIInvDate),
      RIDescription: new FormControl(RIDescription),
      RIInvAmount: new FormControl(RIInvAmount.toFixed(3)),
      RIBalAmount: new FormControl(RIBalAmount.toFixed(3)),
      RIAmount: new FormControl(RIAmount.toFixed(3)),
    });
  }

  createInvoice(): FormGroup {
    return this.formBuilder.group({
      RIId: new FormControl(0),
      RIInvoice: new FormControl(0),
      RIScheme: new FormControl(0),
      RISchemeName: new FormControl(""),
      RIDescription: new FormControl(""),
      RIInvAmount: new FormControl(0),
      RIBalAmount: new FormControl(0),
      RIAmount: new FormControl(0),
      RIInvType: new FormControl(""),
    });
  }

  addInvoice() {
    this.receiptList.push(this.createInvoice());
  }

  removeInvoicesOnReset() {
    for (let i = this.receiptForm.value.Invoices.length - 1; i >= 0; i--) {
      this.receiptList.removeAt(i);
      this.calculateTotal();
    }
  }

  removeInvoice(index) {
    this.receiptList.removeAt(index);
    this.calculateTotal();
  }

  checkBalanceAmount() {
    let receiptList: any[];
    receiptList = this.receiptForm.value.Invoices;
    let condition = false;
    let valid = false;
    let totalAmount = 0;

    for (const invoice of receiptList) {
      if (
        invoice.RIAmount === null ||
        invoice.RIAmount === "" ||
        invoice.RIAmount === "."
      ) {
        invoice.RIAmount = 0;
      }
      if (
        invoice.RIBalAmount === null ||
        invoice.RIBalAmount === "" ||
        invoice.RIBalAmount === "."
      ) {
        invoice.RIBalAmount = 0;
      }

      if (parseFloat(invoice.RIAmount) > parseFloat(invoice.RIBalAmount)) {
        condition = true;
      }
      totalAmount += parseFloat(invoice.RIAmount);

      if (parseFloat(invoice.RIAmount) > 0) {
        if (totalAmount != parseFloat(invoice.RIAmount)) {
          valid = true;
        }
      }
    }
    if (valid) {
      this.displayHTMLFeedback(
        "warning",
        "You can select only one invoice at a time" + "!",
        ""
      );
    }
    if (condition) {
      this.displayHTMLFeedback(
        "warning",
        "Amount cannot be greater than balance amount in invoices" + "!",
        ""
      );
    } else {
      this.calculateTotal();
      this.setReferenceNumber();
    }
  }
  calculateTotal() {
    let receiptList: any[];
    let schemeList: any[];
    let totalAmount: number;
    totalAmount = 0;
    receiptList = this.receiptForm.value.Invoices;
    for (const invoice of receiptList) {
      if (
        invoice.RIAmount === null ||
        invoice.RIAmount === "" ||
        invoice.RIAmount === "."
      ) {
        invoice.RIAmount = 0;
      }
      totalAmount += parseFloat(invoice.RIAmount);
    }
    schemeList = this.receiptForm.value.schemes;
    for (const scheme of schemeList) {
      if (
        scheme.RSAmount === null ||
        scheme.RSAmount === "" ||
        scheme.RSAmount === "."
      ) {
        scheme.RSAmount = 0;
      }
      totalAmount += parseFloat(scheme.RSAmount);
    }
    this.receiptForm.patchValue({
      Amount: totalAmount.toFixed(3),
    });
  }

  setReferenceNumber() {
    let receiptList: any[];
    let referenceNumber: string;
    referenceNumber = "";
    receiptList = this.receiptForm.value.Invoices;
    for (const invoice of receiptList) {
      if (parseFloat(invoice.RIAmount) > 0) {
        if (referenceNumber === "") {
          referenceNumber = invoice.RIDescription;
        } else {
          referenceNumber = referenceNumber + ", " + invoice.RIDescription;
        }
      }
    }
    this.receiptForm.patchValue({
      ReferenceNumber: referenceNumber,
    });
  }

  getInvoices() {
    let member: number;
    member = this.receiptForm.value.Member.Id;
    this.removeInvoicesOnReset();
    this.removeSchemesOnReset();
    if (this.mode === "New") {
      this.receiptService
        .checkExpiredInvoiceExistsOrNot(member.toString())
        .subscribe(
          (response) => {
            if (response === true || response === "true") {
              alert(
                "Expired schemes exists. System will automatically cancel the expired invoices and generate fine."
              );

              this.generateInvoice(member, true);
            } else {
              this.generateInvoice(member, false);
            }
          },
          (err: any) => console.log(err)
        );
    }
    this.memberService
      .getMember(member)
      .subscribe((starClubMember: Member) => this.setMember(starClubMember));

    this.mode = "New";
  }

  setMember(member: Member) {
    console.log(member);
    this.receiptForm.patchValue({
      CivilID: member.CivilID,
      ExeMobile: member.Mobile,
      Mobile: "",
      ConfirmMobile: "",
      WhatsApp: member.WhatsApp,
      ConfirmWhatsApp: "",
      EmergencyContact1: member.EmergencyContact1,
      EmergencyContact1Type: member.EmergencyContact1Type,
      EmergencyContact1Phone: member.EmergencyContact1Phone,
      EmergencyContact2: member.EmergencyContact2,
      EmergencyContact2Type: member.EmergencyContact2Type,
      EmergencyContact2Phone: member.EmergencyContact2Phone,
      HAHouseName: member.HAHouseName,
      HAPlace: member.HAPlace,
      HACountry: member.HACountry,
      HACountryName: member.HACountryName,
      HAState: member.HAState,
      HAStateName: member.HAStateName,
      HADistrict: member.HADistrict,
      HADistrictName: member.HADistrictName,
      HAPostOffice: member.HAPostOffice,
      HAPostOfficeName: member.HAPostOfficeName,
      HAPinCode: member.HAPinCode,
      HAPanchayath: member.HAPanchayath,
      HAPanchayathName: member.HAPanchayathName,
      ExeHAMobile: member.HAMobile,
      HAMobile: "",
      ConfirmHAMobile: "",
    });
    console.log(this.receiptForm.value);
  }

  generateInvoice(member: number, genValue: boolean) {
    this.receiptService
      .generateInvoice(member.toString(), genValue, this.receiptForm.value.Type)
      .subscribe(
        (response) => {
          this.createFormInvoice(response.Invoices);
          this.outstandingInvoices = response.OutstandingInvoices;
          if (this.outstandingInvoices.length > 0) {
            this.displayFeedback("warning", "Pending FBS Exists!", "");
          }
          this.hasAdd();
          this.setReferenceNumber();
        },
        (err: any) => console.log(err)
      );
  }

  get schemeFormGroup() {
    return this.receiptForm.get("schemes") as FormArray;
  }

  createFormScheme(schemes: Receipt) {
    // for (let i = 0; i < schemes.Schemes.length; i++) {
    //   this.schemeList.push(this.createSchemeWithValues(schemes.Schemes[i].MSId,
    //     schemes.Schemes[i].MSScheme, schemes.Schemes[i].MSSchemeName,
    //     schemes.Schemes[i].MSAmount, schemes.Schemes[i].MSIsOneTime));
    // }
    for (const scheme of schemes.Schemes) {
      this.schemeList.push(
        this.createSchemeWithValues(
          scheme.RSId,
          scheme.RSScheme,
          scheme.RSSchemeName,
          scheme.RSDescription,
          scheme.RSAmount
        )
      );
    }
  }

  createSchemeWithValues(
    RSId: number,
    RSScheme: number,
    RSSchemeName: string,
    RSDescription: string,
    RSAmount: number
  ): FormGroup {
    return this.formBuilder.group({
      RSId: new FormControl(RSId),
      RSScheme: new FormControl(RSScheme),
      RSSchemeName: new FormControl(RSSchemeName),
      RSDescription: new FormControl(RSDescription),
      RSAmount: new FormControl(RSAmount.toFixed(3)),
    });
  }

  createScheme(): FormGroup {
    return this.formBuilder.group({
      RSId: new FormControl(0),
      RSScheme: new FormControl(0),
      RSSchemeName: new FormControl(""),
      RSDescription: new FormControl(""),
      RSAmount: new FormControl(this.ZeroValue.toFixed(3)),
    });
  }

  addScheme() {
    this.schemeList.push(this.createScheme());
  }

  removeSchemesOnReset() {
    for (let i = this.receiptForm.value.schemes.length - 1; i >= 0; i--) {
      this.removeScheme(i);
    }
  }

  removeScheme(index) {
    this.schemeList.removeAt(index);
    this.calculateTotal();
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getStates(country: number) {
    this.states$ = this.stateService.getCountryStates(country);
    this.districts$ = this.districtService.getStateDistricts(0);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
  }

  getDistricts(state: number) {
    this.districts$ = this.districtService.getStateDistricts(state);
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(0);
  }

  getPanchayathsAndPO(district: number) {
    this.panchayaths$ = this.panchayathService.getDistrictPanchayaths(district);
  }

  statusMenuVisible(id, status) {
    let NextApproval = this.receiptForm.value.NextApproval;
    let IsBranchApprover = this.receiptForm.value.IsBranchApprover;
    let IsZoneApprover = this.receiptForm.value.IsZoneApprover;
    let IsCentralApprover = this.receiptForm.value.IsCentralApprover;
    if (id < 0) {
      this.approvedStatus = false;
      this.closedStatus = false;
      this.cancelledStatus = false;
    } else {
      if (status === "O") {
        if (NextApproval === "B" && IsBranchApprover) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else if (NextApproval === "Z" && IsZoneApprover) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else if (NextApproval === "A" && IsCentralApprover) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else if (
          NextApproval === "A" &&
          (IsBranchApprover || IsZoneApprover || IsCentralApprover)
        ) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else {
          this.approvedStatus = false;
          this.closedStatus = false;
          this.cancelledStatus = false;
        }
      } else if (status === "B") {
        if (NextApproval === "Z" && IsZoneApprover) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else if (
          NextApproval === "A" &&
          (IsZoneApprover || IsCentralApprover)
        ) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else {
          this.approvedStatus = false;
          this.closedStatus = false;
          this.cancelledStatus = false;
        }
      } else if (status === "Z") {
        if (NextApproval === "A" && IsCentralApprover) {
          this.approvedStatus = true;
          this.cancelledStatus = false;
          this.closedStatus = false;
        } else {
          this.approvedStatus = false;
          this.closedStatus = false;
          this.cancelledStatus = false;
        }
      } else if (status === "A") {
        this.approvedStatus = false;
        this.closedStatus = true;
        this.cancelledStatus = false;
      } else {
        this.approvedStatus = false;
        this.closedStatus = false;
        this.cancelledStatus = false;
      }
    }
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.receiptForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.receiptForm.value.Id > 0)
          privilage = true;
      }
    });

    if (this.outstandingInvoices != undefined) {
      if (this.outstandingInvoices.length > 0) privilage = false;
    }
    if (this.hasFine) privilage = false;
    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
