import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal, { SweetAlertType } from 'sweetalert2';
import { State } from '../../core/models/masters/state';
import { District } from '../../core/models/masters/district';
import { StatesService } from '../../core/services/masters/states.service';
import { DistrictsService } from '../../core/services/masters/districts.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})
export class DistrictComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;
  states$: Observable<State[]>;

  districtForm = new FormGroup({
    Id: new FormControl(0),
    Code: new FormControl(''),
    Name: new FormControl(''),
    State: new FormControl(0),
    StateName: new FormControl(''),
    Active: new FormControl(true)
  });

  constructor(private districtService: DistrictsService, private stateService: StatesService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.states$ = this.stateService.getStates();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 16000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      if (id) {
        this.getDistrict(id);
      }
    });
  }

  cancelDistrict() {
    this.router.navigate(['./', { outlets: { pages: ['districts']} }], { relativeTo: this.route.parent });
  }

  getDistrict(Id: number) {
    this.districtService.getDistrict(Id).subscribe(
      (district: District) => this.editDistrict(district),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['districts']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editDistrict(district: District) {
    this.districtForm.patchValue({
      Id: district.Id,
      Code: district.Code,
      Name: district.Name,
      State: district.State,
      StateName: district.StateName,
      Active: district.Active
    });
  }

  saveDistrict(reset: boolean) {
    const district: District = this.districtForm.value as District;
    if (district.Id < 1) {
      this.districtService.addDistrict(district)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetDistrict();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['districts']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.districtService.modifyDistrict(district)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetDistrict();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['districts']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayHTMLFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  resetDistrict() {
    this.districtForm.reset({
      Id: 0,
      Code: '',
      Name: '',
      State: 0,
      StateName: '',
      Active: true
    });
  }

  hasAdd() {
    let privilage = false;

      this.userDetails.Menus.forEach(value => {
         if (value.Id === this.menuId) {
           if(value.PrivilegeAdd && this.districtForm.value.Id < 1)
              privilage = true;
            else if(value.PrivilegeModify && this.districtForm.value.Id > 0)
              privilage = true;
         }
      });

      return privilage;
    }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
