import { Component, OnInit, ContentChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

import { Receipt } from "../../core/models/receipts/receipt";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { ReceiptsService } from "../../core/services/receipts/receipts.service";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-receipts-fbs",
  templateUrl: "./receipts-fbs.component.html",
  styleUrls: ["./receipts-fbs.component.css"],
})
export class ReceiptsFbsComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  @ContentChild(NgbPagination, /* TODO: add static flag */ null)
  pagination: NgbPagination;

  userDetails: UserDetails;
  menuId: number;

  page = 1;
  pageSize = 25;
  collectionSize = 0;
  listItemFrom = 1;
  listItemTo = 25;
  searchBy = "Kkma_Id";
  searchText = "";

  receipts: Receipt[];

  constructor(
    private receiptService: ReceiptsService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.pageSize = 25;
    this.searchBy = "Kkma_Id";
    this.searchText = "";
    this.collectionSize = 0;
    this.listItemFrom = 1;
    this.listItemTo = 25;

    this.setReceiptList();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 62000;
  }

  loadReceipt(id: number) {
    this.router.navigate(["./", { outlets: { pages: ["receipt-fbs", id] } }], {
      relativeTo: this.route.parent,
    });
  }

  deleteReceipt(id: number) {
    if (confirm("Are you sure to delete the selected receipt?")) {
      this.receiptService.deleteReceipt(id).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Deleted Successfully!", "");
            this.setReceiptList();
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getPageFromService(pages) {
    this.page = pages;
    this.setReceiptList();
  }

  setPageCount(pageSizes) {
    this.pageSize = pageSizes;
    this.setReceiptList();
  }

  onSearchTextChange() {
    this.setReceiptList();
  }

  setReceiptList() {
    this.spinnerService.show();
    this.receiptService
      .getReceiptsBySearch(
        this.page,
        this.pageSize,
        "S",
        this.searchBy,
        this.searchText
      )
      .subscribe(
        (response) => {
          this.collectionSize = response.TotalItems;
          this.listItemFrom = response.ListItemFrom;
          this.listItemTo = response.ListItemTo;
          this.receipts = response.Receipts as Receipt[];
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd) privilage = true;
      }
    });

    return privilage;
  }

  hasView() {
    let privilage = false;
    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeView || value.PrivilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasDelete() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeDelete) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  hasAudit() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAudit) privilage = true;
      }
    });

    return privilage;
  }

  auditReceipt(id: number) {
    alert("Audit Display");
  }

  trackReceipt(index, item) {
    return item;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Receipts");

    XLSX.writeFile(wb, "Receipts.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
