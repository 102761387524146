import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { Receipt } from "../../core/models/receipts/receipt";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";
import { ReceiptsService } from "../../core/services/receipts/receipts.service";
import { Observable, of, concat, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import { MembersService } from "../../core//services/members/members.service";
import {
  tap,
  catchError,
  switchMap,
  distinctUntilChanged,
  debounceTime,
} from "rxjs/operators";
import { MemberList } from "src/app/core/models/members/member-List";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { UserDetails } from "src/app/core/models/system/user-details";
import { UpdateInvoice } from "src/app/core/models/receipts/update-invoice";

@Component({
  selector: "app-update-invoice",
  templateUrl: "./update-invoice.component.html",
  styleUrls: ["./update-invoice.component.css"],
})
export class UpdateInvoiceComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;

  public updateInvoiceForm: FormGroup;
  public updateInvoiceList: FormArray;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;
  ZeroValue = 0;
  Status = [
    { Status: "A", StatusName: "Active" },
    { Status: "E", StatusName: "Expired" },
    { Status: "L", StatusName: "Cancelled" },
  ];
  UpdateStatus = [
    { Status: "A", StatusName: "Select" },
    { Status: "M", StatusName: "Modify" },
    { Status: "D", StatusName: "Delete" },
  ];
  constructor(
    private receiptService: ReceiptsService,
    private memberService: MembersService,
    private modalService: NgbModal,
    private ngbDateAdapter: NgbStringAdapterService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.updateInvoiceForm = new FormGroup({
      Member: new FormControl({ Id: 0, Name: "" }),
      MemberName: new FormControl(""),
      Invoices: this.formBuilder.array([]),
    });
    this.updateInvoiceList = this.updateInvoiceForm.get(
      "Invoices"
    ) as FormArray;

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 76000;
  }

  save() {
    let invoiceList: any[];
    invoiceList = this.updateInvoiceForm.value.Invoices;
    let count = 0;
    let invoices: UpdateInvoice[];
    invoices = [];
    for (const invoice of invoiceList) {
      if (invoice.UpdateStatus != "A") {
        count++;
        invoices.push({
          Id: invoice.Id,
          Member: invoice.Member.Id,
          Type: invoice.Type,
          TypeName: invoice.TypeName,
          SchemeName: invoice.SchemeName,
          ReceiptNumber: invoice.ReceiptNumber,
          ReceiptDate: invoice.ReceiptDate,
          ExpiryDate: invoice.ExpiryDate,
          RevisedExpiryDate: invoice.RevisedExpiryDate,
          Status: invoice.Status,
          StatusName: invoice.Status,
          Amount: invoice.Amount,
          BalanceAmount: invoice.BalanceAmount,
          CanUpdate: invoice.CanUpdate,
          IsEdit: invoice.UpdateStatus == "M" ? true : false,
          IsDelete: invoice.UpdateStatus == "D" ? true : false,
        });
      }
    }

    if (count > 0) {
      this.receiptService.updateInvoice(invoices).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            this.resetInvoice();
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.displayFeedback("warning", "Please select altest one record!", "");
    }
  }

  resetInvoice() {
    this.updateInvoiceForm.reset({
      Member: "",
      MemberName: "",
    });
    this.removeInvoicesOnReset();
  }

  get invoiceFormGroup() {
    return this.updateInvoiceForm.get("Invoices") as FormArray;
  }

  createFormInvoice(invoices: UpdateInvoice[]) {
    for (const invoice of invoices) {
      this.updateInvoiceList.push(
        this.createInvoiceWithValues(
          invoice.Id,
          invoice.Member,
          invoice.Type,
          invoice.TypeName,
          invoice.SchemeName,
          invoice.ReceiptNumber,
          invoice.ReceiptDate,
          invoice.ExpiryDate,
          invoice.RevisedExpiryDate,
          invoice.Status,
          invoice.StatusName,
          invoice.Amount,
          invoice.BalanceAmount,
          invoice.CanUpdate,
          invoice.IsEdit,
          invoice.IsDelete
        )
      );
    }

    this.hasAdd();
  }

  createInvoiceWithValues(
    Id: number,
    Member: number,
    Type: string,
    TypeName: string,
    SchemeName: string,
    ReceiptNumber: string,
    ReceiptDate: Date,
    ExpiryDate: Date,
    RevisedExpiryDate: Date,
    Status: string,
    StatusName: string,
    Amount: number,
    BalanceAmount: number,
    CanEdit: boolean,
    IsEdit: boolean,
    IsDelete: boolean
  ): FormGroup {
    return this.formBuilder.group({
      Id: new FormControl(Id),
      Member: new FormControl(Member),
      Type: new FormControl(Type),
      TypeName: new FormControl(TypeName),
      SchemeName: new FormControl(SchemeName),
      ReceiptNumber: new FormControl(ReceiptNumber),
      ReceiptDate: new FormControl(ReceiptDate),
      ExpiryDate: new FormControl(ExpiryDate),
      RevisedExpiryDate: new FormControl(RevisedExpiryDate),
      Status: new FormControl(Status),
      StatusName: new FormControl(StatusName),
      Amount: new FormControl(Amount.toFixed(3)),
      BalanceAmount: new FormControl(BalanceAmount.toFixed(3)),
      CanEdit: new FormControl(CanEdit),
      IsEdit: new FormControl(IsEdit),
      IsDelete: new FormControl(IsDelete),
      UpdateStatus: new FormControl("A"),
    });
  }

  removeInvoicesOnReset() {
    for (
      let i = this.updateInvoiceForm.value.Invoices.length - 1;
      i >= 0;
      i--
    ) {
      this.updateInvoiceList.removeAt(i);
    }
  }

  getInvoices() {
    let member: number;
    member = this.updateInvoiceForm.value.Member.Id;
    this.removeInvoicesOnReset();
    this.receiptService.GetInvoicesForUpdate(member.toString()).subscribe(
      (response) => {
        console.log(response);
        this.createFormInvoice(response);
      },
      (err: any) => console.log(err)
    );
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.updateInvoiceForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.updateInvoiceForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
