import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MemberComponent } from "./member/member.component";
import { MembersComponent } from "./members/members.component";
import { StarClubMemberComponent } from "./star-club-member/star-club-member.component";
import { StarClubMembersComponent } from "./star-club-members/star-club-members.component";
import { TransferComponent } from "./transfer/transfer.component";
import { TransfersComponent } from "./transfers/transfers.component";
import { CoreModule } from "../core/core.module";
import { RejoinMemberComponent } from "./rejoin-member/rejoin-member.component";
import { RejoinMembersComponent } from "./rejoin-members/rejoin-members.component";
import { SharedModule } from "../shared/shared.module";
import { NgxPrintModule } from "ngx-print";
import { StatusChangeComponent } from './status-change/status-change.component';
import { StatusChangesComponent } from './status-changes/status-changes.component';

@NgModule({
  declarations: [
    MemberComponent,
    MembersComponent,
    StarClubMemberComponent,
    StarClubMembersComponent,
    TransferComponent,
    TransfersComponent,
    RejoinMemberComponent,
    RejoinMembersComponent,
    StatusChangeComponent,
    StatusChangesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NgSelectModule,
    CoreModule,
    SharedModule,
    NgxPrintModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
})
export class MembersModule {}
