import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import Swal, { SweetAlertType } from "sweetalert2";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { Zone } from "../../core/models/masters/Zone";
import { Branch } from "../../core/models/masters/branch";
import { ZonesService } from "../../core/services/masters/zones.service";
import { BranchesService } from "../../core/services/masters/branches.service";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-branch",
  templateUrl: "./branch.component.html",
  styleUrls: ["./branch.component.css"],
})
export class BranchComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;
  zones$: Observable<Zone[]>;

  branchForm = new FormGroup({
    Id: new FormControl(0),
    Code: new FormControl(""),
    Name: new FormControl(""),
    Zone: new FormControl(0),
    ZoneName: new FormControl(""),
    Active: new FormControl(true),
    ValidateOutInvoiceOnTransfer: new FormControl(false),
    HasFBSInvoice: new FormControl(true),
    HasMembershipInvoice: new FormControl(true),
    ValidateCivilId: new FormControl(true),
  });

  constructor(
    private branchService: BranchesService,
    private zoneService: ZonesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.zones$ = this.zoneService.getZones();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 12000;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getBranch(id);
      }
    });
  }

  cancelBranch() {
    this.router.navigate(["./", { outlets: { pages: ["branches"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getBranch(Id: number) {
    this.branchService.getBranch(Id).subscribe(
      (branch: Branch) => this.editBranch(branch),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["branches"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editBranch(branch: Branch) {
    this.branchForm.patchValue({
      Id: branch.Id,
      Code: branch.Code,
      Name: branch.Name,
      Zone: branch.Zone,
      ZoneName: branch.ZoneName,
      Active: branch.Active,
      ValidateOutInvoiceOnTransfer: branch.ValidateOutInvoiceOnTransfer,
      HasFBSInvoice: branch.HasFBSInvoice,
      HasMembershipInvoice: branch.HasMembershipInvoice,
      ValidateCivilId: branch.ValidateCivilId,
    });
  }

  saveBranch(reset: boolean) {
    const branch: Branch = this.branchForm.value as Branch;
    if (branch.Id < 1) {
      this.branchService.addBranch(branch).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetBranch();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["branches"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.branchService.modifyBranch(branch).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetBranch();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["branches"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetBranch() {
    this.branchForm.reset({
      Id: 0,
      Code: "",
      Name: "",
      Zone: 0,
      ZoneName: "",
      Active: true,
      ValidateOutInvoiceOnTransfer: false,
      HasFBSInvoice: true,
      HasMembershipInvoice: true,
      ValidateCivilId: true,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.branchForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.branchForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
