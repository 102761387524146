import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { Transfer } from "../../core/models/members/transfer";
import { TransfersService } from "../../core/services/members/transfers.service";
import { Member } from "../../core/models/members/member";
import { MembersService } from "../../core//services/members/members.service";
import { ZonesService } from "../../core/services/masters/zones.service";
import { BranchesService } from "../../core/services/masters/branches.service";
import { UnitsService } from "../../core/services/masters/units.service";
import { Zone } from "../../core/models/masters/Zone";
import { Branch } from "../../core/models/masters/branch";
import { Unit } from "../../core/models/masters/unit";
import { MemberList } from "src/app/core/models/members/member-List";
import { StatusUpdate } from "src/app/core/services/members/status-update";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-transfer",
  templateUrl: "./transfer.component.html",
  styleUrls: ["./transfer.component.css"],
})
export class TransferComponent implements OnInit {
  cmembers$: Observable<Member[]>;
  members$: Member[];
  referers$: Member[];
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  cbranches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  TransferDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  Id: number;
  Member: number;
  MemberName: string;
  KKMAStarClub: number;
  KKMAStarClubName: string;
  approvedStatus: boolean;
  closedStatus: boolean;
  cancelledStatus: boolean;

  KKMAStatus = [
    { Status: "A", StatusName: "Active" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "E", StatusName: "Expired" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  transferForm = new FormGroup({
    Id: new FormControl(0),
    TransferDate: new FormControl(new Date()),
    Member: new FormControl({ Id: 0, Name: "" }),
    MemberName: new FormControl(""),
    KKMAZone: new FormControl(0),
    KKMAZoneName: new FormControl(""),
    KKMABranch: new FormControl(0),
    KKMABranchName: new FormControl(""),
    KKMAUnit: new FormControl(0),
    KKMAUnitName: new FormControl(""),
    KKMAStarClub: new FormControl({ Id: 0, Name: "" }),
    KKMAStarClubName: new FormControl(""),
    KKMAStatus: new FormControl("A"),
    Description: new FormControl(""),
    Status: new FormControl("O"),
    StatusName: new FormControl("Open"),
    CKKMAZone: new FormControl(0),
    CKKMAZoneName: new FormControl(""),
    CKKMABranch: new FormControl(0),
    CKKMABranchName: new FormControl(""),
    CKKMAUnit: new FormControl(0),
    CKKMAUnitName: new FormControl(""),
    CKKMAStarClub: new FormControl({ Id: 0, Name: "" }),
    CKKMAStarClubName: new FormControl(""),
    CKKMAStatus: new FormControl("A"),
    CKKMAStatusName: new FormControl("Active"),
    IsApprover: new FormControl(false),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  constructor(
    private transferService: TransfersService,
    private ZoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private memberService: MembersService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.TransferDate = this.calendar.getToday();

    this.cmembers$ = this.memberService.getMembers();
    this.zones$ = this.ZoneService.getZones();
    //this.units$ = this.unitService.getUnits();

    this.loadMembers();
    this.loadStarClubMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 23000;

    this.Id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getTransfer(expID);
      }
    });
    this.TransferDate = this.calendar.getToday();
  }

  cancelTransfer() {
    this.router.navigate(["./", { outlets: { pages: ["transfers"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getTransfer(Id: number) {
    this.transferService.getTransfer(Id).subscribe(
      (transfer: Transfer) => this.editTransfer(transfer),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["transfers"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editTransfer(transfer: Transfer) {
    this.transferForm.patchValue({
      Id: transfer.Id,
      TransferDate: transfer.TransferDate,
      Member: { Id: transfer.Member, Name: transfer.MemberName },
      MemberName: transfer.MemberName,
      CKKMAZone: transfer.KKMAZone,
      CKKMAZoneName: transfer.KKMAZoneName,
      CKKMABranch: transfer.KKMABranch,
      CKKMABranchName: transfer.KKMABranchName,
      CKKMAUnit: transfer.KKMAUnit,
      CKKMAUnitName: transfer.KKMAUnitName,
      CKKMAStarClub: {
        Id: transfer.CKKMAStarClub,
        Name: transfer.KKMAStarClubName,
      },
      CKKMAStarClubName: transfer.CKKMAStarClubName,
      CKKMAStatus: transfer.CKKMAStatus,
      KKMAZone: transfer.KKMAZone,
      KKMAZoneName: transfer.KKMAZoneName,
      KKMABranch: transfer.KKMABranch,
      KKMABranchName: transfer.KKMABranchName,
      KKMAUnit: transfer.KKMAUnit,
      KKMAUnitName: transfer.KKMAUnitName,
      KKMAStarClub: {
        Id: transfer.KKMAStarClub,
        Name: transfer.KKMAStarClubName,
      },
      KKMAStarClubName: transfer.KKMAStarClubName,
      KKMAStatus: transfer.KKMAStatus,
      Description: transfer.Description,
      Status: transfer.Status,
      StatusName:
        transfer.Status === "O"
          ? "Open"
          : transfer.Status === "A"
          ? "Approved"
          : transfer.Status === "C"
          ? "Closed"
          : transfer.Status === "L"
          ? "Cancelled"
          : "Open",
      IsApprover: transfer.IsApprover,
    });
    this.Id = transfer.Id;
    this.Member = transfer.Member;
    this.MemberName = transfer.MemberName;
    this.KKMAStarClub = transfer.KKMAStarClub;
    this.KKMAStarClubName = transfer.KKMAStarClubName;

    this.TransferDate = this.ngbDateAdapter.fromModel(
      new Date(transfer.TransferDate)
    );
    this.statusMenuVisible(transfer.Id, transfer.Status, transfer.IsApprover);
    this.memberService.getMember(this.transferForm.value.Member.Id).subscribe(
      (member: Member) => this.setCurrentDetails(member),
      (err: any) => console.log(err)
    );
  }

  saveTransfer(reset: boolean) {
    if (this.transferForm.value.KKMAStarClub == null) {
      this.transferForm.value.KKMAStarClub = { Id: 0, Name: "" };
    }
    const transfer: Transfer = {
      Id: this.transferForm.value.Id,
      Type: "T",
      TransferDate: this.transferForm.value.TransferDate,
      Member: this.transferForm.value.Member.Id,
      MemberName: this.transferForm.value.Member.Name,
      CKKMAZone: this.transferForm.value.CKKMAZone,
      CKKMAZoneName: this.transferForm.value.CKKMAZoneName,
      CKKMABranch: this.transferForm.value.CKKMABranch,
      CKKMABranchName: this.transferForm.value.CKKMABranchName,
      CKKMAUnit: this.transferForm.value.CKKMAUnit,
      CKKMAUnitName: this.transferForm.value.CKKMAUnitName,
      CKKMAStarClub: this.transferForm.value.CKKMAStarClub.Id,
      CKKMAStarClubName: this.transferForm.value.CKKMAStarClub.Name,
      CKKMAStatus: this.transferForm.value.CKKMAStatus,
      KKMAZone: this.transferForm.value.KKMAZone,
      KKMAZoneName: this.transferForm.value.KKMAZoneName,
      KKMABranch: this.transferForm.value.KKMABranch,
      KKMABranchName: this.transferForm.value.KKMABranchName,
      KKMAUnit: this.transferForm.value.KKMAUnit,
      KKMAUnitName: this.transferForm.value.KKMAUnitName,
      KKMAStarClub: this.transferForm.value.KKMAStarClub.Id,
      KKMAStarClubName: this.transferForm.value.KKMAStarClub.Name,
      KKMAStatus: this.transferForm.value.KKMAStatus,
      Description: this.transferForm.value.Description,
      Status: this.transferForm.value.Status,
      IsApprover: this.transferForm.value.IsApprover,
      KKMAId: "",
      FKKMAZoneName: "",
      FKKMABranchName: "",
      FKKMAUnitName: "",
      FKKMAStarClubName: "",
      CreatedBy: "",
      CreatedOn: new Date(),
      EditedBy: "",
      EditedOn: new Date(),
    };

    if (transfer.Id < 1) {
      this.transferService.addTransfer(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetTransfer();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["transfers"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.transferService.modifyTransfer(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetTransfer();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["transfers"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  updateStatus(stat) {
    if (this.transferForm.value.Id < 1) {
      this.displayFeedback(
        "error",
        "Error!",
        "Please select a transfer to update status!"
      );
    } else {
      const transfer: StatusUpdate = {
        Id: this.transferForm.value.Id,
        Status: stat,
        EditedBy: "",
      };

      this.transferService.modifyStatus(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Status Updated Successfully!", "");
            this.transferForm.patchValue({
              Status: stat,
              StatusName:
                stat === "O"
                  ? "Open"
                  : stat === "A"
                  ? "Approved"
                  : stat === "C"
                  ? "Closed"
                  : stat === "L"
                  ? "Cancelled"
                  : "Open",
            });
            this.statusMenuVisible(transfer.Id, stat, false);
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetTransfer() {
    this.transferForm.reset({
      Id: 0,
      TransferDate: new Date(),
      Member: { Id: 0, Name: "" },
      MemberName: "",
      KKMAZone: 0,
      KKMAZoneName: "",
      KKMABranch: 0,
      KKMABranchName: "",
      KKMAUnit: 0,
      KKMAUnitName: "",
      KKMAStarClub: { Id: 0, Name: "" },
      KKMAStarClubName: "",
      KKMAStatus: "A",
      Description: "",
      Status: "O",
      StatusName: "Open",
      CKKMAZone: 0,
      CKKMAZoneName: "",
      CKKMABranch: 0,
      CKKMABranchName: "",
      CKKMAUnit: 0,
      CKKMAUnitName: "",
      CKKMAStarClub: { Id: 0, Name: "" },
      CKKMAStarClubName: "",
      CKKMAStatus: "A",
      CKKMAStatusName: "Active",
      IsApprover: false,
    });
    this.statusMenuVisible(0, "", false);
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getCBranches(zone: number) {
    this.cbranches$ = this.branchService.getZoneBranches(zone);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }
  getCurrentDetails() {
    if (this.transferForm.value.Member != null) {
      this.memberService.getMember(this.transferForm.value.Member.Id).subscribe(
        (member: Member) => this.setCurrentDetails(member),
        (err: any) => console.log(err)
      );
    } else {
      this.memberService.getMember(0).subscribe(
        (member: Member) => this.setCurrentDetails(member),
        (err: any) => console.log(err)
      );
    }
  }

  getReferrerDetails($event) {
    this.transferForm.patchValue({
      KKMAStarClub: $event.item.Id,
      KKMAStarClubName: $event.item.FullName,
    });
  }

  setCurrentDetails(member: Member) {
    this.transferForm.patchValue({
      CKKMAZone: member.KKMAZone,
      CKKMAZoneName: member.KKMAZoneName,
      CKKMABranch: member.KKMABranch,
      CKKMABranchName: member.KKMABranchName,
      CKKMAUnit: member.KKMAUnit,
      CKKMAUnitName: member.KKMAUnitName,
      CKKMAStarClub: member.Referrer,
      CKKMAStarClubName: member.ReferrerName,
      CKKMAStatus: member.Status,
      CKKMAStatusName:
        member.Status == "A"
          ? "Active"
          : member.Status == "I"
          ? "Inactive"
          : member.Status == "E"
          ? "Expired"
          : member.Status == "C"
          ? "Cancelled"
          : member.Status == "O"
          ? "Cancelled - Overseas"
          : "On Vacation",
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.GetAllMembersListForTransfer(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  statusMenuVisible(id, status, IsApprover) {
    if (id < 0) {
      this.approvedStatus = false;
      this.closedStatus = false;
      this.cancelledStatus = false;
    } else {
      if (!IsApprover) {
        this.approvedStatus = false;
        this.closedStatus = false;
        this.cancelledStatus = false;
      } else {
        if (status === "O") {
          this.approvedStatus = true;
          this.cancelledStatus = true;
          this.closedStatus = false;
        } else if (status === "A") {
          this.approvedStatus = false;
          this.closedStatus = true;
          this.cancelledStatus = false;
        } else {
          this.approvedStatus = false;
          this.closedStatus = false;
          this.cancelledStatus = false;
        }
      }
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.transferForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.transferForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
