import { Component, OnInit, ContentChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

import { Member } from "../../core/models/members/member";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { MembersService } from "../../core/services/members/members.service";
import { StarClubMember } from "../../core/models/members/star-club-member";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-star-club-members",
  templateUrl: "./star-club-members.component.html",
  styleUrls: ["./star-club-members.component.css"],
})
export class StarClubMembersComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  @ContentChild(NgbPagination, /* TODO: add static flag */ null)
  pagination: NgbPagination;

  userDetails: UserDetails;
  menuId: number;

  page = 1;
  pageSize = 25;
  collectionSize = 0;
  listItemFrom = 1;
  listItemTo = 25;
  searchBy = "th.KKMA_ID";
  searchText = "";

  members: Member[];

  constructor(
    private memberService: MembersService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.pageSize = 25;
    this.searchBy = "th.KKMA_ID";
    this.searchText = "";
    this.collectionSize = 0;
    this.listItemFrom = 1;
    this.listItemTo = 25;

    this.setStarClubMemberList();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 22000;
  }

  loadMember(id: number) {
    this.router.navigate(
      ["./", { outlets: { pages: ["star-club-member", id] } }],
      { relativeTo: this.route.parent }
    );
  }

  deleteMember(id: number) {
    if (confirm("Are you sure to delete the selected member?")) {
      const starClubMember: StarClubMember = {
        Member: id,
        MemberName: "",
        IsStarClubMember: false,
        CreatedBy: "",
      };

      this.memberService.modifyStarClub(starClubMember).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Deleted Successfully!", "");
            this.setStarClubMemberList();
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getPageFromService(pages) {
    this.page = pages;
    this.setStarClubMemberList();
  }

  setPageCount(pageSizes) {
    this.pageSize = pageSizes;
    this.setStarClubMemberList();
  }

  onSearchTextChange() {
    this.setStarClubMemberList();
  }

  setStarClubMemberList() {
    this.spinnerService.show();
    this.memberService
      .getStarClubMembersBySearch(
        this.page,
        this.pageSize,
        this.searchBy,
        this.searchText
      )
      .subscribe(
        (response) => {
          this.collectionSize = response.TotalItems;
          this.listItemFrom = response.ListItemFrom;
          this.listItemTo = response.ListItemTo;
          this.members = response.Members as Member[];
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd) privilage = true;
      }
    });

    return privilage;
  }

  hasView() {
    let privilage = false;
    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeView || value.PrivilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasDelete() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeDelete) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  hasAudit() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAudit) privilage = true;
      }
    });

    return privilage;
  }

  auditMember(id: number) {
    alert("Audit Display");
  }

  trackMember(index, item) {
    return item;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Star Club Members");

    XLSX.writeFile(wb, "Star Club Members.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
