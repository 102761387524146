import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { MembersList } from "../../../core/models/reports/members/members-list";
import { MemberFamilyList } from "../../../core/models/reports/members/member-family-list";
import { MembershipHistory } from "../../../core/models/reports/schemes/membsership-history";
import { FbsHistory } from "../../../core/models/reports/schemes/fbs-history";
import { SchemeHistory } from "../../../core/models/reports/schemes/scheme-history";
import { ReceiptHistory } from "../../models/reports/receipts/receipts-history";
import { MemberTalent } from "../../../core/models/reports/members/member-talent";
import { MemberNotRenewed } from "../../models/reports/members/member-not-renewed";
import { MemberRenewed } from "../../models/reports/members/member-renewed";
import { MemberArea } from "../../models/reports/members/member-area";
import { MemberSchemeDues } from "../../models/reports/members/member-scheme-dues";
import { MemberSchemes } from "../../models/reports/members/member-schemes";
import { MemberSchemeStatus } from "../../models/reports/members/member-scheme-status";
import { MemberDetailsDashboard } from "../../models/reports/members/member-details-dashboard";
import { FbsDashboard } from "../../models/reports/schemes/fbs-dashboard";
import { CollectionSummary } from "../../models/reports/fbs/collection-summary";
import { CollectionSheet } from "../../models/reports/fbs/collection-sheet";
import { StarClubPerfomance } from "../../models/reports/fbs/star-club-performance";
import { SchemeSummary } from "../../models/reports/fbs/scheme-summary";
import { MemberDetailsByMembershipWithFbs } from "../../models/reports/receipts/member-details-by-membership-with-fbs";
import { MemberRejoin } from "../../models/reports/members/member-rejoin";

@Injectable({
  providedIn: "root",
})
export class ReportGeneratorService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getDashboard(index: number): Observable<any> {
    const params = new HttpParams().set("index", index.toString());
    return this.http.get<any>(
      environment.root + "/ReportsGenerator/GetDashboard",
      { params }
    );
  }

  getMemberList(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    status: string
  ): Observable<MembersList[]> {
    const params = new HttpParams()
      .set("zone", zone.toString())
      .set("branch", branch.toString())
      .set("unit", unit.toString())
      .set("referrer", referrer.toString())
      .set("status", status);
    return this.http.get<MembersList[]>(
      environment.root + "/ReportsGenerator/GetMemberList",
      { params }
    );
  }

  getMemberListByOrg(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    status: string
  ): Observable<MembersList[]> {
    const params = new HttpParams()
      .set("zone", zone.toString())
      .set("branch", branch.toString())
      .set("unit", unit.toString())
      .set("referrer", referrer.toString())
      .set("status", status);
    return this.http.get<MembersList[]>(
      environment.root + "/ReportsGenerator/GetMemberListByOrg",
      { params }
    );
  }

  getMemberListByInd(
    country: number,
    state: number,
    district: number,
    panchayath: number,
    postOffice: number,
    status: string
  ): Observable<MembersList[]> {
    const params = new HttpParams()
      .set("country", country.toString())
      .set("state", state.toString())
      .set("district", district.toString())
      .set("panchayath", panchayath.toString())
      .set("postOffice", postOffice.toString())
      .set("status", status);
    return this.http.get<MembersList[]>(
      environment.root + "/ReportsGenerator/GetMemberListByInd",
      { params }
    );
  }

  getMemberListByKuwait(
    area: number,
    block: number,
    qualification: number,
    occupation: number,
    status: string
  ): Observable<MembersList[]> {
    const params = new HttpParams()
      .set("area", area.toString())
      .set("block", block.toString())
      .set("qualification", qualification.toString())
      .set("occupation", occupation.toString())
      .set("status", status);
    return this.http.get<MembersList[]>(
      environment.root + "/ReportsGenerator/GetMemberListByKuwait",
      { params }
    );
  }

  getMemberFamilyList(
    member: number,
    relation: number,
    qualification: number,
    status: string
  ): Observable<MemberFamilyList[]> {
    const params = new HttpParams()
      .set("member", member.toString())
      .set("relation", relation.toString())
      .set("qualification", qualification.toString())
      .set("status", status);
    return this.http.get<MemberFamilyList[]>(
      environment.root + "/ReportsGenerator/GetMemberFamilyList",
      { params }
    );
  }

  getMembershipHistory(searchFields): Observable<MembershipHistory[]> {
    return this.http.post<MembershipHistory[]>(
      environment.root + "/ReportsGenerator/GetMembershipHistory",
      searchFields
    );
  }

  getMemberRejoin(searchFields): Observable<MemberRejoin[]> {
    return this.http.post<MemberRejoin[]>(
      environment.root + "/ReportsGenerator/GetMemberRejoin",
      searchFields
    );
  }

  getFbsHistory(searchFields): Observable<FbsHistory[]> {
    return this.http.post<FbsHistory[]>(
      environment.root + "/ReportsGenerator/GetFbsHistory",
      searchFields
    );
  }

  getSchemesHistory(searchFields): Observable<SchemeHistory[]> {
    return this.http.post<SchemeHistory[]>(
      environment.root + "/ReportsGenerator/GetSchemeHistory",
      searchFields
    );
  }

  getFbsByMember(member: number, status: string): Observable<any[]> {
    const params = new HttpParams()
      .set("member", member.toString())
      .set("status", status.toString());
    return this.http.get<any[]>(
      environment.root + "/ReportsGenerator/GetFbsByMember",
      { params }
    );
  }

  getMembershipByMember(member: number, status: string): Observable<any[]> {
    const params = new HttpParams()
      .set("member", member.toString())
      .set("status", status.toString());
    return this.http.get<any[]>(
      environment.root + "/ReportsGenerator/GetMembershipByMember",
      { params }
    );
  }

  getSchemesByMember(member: number, status: string): Observable<any[]> {
    const params = new HttpParams()
      .set("member", member.toString())
      .set("status", status.toString());
    return this.http.get<any[]>(
      environment.root + "/ReportsGenerator/GetSchemeByMember",
      { params }
    );
  }

  getReceiptsHistory(searchFields): Observable<ReceiptHistory[]> {
    return this.http.post<ReceiptHistory[]>(
      environment.root + "/ReportsGenerator/GetReceiptsHistory",
      searchFields
    );
  }

  getReceiptByMember(member: number): Observable<any[]> {
    const params = new HttpParams().set("member", member.toString());
    return this.http.get<any[]>(
      environment.root + "/ReportsGenerator/GetReceiptByMember",
      { params }
    );
  }

  GetMemberDetails(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    bloodGroup: string,
    occupation: number,
    qualification: number
  ): Observable<MembersList[]> {
    const params = new HttpParams()
      .set("zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("BloodGroup", bloodGroup.toString())
      .set("Occupation", occupation.toString())
      .set("Qualification", qualification.toString());
    return this.http.get<MembersList[]>(
      environment.root + "/ReportsGenerator/GetMemberDetails",
      { params }
    );
  }

  GetMemberDetailsKerala(
    zone: number,
    branch: number,
    state: number,
    district: number,
    panchayath: number,
    postOffice: number
  ): Observable<MembersList[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("State", state.toString())
      .set("District", district.toString())
      .set("Panchayath", panchayath.toString())
      .set("PostOffice", postOffice.toString());
    return this.http.get<MembersList[]>(
      environment.root + "/ReportsGenerator/GetMemberDetailsKerala",
      { params }
    );
  }

  GetMemberByTalent(
    zone: number,
    branch: number,
    unit: number,
    talent: number
  ): Observable<MemberTalent[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Talent", talent.toString());
    return this.http.get<MemberTalent[]>(
      environment.root + "/ReportsGenerator/GetMemberByTalent",
      { params }
    );
  }
  GetMemberNotRenewed(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    NewMember: string,
    typeId: number
  ): Observable<MemberNotRenewed[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("NewMember", NewMember)
      .set("TypeId", typeId.toString());
    return this.http.get<MemberNotRenewed[]>(
      environment.root + "/ReportsGenerator/GetMemberNotRenewed",
      { params }
    );
  }
  GetMemberRenewed(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    NewMember: string,
    typeId: number
  ): Observable<MemberRenewed[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("NewMember", NewMember)
      .set("TypeId", typeId.toString());
    return this.http.get<MemberRenewed[]>(
      environment.root + "/ReportsGenerator/GetMemberRenewed",
      { params }
    );
  }
  GetMemberByArea(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    area: number
  ): Observable<MemberArea[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("Area", area.toString());
    return this.http.get<MemberArea[]>(
      environment.root + "/ReportsGenerator/GetMemberByArea",
      { params }
    );
  }
  GetMemberSchemeDues(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    scheme: number
  ): Observable<MemberSchemeDues[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("Scheme", scheme.toString());
    return this.http.get<MemberSchemeDues[]>(
      environment.root + "/ReportsGenerator/GetMemberSchemeDues",
      { params }
    );
  }
  GetMemberSchemes(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    scheme: number
  ): Observable<MemberSchemes[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("Scheme", scheme.toString());
    return this.http.get<MemberSchemes[]>(
      environment.root + "/ReportsGenerator/GetMemberSchemes",
      { params }
    );
  }
  GetMemberSchemeStatus(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    scheme: number,
    status: string
  ): Observable<MemberSchemeStatus[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("Scheme", scheme.toString())
      .set("Status", status.toString());
    return this.http.get<MemberSchemeStatus[]>(
      environment.root + "/ReportsGenerator/GetMemberSchemeStatus",
      { params }
    );
  }
  GetMemberDetailsDashboard(
    zone: number,
    branch: number,
    unit: number,
    referrer: number
  ): Observable<MemberDetailsDashboard[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString());
    return this.http.get<MemberDetailsDashboard[]>(
      environment.root + "/ReportsGenerator/GetMemberDetailsDashboard",
      { params }
    );
  }
  GetFbsDashboard(
    zone: number,
    branch: number,
    unit: number,
    referrer: number,
    scheme: number
  ): Observable<FbsDashboard[]> {
    const params = new HttpParams()
      .set("Zone", zone.toString())
      .set("Branch", branch.toString())
      .set("Unit", unit.toString())
      .set("Referrer", referrer.toString())
      .set("Scheme", scheme.toString());
    return this.http.get<FbsDashboard[]>(
      environment.root + "/ReportsGenerator/GetFbsDashboard",
      { params }
    );
  }

  getCollectionSummary(searchFields): Observable<CollectionSummary[]> {
    return this.http.post<CollectionSummary[]>(
      environment.root + "/ReportsGenerator/GetCollectionSummary",
      searchFields
    );
  }

  getCollectionSheet(searchFields): Observable<CollectionSheet[]> {
    return this.http.post<CollectionSheet[]>(
      environment.root + "/ReportsGenerator/GetCollectionSheet",
      searchFields
    );
  }

  getStarClubPerformance(searchFields): Observable<StarClubPerfomance[]> {
    return this.http.post<StarClubPerfomance[]>(
      environment.root + "/ReportsGenerator/GetStarClubPerfomance",
      searchFields
    );
  }

  getSchemeSummary(searchFields): Observable<SchemeSummary[]> {
    return this.http.post<SchemeSummary[]>(
      environment.root + "/ReportsGenerator/GetSchemeSummary",
      searchFields
    );
  }

  getMemberDetailsByMembershipWithFbs(
    searchFields
  ): Observable<MemberDetailsByMembershipWithFbs[]> {
    return this.http.post<MemberDetailsByMembershipWithFbs[]>(
      environment.root +
        "/ReportsGenerator/GetMemberDetailsByMembershipWithFbs",
      searchFields
    );
  }

  getMemberDetailsByMembershipWithFbs1(
    searchFields
  ): Observable<MemberDetailsByMembershipWithFbs[]> {
    return this.http.post<MemberDetailsByMembershipWithFbs[]>(
      environment.root +
        "/ReportsGenerator/GetMemberDetailsByMembershipWithFbs1",
      searchFields
    );
  }

  getMemberFamilyDetails(searchFields): Observable<any[]> {
    return this.http.post<any[]>(
      environment.root + "/ReportsGenerator/GetMemberFamilyDetails",
      searchFields
    );
  }
}
