import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { PostOffice } from "../../models/masters/post-office";

@Injectable({
  providedIn: "root",
})
export class PostOfficesService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getPostoffices(): Observable<PostOffice[]> {
    return this.http.get<PostOffice[]>(environment.root + "/Postoffice");
  }

  getDistrictPostoffices(district): Observable<PostOffice[]> {
    const params = new HttpParams().set("district", district);
    console.log(district);
    return this.http.get<PostOffice[]>(
      environment.root + "/Postoffice/GetAllDistrictPostOffices",
      { params }
    );
  }

  getPinCode(postoffice): Observable<any> {
    const params = new HttpParams().set("postoffice", postoffice);
    return this.http.get<any>(environment.root + "/Postoffice/GetPinCode", {
      params,
    });
  }

  getPostoffice(id: number): Observable<PostOffice> {
    return this.http.get<PostOffice>(environment.root + "/Postoffice/" + id);
  }

  addPostoffice(postoffice: PostOffice) {
    return this.http
      .post(environment.root + "/Postoffice/Add", postoffice, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyPostoffice(postoffice: PostOffice) {
    return this.http
      .post(
        environment.root + "/Postoffice/Modify",
        postoffice,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  deletePostoffice(id: number) {
    return this.http
      .post(environment.root + "/Postoffice/Delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getPostOfficeBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      TotalItems: number;
      ListItemFrom: number;
      ListItemTo: number;
      PostOffices: PostOffice[];
    }>(environment.root + "/PostOffice/GetAllPostOfficeBySearch", { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
