import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { Observable, Subject, concat, of } from 'rxjs';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbStringAdapterService } from 'src/app/shared/services/ngb-string-adapter.service';
import { debounceTime, map, distinctUntilChanged, tap, switchMap, catchError} from 'rxjs/operators';
import Swal, { SweetAlertType } from 'sweetalert2';

import { FineService } from '../../core/services/schemes/fine.service';
import { Fine, FineScheme } from '../../core/models/schemes/fine';
import { Scheme } from '../../core/models/masters/scheme';
import { SchemesService } from '../../core//services/masters/schemes.service';
import { Member } from '../../core/models/members/member';
import { MembersService } from '../../core//services/members/members.service';
import { MemberList } from 'src/app/core/models/members/member-List';
import { StatusUpdate } from 'src/app/core/services/members/status-update';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-fine',
  templateUrl: './fine.component.html',
  styleUrls: ['./fine.component.css']
})
export class FineComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;
  FineDate: NgbDateStruct;

  public fineForm: FormGroup;
  public schemeList: FormArray;
  approvedStatus: boolean;
  closedStatus: boolean;
  cancelledStatus: boolean;

  schemes$: Observable<Scheme[]>;
  members$: Member[];

  Id: number;
  ZeroValue = 0;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(private fineService: FineService, private schemeService: SchemesService,
              private memberService: MembersService,
              private ngbDateAdapter: NgbStringAdapterService,
              private formBuilder: FormBuilder, private calendar: NgbCalendar,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.Id = 0;
    this.FineDate = this.calendar.getToday();

    this.fineForm = new FormGroup({
      Id: new FormControl(0),
      FineNumber: new FormControl(''),
      FineDate: new FormControl(new Date()),
      Description: new FormControl(''),
      Amount: new FormControl(this.ZeroValue.toFixed(3)),
      Status: new FormControl('O'),
      StatusName: new FormControl('Open'),
      schemes: this.formBuilder.array([])
  });
    this.schemeList = this.fineForm.get('schemes') as FormArray;

    this.schemes$ = this.schemeService.getSchemes();

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 50000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      console.log(id);
      if (id) {
        this.getFine(id);
      }
    });
  }

  cancelFine() {
    this.router.navigate(['./', { outlets: { pages: ['fines']} }], { relativeTo: this.route.parent });
  }

  getFine(Id: number) {
    this.fineService.getFine(Id).subscribe(
      (fine: Fine) => this.editFine(fine),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['fines']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editFine(fine: Fine) {
    this.fineForm.patchValue({
      Id: fine.Id,
      FineNumber: fine.FineNumber,
      FineDate: fine.FineDate,
      Description: fine.Description,
      Amount: fine.Amount.toFixed(3),
      Status: fine.Status,
      StatusName: fine.Status === 'O' ? 'Open' :
                  fine.Status === 'A' ? 'Approved' :
                  fine.Status === 'C' ? 'Closed' :
                  fine.Status === 'L' ? 'Cancelled' : 'Open',
      schemes: []
    });
    this.createFormScheme(fine);
    this.FineDate = this.ngbDateAdapter.fromModel(new Date(this.fineForm.value.FineDate));
    this.statusMenuVisible(fine.Id, fine.Status);
  }

  saveFine(reset: boolean) {
    const fine: Fine = {
      Id: this.fineForm.value.Id,
      FineNumber: this.fineForm.value.FineNumber,
      FineDate: this.fineForm.value.FineDate,
      Description: this.fineForm.value.Description,
      Amount: this.fineForm.value.Amount,
      Status: this.fineForm.value.Status,
      CreatedBy: '',
      CreatedOn: new Date(),
      EditedBy: '',
      EditedOn: new Date(),
      Schemes: null // this.fineForm.value.schemes
    };

    const SchemesList = this.fineForm.value.schemes;

    const schemes: FineScheme[] = [];

    for (const scheme of SchemesList) {

      const c: FineScheme = {
        FSId: scheme.FSId,
        FSFine: scheme.FSFine,
        FSScheme: scheme.FSScheme,
        FSSchemeName: scheme.FSSchemeName,
        FSMember: scheme.FSMember.Id,
        FSMemberName: scheme.FSMember.Name,
        FSAmount: scheme.FSAmount
      };
      schemes.push(c);
    }
    fine.Schemes = schemes;

    if (fine.Id < 1) {
      console.log(fine);
      this.fineService.addFine(fine)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetFine();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['fines']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.fineService.modifyFine(fine)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetFine();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['fines']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  updateStatus(stat) {
    if (this.fineForm.value.Id < 1) {
      this.displayFeedback('error', 'Error!', 'Please select a fine to update status!');
    } else {
      const transfer: StatusUpdate = {
        Id: this.fineForm.value.Id,
        Status: stat,
        EditedBy: ''
      };

      this.fineService.modifyStatus(transfer)
      .subscribe (
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback('success', 'Status Updated Successfully!', '');
            this.fineForm.patchValue({
              Status: stat,
              StatusName: stat === 'O' ? 'Open' :
                          stat === 'A' ? 'Approved' :
                          stat === 'C' ? 'Closed' :
                          stat === 'L' ? 'Cancelled' : 'Open'
            });
            this.statusMenuVisible(transfer.Id, stat);
          } else {
            this.displayHTMLFeedback('warning', response.result + '!', response.message);
          }
        },
        (error) => {
          this.displayFeedback('error', 'Error!', error);
        }
      );
    }
  }

  resetFine() {
    this.fineForm.reset({
      Id: 0,
      FineNumber: '',
      FineDate: new Date(),
      Description: '',
      Amount: this.ZeroValue.toFixed(3),
      Status: 'O',
      StatusName: 'Open'
    });
    this. removeSchemesOnReset();
  }

  get schemeFormGroup() {
    return this.fineForm.get('schemes') as FormArray;
  }

  createFormScheme(schemes: Fine) {
    // for (let i = 0; i < schemes.Schemes.length; i++) {
    //   this.schemeList.push(this.createSchemeWithValues(schemes.Schemes[i].MSId,
    //     schemes.Schemes[i].MSScheme, schemes.Schemes[i].MSSchemeName,
    //     schemes.Schemes[i].MSAmount, schemes.Schemes[i].MSIsOneTime));
    // }
      for (const scheme of schemes.Schemes) {
        this.schemeList.push(this.createSchemeWithValues(
              scheme.FSId, scheme.FSScheme, scheme.FSSchemeName,
              scheme.FSMember, scheme.FSMemberName, scheme.FSAmount));
      }
  }

  createSchemeWithValues(FSId: number, FSScheme: number, FSSchemeName: string,
                         FSMember: number, FSMemberName: string, FSAmount: number): FormGroup {
    return this.formBuilder.group({
      FSId:  new FormControl(FSId),
      FSScheme: new FormControl(FSScheme),
      FSSchemeName: new FormControl(FSSchemeName),
      FSMember: new FormControl({Id: FSMember, Name: FSMemberName}),
      FSMemberName: new FormControl(FSMemberName),
      FSAmount: new FormControl(FSAmount)
    });
  }

  createScheme(): FormGroup {
    return this.formBuilder.group({
      FSId:  new FormControl(0),
      FSScheme: new FormControl(0),
      FSSchemeName: new FormControl(''),
      FSMember: new FormControl({Id: 0, Name: ''}),
      FSMemberName: new FormControl(''),
      FSAmount: new FormControl(0)
    });
  }

  addScheme() {
    this.schemeList.push(this.createScheme());
  }

  removeSchemesOnReset() {
    for (let i = this.fineForm.value.schemes.length - 1; i >= 0; i--) {
      this.removeScheme(i);
    }
  }

  removeScheme(index) {
    this.schemeList.removeAt(index);
    this.calculateTotal();
  }

  calculateTotal() {
    let schemesList: any[];
    let totalAmount: number;
    totalAmount = 0;
    schemesList = this.fineForm.value.schemes;
    for (const scheme of schemesList) {
      if (scheme.FSAmount === null || scheme.FSAmount === '' || scheme.FSAmount === '.') {
        scheme.FSAmount = 0;
      }
      totalAmount += parseFloat(scheme.FSAmount);
    }
    this.fineForm.patchValue({
      Amount: totalAmount.toFixed(3)
    });
  }

  setMemberFormValues(row) {
    let schemesList: any[];
    let count: number;
    count = 0;
    schemesList = this.fineForm.value.schemes;
    for (const scheme of schemesList) {
      if (count === row) {
        if (scheme.FSMember == null) {
          scheme.FSMember = {Id: 0, Name: '' };
        }
      }
      count += 1;
    }
    this.fineForm.patchValue({
      Schemes: schemesList
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.memberLoading = true),
        switchMap(
          term => this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => this.memberLoading = false)
          )
        )
      )
    );
  }

  statusMenuVisible(id, status) {
    if (id < 0) {
      this.approvedStatus = false;
      this.closedStatus = false;
      this.cancelledStatus = false;
    } else {
      if (status === 'O') {
        this.approvedStatus = true;
        this.cancelledStatus = true;
        this.closedStatus = false;
      } else if (status === 'A') {
        this.approvedStatus = false;
        this.closedStatus = true;
        this.cancelledStatus = false;
      } else {
        this.approvedStatus = false;
        this.closedStatus = false;
        this.cancelledStatus = false;
      }
    }
  }

  hasAdd() {
  let privilage = false;

    this.userDetails.Menus.forEach(value => {
       if (value.Id === this.menuId) {
         if(value.PrivilegeAdd && this.fineForm.value.Id < 1)
            privilage = true;
          else if(value.PrivilegeModify && this.fineForm.value.Id > 0)
            privilage = true;
       }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
