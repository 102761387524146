import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";

import { UsersService } from "./../../services/system/users.service";
import { HttpResponse, HttpResult } from "../../models/utils/http-response";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm = new FormGroup({
    OPassword: new FormControl(""),
    Password: new FormControl(""),
    CPassword: new FormControl(""),
    ResetPasswordOnLogin: new FormControl(true),
  });

  constructor(
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.userService.changePassword(this.changePasswordForm.value).subscribe(
      (res: HttpResponse) => {
        if (res.result === HttpResult.Success) {
          this.displayHTMLFeedback(
            "success",
            "Password updated successfully!",
            ""
          );
          this.router.navigateByUrl("home/(pages:dashboard)");
        } else {
          this.displayHTMLFeedback("warning", res.result + "!", res.message);
        }
      },
      (err) => {
        if (err.status === 400) {
          this.displayFeedback(
            "warning",
            "Incorrect old password",
            "Authentication failed.!"
          );
        } else {
          this.displayFeedback("error", "Error!", err);
        }
      }
    );
  }

  resetLogin() {
    this.changePasswordForm.reset({
      NPassword: "",
      Password: "",
      CPassword: "",
      ResetPasswordOnLogin: true,
    });
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
