import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReceiptComponent } from "./receipt/receipt.component";
import { ReceiptsComponent } from "./receipts/receipts.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { RevicedInvoiceComponent } from "./reviced-invoice/reviced-invoice.component";
import { RevicedInvoicesComponent } from "./reviced-invoices/reviced-invoices.component";
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { ReceiptMembershipComponent } from "./receipt-membership/receipt-membership.component";
import { ReceiptsMembershipComponent } from "./receipts-membership/receipts-membership.component";
import { ReceiptsFbsComponent } from "./receipts-fbs/receipts-fbs.component";
import { ReceiptFbsComponent } from "./receipt-fbs/receipt-fbs.component";
import { ReceiptFineComponent } from "./receipt-fine/receipt-fine.component";
import { ReceiptsFineComponent } from "./receipts-fine/receipts-fine.component";
import { NgxPrintModule } from "ngx-print";
import { ReceiptApprovalComponent } from "./receipt-approval/receipt-approval.component";
import { CancelInvoicesComponent } from "./cancel-invoices/cancel-invoices.component";
import { CancelInvoiceComponent } from "./cancel-invoice/cancel-invoice.component";
import { UpdateInvoiceComponent } from './update-invoice/update-invoice.component';

@NgModule({
  declarations: [
    ReceiptComponent,
    ReceiptsComponent,
    RevicedInvoiceComponent,
    RevicedInvoicesComponent,
    ReceiptMembershipComponent,
    ReceiptsMembershipComponent,
    ReceiptsFbsComponent,
    ReceiptFbsComponent,
    ReceiptFineComponent,
    ReceiptsFineComponent,
    ReceiptApprovalComponent,
    CancelInvoicesComponent,
    CancelInvoiceComponent,
    UpdateInvoiceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    CoreModule,
    NgxPrintModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [ReceiptsComponent],
})
export class ReceiptsModule {}
