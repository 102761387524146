import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Role, RoleMenu } from '../../models/system/role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.root + '/Role');
  }

  getRole(id: number): Observable<Role> {
    return this.http.get<Role>(environment.root + '/Role/' + id);
  }

  addRole(role: Role) {
    return this.http.post(environment.root + '/Role/Add', role, this.httpOptions)
        .pipe(
          catchError(this.handleError)
        );
  }

  modifyRole(role: Role) {
    return this.http.post(environment.root + '/Role/Modify', role, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteRole(id: number) {
    return this.http.post(environment.root + '/Role/Delete/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getRoleBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('searchBy', searchBy.toString())
      .set('searchText', searchText.toString());

    return this.http.get<{TotalItems: number, ListItemFrom: number, ListItemTo: number, Roles: Role[]}>
      (environment.root + '/Role/GetAllRoleBySearch', { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
