import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CoreModule } from "src/app/core/core.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MemebersListComponent } from "./memebers-list/memebers-list.component";
import { MemebersListByOrgComponent } from "./memebers-list-by-org/memebers-list-by-org.component";
import { MemebersListByKuwaitComponent } from "./memebers-list-by-kuwait/memebers-list-by-kuwait.component";
import { MemebersListByIndiaComponent } from "./memebers-list-by-india/memebers-list-by-india.component";
import { MemebersFamilyListComponent } from "./memebers-family-list/memebers-family-list.component";
import { MemberDetailsComponent } from "./member-details/member-details.component";
import { MemberDetailsKeralaComponent } from "./member-details-kerala/member-details-kerala.component";
import { MemberTalentComponent } from "./member-talent/member-talent.component";
import { MemberNotRenewedComponent } from "./member-not-renewed/member-not-renewed.component";
import { MemberRenewedComponent } from "./member-renewed/member-renewed.component";
import { MemberAreaComponent } from "./member-area/member-area.component";
import { MemberSchemeDuesComponent } from "./member-scheme-dues/member-scheme-dues.component";
import { MemberSchemesComponent } from "./member-schemes/member-schemes.component";
import { MemberSchemeStatusComponent } from "./member-scheme-status/member-scheme-status.component";
import { MemberDetailsDashboardComponent } from "./member-details-dashboard/member-details-dashboard.component";
import { MemberRejoinComponent } from "./member-rejoin/member-rejoin.component";
import { MemberFamilyDetailsComponent } from './member-family-details/member-family-details.component';
import { ExpiredMemberDetailsComponent } from './expired-member-details/expired-member-details.component';

@NgModule({
  declarations: [
    MemebersListComponent,
    MemebersListByOrgComponent,
    MemebersListByKuwaitComponent,
    MemebersListByIndiaComponent,
    MemebersFamilyListComponent,
    MemberDetailsComponent,
    MemberDetailsKeralaComponent,
    MemberTalentComponent,
    MemberNotRenewedComponent,
    MemberRenewedComponent,
    MemberAreaComponent,
    MemberSchemeDuesComponent,
    MemberSchemesComponent,
    MemberSchemeStatusComponent,
    MemberDetailsDashboardComponent,
    MemberRejoinComponent,
    MemberFamilyDetailsComponent,
    ExpiredMemberDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
})
export class MembersModule {}
