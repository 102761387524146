import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import Swal, { SweetAlertType } from 'sweetalert2';
import { User } from '../../core/models/system/user';
import { UsersService } from '../../core/services/system/users.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';


@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.css']
})
export class ResetUserPasswordComponent implements OnInit {
  UserId: number;
  Email: number;
  Password: string;
  CPassword: string;
  public resetUserPasswordForm: FormGroup;

  constructor(private userService: UsersService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.resetUserPasswordForm = new FormGroup({
      UserId: new FormControl(''),
      Email: new FormControl(''),
      Password: new FormControl(''),
      CPassword: new FormControl(''),
      IsResetLink: new FormControl(false)
  });

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.getUser(id);
      } else {
        this.getUser('0');
      }
    });

  }

  cancel() {
    this.router.navigate(['./', { outlets: { pages: ['user',
      this.resetUserPasswordForm.value.UserId]} }], { relativeTo: this.route.parent });
  }

  getUser(id: string) {
    this.userService.getUser(id).subscribe(
      (user: User) => this.editUser(user),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['user', id]} }], { relativeTo: this.route.parent });
      }
    );
  }

  editUser(user: User) {
    console.log(user);
    this.resetUserPasswordForm.patchValue({
      UserId: user.Id,
      Email: user.Email
    });
  }

  modify() {
    this.userService.resetUserPassword(this.resetUserPasswordForm.value).subscribe(
      (res: HttpResponse) => {
        if (res.result === HttpResult.Success) {
          if (this.resetUserPasswordForm.value.IsResetLink) {
            this.displayHTMLFeedback('success', 'Password reset link send to registered email!', '');
          } else {
            this.displayHTMLFeedback('success', 'Password updated successfully!', '');
          }
          this.router.navigate(['./', { outlets: { pages: ['user',
            this.resetUserPasswordForm.value.UserId]} }], { relativeTo: this.route.parent });
        } else {
          this.displayHTMLFeedback('warning', res.result + '!', res.message);
        }
      },
      err => {
        if (err.status === 400) {
          this.displayFeedback('warning', 'Incorrect old password', 'Authentication failed.!');
        } else {
          this.displayFeedback('error', 'Error!', err);
        }
      }
    );
  }

displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
