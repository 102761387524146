import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReceiptsService } from "../../core/services/receipts/receipts.service";
import { ReceiptApprovalList } from "src/app/core/models/receipts/Receipt-approval-list";
import { ReceiptApprovalSearch } from "src/app/core/models/receipts/receipt-approval-search";
import { concat, Observable, of, Subject } from "rxjs";
import { MemberList } from "src/app/core/models/members/member-List";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { MembersService } from "src/app/core/services/members/members.service";
import { Branch } from "src/app/core/models/masters/branch";
import { Unit } from "src/app/core/models/masters/unit";
import { BranchesService } from "src/app/core/services/masters/branches.service";
import { UnitsService } from "src/app/core/services/masters/units.service";
import { StatusUpdate } from "src/app/core/services/members/status-update";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";
import { Fbs } from "src/app/core/models/schemes/fbs";
import { Fine } from "src/app/core/models/schemes/fine";
import { Membership } from "src/app/core/models/schemes/membership";
import { FbsService } from "src/app/core/services/schemes/fbs.service";
import { FineService } from "src/app/core/services/schemes/fine.service";
import { MembershipsService } from "src/app/core/services/schemes/memberships.service";
import { Zone } from "src/app/core/models/masters/zone";
import { ZonesService } from "src/app/core/services/masters/zones.service";

@Component({
  selector: "app-receipt-approval",
  templateUrl: "./receipt-approval.component.html",
  styleUrls: ["./receipt-approval.component.css"],
})
export class ReceiptApprovalComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  FromDate: NgbDateStruct;
  ToDate: NgbDateStruct;
  listCount: any;
  columnTotal: number;
  hideApprove: boolean;
  public receiptForm: FormGroup;
  public receiptsList: FormArray;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  fbss$: Observable<Fbs[]>;
  memberships$: Observable<Membership[]>;
  fines$: Observable<Fine[]>;

  Type = [
    { Type: "", TypeName: "All" },
    { Type: "M", TypeName: "Membership" },
    { Type: "S", TypeName: "FBS" },
    { Type: "F", TypeName: "Fine" },
  ];

  constructor(
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private formBuilder: FormBuilder,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private memberService: MembersService,
    private receiptService: ReceiptsService,
    private fbsService: FbsService,
    private membershipService: MembershipsService,
    private fineService: FineService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.FromDate = this.calendar.getToday();
    this.ToDate = this.calendar.getToday();
    this.listCount = 0;
    this.columnTotal = 0;
    this.hideApprove = false;

    this.fbss$ = this.fbsService.getFbses();
    this.memberships$ = this.membershipService.getMemberships();
    this.fines$ = this.fineService.getFines();
    this.loadStarClubMembers();
    this.loadMembers();
    this.zones$ = this.zoneService.getZones();
    this.receiptForm = new FormGroup({
      FromDate: new FormControl(new Date()),
      ToDate: new FormControl(new Date()),
      Zone: new FormControl(0),
      ZoneName: new FormControl(""),
      Branch: new FormControl(0),
      BranchName: new FormControl(""),
      Unit: new FormControl(0),
      UnitName: new FormControl(""),
      Referrer: new FormControl({ Id: 0, Name: "" }),
      ReferrerName: new FormControl(""),
      Member: new FormControl({ Id: 0, Name: "" }),
      MemberName: new FormControl(""),
      Type: new FormControl(""),
      TypeId: new FormControl(0),
      TypeIdName: new FormControl(""),
      MemberType: new FormControl(""),
      Receipts: this.formBuilder.array([]),
    });
    this.receiptsList = this.receiptForm.get("Receipts") as FormArray;
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }

  approve() {
    let receiptList: any[];
    receiptList = this.receiptForm.value.Receipts;
    let updated: boolean;
    let errorMessage: string;
    let receipts: StatusUpdate[];
    receipts = [];
    for (const receipt of receiptList) {
      if (receipt.Selected) {
        const rec: StatusUpdate = {
          Id: receipt.Id,
          Status: receipt.NextApproval,
          EditedBy: "",
        };

        receipts.push({
          Id: receipt.Id,
          Status: receipt.NextApproval,
          EditedBy: "",
        });
      }
    }
    if (receipts.length > 0) {
      this.receiptService.approve(receipts).subscribe(
        (response: HttpResponse) => {
          console.log(response);
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            //this.reset();
            this.removeReceipt();
            this.hideApprove = true;
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.displayHTMLFeedback("info", "Please select atlease one receipt", "");
    }
  }

  reset() {
    this.receiptForm.reset({
      FromDate: new Date(),
      ToDate: new Date(),
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      Unit: 0,
      UnitName: "",
      Referrer: { Id: 0, Name: "" },
      ReferrerName: "",
      Member: { Id: 0, Name: "" },
      MemberName: "",
      Type: "",
      TypeId: 0,
      TypeIdName: "",
      MemberType: "",
    });
    this.hideApprove = false;
    this.removeReceiptOnReset();
  }

  getReceipts() {
    const searchFields: ReceiptApprovalSearch = {
      FromDate: this.receiptForm.value.FromDate,
      ToDate: this.receiptForm.value.ToDate,
      Zone: this.receiptForm.value.Zone,
      Branch: this.receiptForm.value.Branch,
      Unit: this.receiptForm.value.Unit,
      Referrer: this.receiptForm.value.Referrer.Id,
      Member: this.receiptForm.value.Member.Id,
      Type: this.receiptForm.value.Type,
      TypeId: this.receiptForm.value.TypeId,
      MemberType: this.receiptForm.value.MemberType,
    };
    this.removeReceiptOnReset();
    this.hideApprove = false;
    this.spinnerService.show();
    this.receiptService.getReceiptsForApproval(searchFields).subscribe(
      (response: ReceiptApprovalList[]) => {
        this.listCount = response.length;
        this.removeReceiptOnReset();
        this.createFormReceipt(response);
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  get receiptFormGroup() {
    return this.receiptForm.get("Receipts") as FormArray;
  }

  createFormReceipt(receipts: ReceiptApprovalList[]) {
    for (const receipt of receipts) {
      if (receipt.Status === "O") {
        if (receipt.NextApproval === "B" && receipt.IsBranchApprover) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        } else if (receipt.NextApproval === "Z" && receipt.IsZoneApprover) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        } else if (receipt.NextApproval === "A" && receipt.IsCentralApprover) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        } else if (
          receipt.NextApproval === "A" &&
          (receipt.IsBranchApprover ||
            receipt.IsZoneApprover ||
            receipt.IsCentralApprover)
        ) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        }
      } else if (receipt.Status === "B") {
        if (receipt.NextApproval === "Z" && receipt.IsZoneApprover) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        } else if (
          receipt.NextApproval === "A" &&
          (receipt.IsZoneApprover || receipt.IsCentralApprover)
        ) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        }
      } else if (receipt.Status === "Z") {
        if (receipt.NextApproval === "A" && receipt.IsCentralApprover) {
          this.receiptsList.push(
            this.createReceiptWithValues(
              receipt.Id,
              receipt.Type,
              receipt.ReceiptNumber,
              receipt.ReceiptDate,
              receipt.ReferenceNumber,
              receipt.Member,
              receipt.MemberName,
              receipt.KKMAId,
              receipt.CreatedByName,
              receipt.CreatedByKKMAId,
              receipt.Amount,
              receipt.Description,
              receipt.Status,
              receipt.IsBranchApprover,
              receipt.IsZoneApprover,
              receipt.IsCentralApprover,
              receipt.NextApproval
            )
          );
        }
      }
    }
  }

  createReceiptWithValues(
    Id: number,
    Type: string,
    ReceiptNumber: string,
    ReceiptDate: Date,
    ReferenceNumber: string,
    Member: number,
    MemberName: string,
    KKMAId: string,
    CreatedByName: string,
    CreatedByKKMAId: string,
    Amount: number,
    Description: string,
    Status: string,
    IsBranchApprover: boolean,
    IsZoneApprover: boolean,
    IsCentralApprover: boolean,
    NextApproval: string
  ): FormGroup {
    return this.formBuilder.group({
      Selected: new FormControl(false),
      Id: new FormControl(Id),
      Type: new FormControl(Type),
      ReceiptNumber: new FormControl(ReceiptNumber),
      ReceiptDate: new FormControl(ReceiptDate),
      ReferenceNumber: new FormControl(ReferenceNumber),
      Member: new FormControl(Member),
      MemberName: new FormControl(MemberName),
      KKMAId: new FormControl(KKMAId),
      CreatedByName: new FormControl(CreatedByName),
      CreatedByKKMAId: new FormControl(CreatedByKKMAId),
      Amount: new FormControl(Amount.toFixed(3)),
      Description: new FormControl(Description),
      Status: new FormControl(Status),
      IsBranchApprover: new FormControl(IsBranchApprover),
      IsZoneApprover: new FormControl(IsZoneApprover),
      IsCentralApprover: new FormControl(IsCentralApprover),
      NextApproval: new FormControl(NextApproval),
    });
  }

  removeReceiptOnReset() {
    for (let i = this.receiptForm.value.Receipts.length - 1; i >= 0; i--) {
      this.receiptsList.removeAt(i);
    }
  }

  removeReceipt() {
    this.receiptForm.value.Receipts.forEach((receipt, index) => {
      if (!receipt.Selected) {
        this.receiptsList.removeAt(index);
      }
    });
    /*
    let receiptList: any[];
    receiptList = this.receiptForm.value.Receipts;
    let index = 0;
    for (const receipt of receiptList) {
      console.log(receipt);
      if (receipt.Selected) {
        this.receiptsList.removeAt(index);
      }
      index = index + 1;
    }
    */
  }

  calculateTotal() {
    let receiptList: any[];
    receiptList = this.receiptForm.value.Receipts;
    this.columnTotal = 0;
    for (const invoice of receiptList) {
      if (invoice.Selected) this.columnTotal += parseFloat(invoice.Amount);
    }
  }

  selectUnselect() {
    let receiptList: any[];
    receiptList = this.receiptForm.value.Receipts;
    this.columnTotal = 0;
    for (const invoice of receiptList) {
      invoice.Selected = !invoice.Selected;
    }
    this.receiptForm.patchValue({
      Receipts: receiptList,
    });
    this.calculateTotal();
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Receipt - Approval");

    XLSX.writeFile(wb, "Receipt - Approval.xlsx");
  }
  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
