import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CollectionSummaryComponent } from "./collection-summary/collection-summary.component";
import { CoreModule } from "src/app/core/core.module";
import { CollectionSheetComponent } from "./collection-sheet/collection-sheet.component";
import { StarClubPerfomanceComponent } from "./star-club-perfomance/star-club-perfomance.component";
import { SchemeSummaryComponent } from "./scheme-summary/scheme-summary.component";

@NgModule({
  declarations: [
    CollectionSummaryComponent,
    CollectionSheetComponent,
    StarClubPerfomanceComponent,
    SchemeSummaryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
})
export class FbsModule {}
