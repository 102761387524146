import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";

import { Member } from "../../../core/models/members/member";
import { Zone } from "../../../core/models/masters/zone";
import { Branch } from "../../../core/models/masters/branch";
import { Unit } from "../../../core/models/masters/unit";
import { MembersService } from "../../../core/services/members/members.service";
import { ZonesService } from "../../../core/services/masters/zones.service";
import { BranchesService } from "../../../core/services/masters/branches.service";
import { UnitsService } from "../../../core/services/masters/units.service";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MembersList } from "../../../core/models/reports/members/members-list";
import { MemberList } from "src/app/core/models/members/member-List";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-memebers-list",
  templateUrl: "./memebers-list.component.html",
  styleUrls: ["./memebers-list.component.css"],
})
export class MemebersListComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  Referrer: number;
  ReferrerName: string;
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  memberList: MembersList[];
  listCount: any;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  userDetails: UserDetails;
  menuId: number;

  memebersListForm = new FormGroup({
    Zone: new FormControl(0),
    ZoneName: new FormControl(""),
    Branch: new FormControl(0),
    BranchName: new FormControl(""),
    Unit: new FormControl(0),
    UnitName: new FormControl(""),
    Referrer: new FormControl({ Id: 0, Name: "" }),
    ReferrerName: new FormControl(""),
    Status: new FormControl(""),
  });

  Status = [
    { Status: "X", StatusName: "All" },
    { Status: "A", StatusName: "Active" },
    { Status: "I", StatusName: "Inactive" },
    { Status: "E", StatusName: "Expired" },
    { Status: "C", StatusName: "Cancelled" },
    { Status: "O", StatusName: "Cancelled - Overseas" },
    { Status: "V", StatusName: "On Vacation" },
  ];

  constructor(
    private memberService: MembersService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.zones$ = this.zoneService.getZones();

    this.loadStarClubMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 81200;

    this.listCount = 0;
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }

  resetFilters() {
    this.memebersListForm.reset({
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      Unit: 0,
      UnitName: "",
      Referrer: { Id: 0, Name: "" },
      ReferrerName: "",
      Status: "",
    });
    this.Referrer = 0;
    this.ReferrerName = "";
  }

  setSelectedItem($event) {
    this.memebersListForm.patchValue({
      Referrer: $event.item.Id,
      ReferrerName: $event.item.FullName,
    });
  }

  getMemberListByOrg() {
    if (this.memebersListForm.value.Referrer === null) {
      this.memebersListForm.value.Referrer = { Id: 0, Name: "" };
    } else {
      if (this.memebersListForm.value.Referrer === 0) {
        this.memebersListForm.value.Referrer = { Id: 0, Name: "" };
      }
    }
    this.spinnerService.show();
    this.reportGeneratorService
      .getMemberList(
        this.memebersListForm.value.Zone,
        this.memebersListForm.value.Branch,
        this.memebersListForm.value.Unit,
        this.memebersListForm.value.Referrer.Id,
        this.memebersListForm.value.Status
      )
      .subscribe(
        (response: MembersList[]) => {
          this.memberList = response;
          this.listCount = this.memberList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members List");

    XLSX.writeFile(wb, "Members List.xlsx");
  }
}
