import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MembershipHistoryComponent } from "./membership-history/membership-history.component";
import { MembershipByMemberComponent } from "./membership-by-member/membership-by-member.component";
import { FbsHistoryComponent } from "./fbs-history/fbs-history.component";
import { FbsByMemberComponent } from "./fbs-by-member/fbs-by-member.component";
import { SchemesHistoryComponent } from "./schemes-history/schemes-history.component";
import { SchemesByMemberComponent } from "./schemes-by-member/schemes-by-member.component";
import { CoreModule } from "src/app/core/core.module";
import { FbsDashboardComponent } from "./fbs-dashboard/fbs-dashboard.component";

@NgModule({
  declarations: [
    MembershipHistoryComponent,
    MembershipByMemberComponent,
    FbsHistoryComponent,
    FbsByMemberComponent,
    SchemesHistoryComponent,
    SchemesByMemberComponent,
    FbsDashboardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [
    MembershipHistoryComponent,
    MembershipByMemberComponent,
    FbsHistoryComponent,
    FbsByMemberComponent,
    SchemesHistoryComponent,
    SchemesByMemberComponent,
  ],
})
export class SchemesModule {}
