import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { MemberDetailsByMembershipWithFbs } from "../../../core/models/reports/receipts/member-details-by-membership-with-fbs";
import { MemberDetailsByMembershipWithFbsSearch } from "src/app/core/models/reports/receipts/member-details-by-membership-with-fbs-search";
import { UserDetails } from "src/app/core/models/system/user-details";
import { concat, Observable, of, Subject } from "rxjs";
import { Branch } from "src/app/core/models/masters/branch";
import { Unit } from "src/app/core/models/masters/unit";
import { MemberList } from "src/app/core/models/members/member-List";
import { Fbs } from "src/app/core/models/schemes/fbs";
import { Fine } from "src/app/core/models/schemes/fine";
import { Membership } from "src/app/core/models/schemes/membership";
import { BranchesService } from "src/app/core/services/masters/branches.service";
import { UnitsService } from "src/app/core/services/masters/units.service";
import { MembersService } from "src/app/core/services/members/members.service";
import { FbsService } from "src/app/core/services/schemes/fbs.service";
import { FineService } from "src/app/core/services/schemes/fine.service";
import { MembershipsService } from "src/app/core/services/schemes/memberships.service";
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { Zone } from "src/app/core/models/masters/zone";
import { ZonesService } from "src/app/core/services/masters/zones.service";
import Swal, { SweetAlertType } from "sweetalert2";

@Component({
  selector: "app-member-detail-membership-fbs",
  templateUrl: "./member-detail-membership-fbs.component.html",
  styleUrls: ["./member-detail-membership-fbs.component.css"],
})
export class MemberDetailMembershipFbsComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  FromDate: NgbDateStruct;
  ToDate: NgbDateStruct;
  memberList: MemberDetailsByMembershipWithFbs[];
  listCount: any;
  userDetails: UserDetails;
  menuId: number;
  public memberDetailForm: FormGroup;

  Referrer: number;
  ReferrerName: string;
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  fbss$: Observable<Fbs[]>;
  memberships$: Observable<Membership[]>;

  starclubLoading = false;
  starclub$: Observable<MemberList[]>;
  starclubInput$ = new Subject<string>();
  selectedStarClub: any;

  columns: any[];
  visibleColumns: any[];
  MemberStatus = [
    { MemberStatus: "", MemberStatusName: "All" },
    { MemberStatus: "A", MemberStatusName: "Active" },
    { MemberStatus: "N", MemberStatusName: "Not Active" },
  ];

  MembershipStatus = [
    { MembershipStatus: "", MembershipStatusName: "All" },
    { MembershipStatus: "P", MembershipStatusName: "Paid" },
    { MembershipStatus: "N", MembershipStatusName: "Not Paid" },
  ];

  FbsStatus = [
    { FbsStatus: "", FbsStatusName: "All" },
    { FbsStatus: "P", FbsStatusName: "Paid" },
    { FbsStatus: "N", FbsStatusName: "Not Paid" },
  ];
  constructor(
    private calendar: NgbCalendar,
    private ngbDateAdapter: NgbStringAdapterService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private memberService: MembersService,
    private fbsService: FbsService,
    private membershipService: MembershipsService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.FromDate = this.calendar.getToday();
    this.ToDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 82800;

    this.listCount = 0;
    this.loadStarClubMembers();

    this.zones$ = this.zoneService.getZones();
    this.fbss$ = this.fbsService.getFbses();
    this.memberships$ = this.membershipService.getMemberships();

    this.memberDetailForm = new FormGroup({
      Zone: new FormControl(0),
      ZoneName: new FormControl(""),
      Branch: new FormControl(0),
      BranchName: new FormControl(""),
      Unit: new FormControl(0),
      UnitName: new FormControl(""),
      Referrer: new FormControl({ Id: 0, Name: "" }),
      ReferrerName: new FormControl(""),
      Membership: new FormControl(0),
      MembershipName: new FormControl(""),
      Fbs: new FormControl(0),
      FbsName: new FormControl(""),
      MemberStatus: new FormControl(""),
      MembershipStatus: new FormControl(""),
      FbsStatus: new FormControl(""),
    });
  }

  resetFilters() {
    this.memberDetailForm.reset({
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      Unit: 0,
      UnitName: "",
      Referrer: { Id: 0, Name: "" },
      ReferrerName: "",
      Membership: 0,
      MembershipName: "",
      Fbs: 0,
      FbsName: "",
      MemberStatus: "",
      MembershipStatus: "",
      FbsStatus: "",
    });
  }

  private loadStarClubMembers() {
    this.starclub$ = concat(
      of([]),
      this.starclubInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.starclubLoading = true)),
        switchMap((term) =>
          this.memberService.getStarClubMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.starclubLoading = false))
          )
        )
      )
    );
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }

  getMember() {
    if (this.memberDetailForm.value.Membership === 0) {
      this.displayHTMLFeedback("warning", "Please select a membership!", "");
    } else if (
      this.memberDetailForm.value.Branch === 0 &&
      this.memberDetailForm.value.Referrer.Id === 0
    ) {
      this.displayHTMLFeedback(
        "warning",
        "Please select a branch/star club!",
        ""
      );
    } else {
      if (this.memberDetailForm.value.Referrer === null) {
        this.memberDetailForm.value.Referrer = { Id: 0, Name: "" };
      } else {
        if (this.memberDetailForm.value.Referrer === 0) {
          this.memberDetailForm.value.Referrer = { Id: 0, Name: "" };
        }
      }
      const searchFields: MemberDetailsByMembershipWithFbsSearch = {
        Zone: this.memberDetailForm.value.Zone,
        Branch: this.memberDetailForm.value.Branch,
        Unit: this.memberDetailForm.value.Unit,
        Referrer: this.memberDetailForm.value.Referrer.Id,
        Membership: this.memberDetailForm.value.Membership,
        Fbs: this.memberDetailForm.value.Fbs,
        MemberStatus: this.memberDetailForm.value.MemberStatus,
        MembershipStatus: this.memberDetailForm.value.MembershipStatus,
        FbsStatus: this.memberDetailForm.value.FbsStatus,
      };
      this.spinnerService.show();
      this.reportGeneratorService
        .getMemberDetailsByMembershipWithFbs1(searchFields)
        .subscribe(
          (response: any) => {
            console.log(response);
            this.memberList = response.Rows;
            this.listCount = this.memberList.length;
            this.columns = response.Columns as any[];
            this.visibleColumns = this.columns
              .filter((a) => a.Visible)
              .sort((a, b) => a.SortOrder - b.SortOrder);
            this.spinnerService.hide();
          },
          (err: any) => {
            console.log(err);
            this.spinnerService.hide();
          }
        );
    }
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Member Details By Membership");

    XLSX.writeFile(wb, "Member Details By Membership With Fbs.xlsx");
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
