import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { Branch } from "../../../core/models/masters/branch";
import { Unit } from "../../../core/models/masters/unit";
import { MembersService } from "../../../core/services/members/members.service";
import { ZonesService } from "../../../core/services/masters/zones.service";
import { BranchesService } from "../../../core/services/masters/branches.service";
import { UnitsService } from "../../../core/services/masters/units.service";

import { ReportGeneratorService } from "../../../core/services/reports/report-generator.services";
import { TalentsService } from "src/app/core/services/masters/talents.service";
import { Talent } from "src/app/core/models/masters/talent";
import { MemberTalent } from "../../../core/models/reports/members/member-talent";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Zone } from "src/app/core/models/masters/zone";

@Component({
  selector: "app-member-talent",
  templateUrl: "./member-talent.component.html",
  styleUrls: ["./member-talent.component.css"],
})
export class MemberTalentComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  zones$: Observable<Zone[]>;
  branches$: Observable<Branch[]>;
  units$: Observable<Unit[]>;
  talents$: Observable<Talent[]>;
  memberList: MemberTalent[];
  listCount: any;

  userDetails: UserDetails;
  menuId: number;

  memebersListForm = new FormGroup({
    Zone: new FormControl(0),
    ZoneName: new FormControl(""),
    Branch: new FormControl(0),
    BranchName: new FormControl(""),
    Unit: new FormControl(0),
    UnitName: new FormControl(""),
    Talent: new FormControl(0),
    TalentName: new FormControl(""),
  });

  constructor(
    private memberService: MembersService,
    private zoneService: ZonesService,
    private branchService: BranchesService,
    private unitService: UnitsService,
    private talentService: TalentsService,
    private reportGeneratorService: ReportGeneratorService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.zones$ = this.zoneService.getZones();
    this.talents$ = this.talentService.getTalents();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 80300;

    this.listCount = 0;
  }

  getBranches(zone: number) {
    this.branches$ = this.branchService.getZoneBranches(zone);
    this.units$ = this.unitService.getBranchUnits(0);
  }

  getUnits(branch: number) {
    this.units$ = this.unitService.getBranchUnits(branch);
  }

  resetFilters() {
    this.memebersListForm.reset({
      Zone: 0,
      ZoneName: "",
      Branch: 0,
      BranchName: "",
      Unit: 0,
      UnitName: "",
      Talent: 0,
      TalentName: "",
    });
  }

  GetMemberByTalent() {
    this.spinnerService.show();
    this.reportGeneratorService
      .GetMemberByTalent(
        this.memebersListForm.value.Zone,
        this.memebersListForm.value.Branch,
        this.memebersListForm.value.Unit,
        this.memebersListForm.value.Talent
      )
      .subscribe(
        (response: MemberTalent[]) => {
          this.memberList = response;
          this.listCount = this.memberList.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasExport() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members By Talent");

    XLSX.writeFile(wb, "Members By Talent.xlsx");
  }
}
