import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormArray, FormBuilder } from "@angular/forms";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  min,
} from "rxjs/operators";
import { Observable, concat, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import Swal, { SweetAlertType } from "sweetalert2";

import { Membership, MSSchemes } from "../../core/models/schemes/membership";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { MembershipsService } from "../../core/services/schemes/memberships.service";
import { Scheme } from "../../core/models/masters/scheme";
import { SchemesService } from "../../core//services/masters/schemes.service";
import { Member } from "../../core/models/members/member";
import { MembersService } from "../../core//services/members/members.service";
import { MemberList } from "src/app/core/models/members/member-List";
import { StatusUpdate } from "src/app/core/services/members/status-update";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-membership",
  templateUrl: "./membership.component.html",
  styleUrls: ["./membership.component.css"],
})
export class MembershipComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;
  Date: NgbDateStruct;
  FromDate: NgbDateStruct;
  ToDate: NgbDateStruct;

  public membershipForm: FormGroup;
  public schemeList: FormArray;
  approvedStatus: boolean;
  closedStatus: boolean;
  cancelledStatus: boolean;

  schemes$: Observable<Scheme[]>;
  members$: Member[];

  Id: number;
  ZeroValue = 0;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private membershipService: MembershipsService,
    private schemeService: SchemesService,
    private memberService: MembersService,
    private ngbDateAdapter: NgbStringAdapterService,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.Id = 0;
    this.Date = this.calendar.getToday();
    this.FromDate = this.calendar.getToday();
    this.ToDate = this.calendar.getToday();

    this.membershipForm = new FormGroup({
      Id: new FormControl(0),
      Name: new FormControl(""),
      Date: new FormControl(new Date()),
      FromDate: new FormControl(new Date()),
      ToDate: new FormControl(new Date()),
      Description: new FormControl(""),
      CentralAmount: new FormControl(0),
      ZoneAmount: new FormControl(0),
      BranchAmount: new FormControl(0),
      UnitAmount: new FormControl(0),
      MembershipAmount: new FormControl(this.ZeroValue.toFixed(3)),
      SchemesAmount: new FormControl(0),
      TotalAmount: new FormControl(this.ZeroValue.toFixed(3)),
      Status: new FormControl("O"),
      StatusName: new FormControl("Open"),
      schemes: this.formBuilder.array([]),
    });
    this.schemeList = this.membershipForm.get("schemes") as FormArray;

    this.schemes$ = this.schemeService.getSchemes();

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 30000;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getMembership(id);
      }
    });
  }

  cancelMembership() {
    this.router.navigate(["./", { outlets: { pages: ["memberships"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getMembership(Id: number) {
    this.membershipService.getMembership(Id).subscribe(
      (membership: Membership) => this.editMembership(membership),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["memberships"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editMembership(membership: Membership) {
    this.membershipForm.patchValue({
      Id: membership.Id,
      Name: membership.Name,
      Date: membership.Date,
      FromDate: membership.FromDate,
      ToDate: membership.ToDate,
      Description: membership.Description,
      CentralAmount: membership.CentralAmount.toFixed(3),
      ZoneAmount: membership.ZoneAmount.toFixed(3),
      BranchAmount: membership.BranchAmount.toFixed(3),
      UnitAmount: membership.UnitAmount.toFixed(3),
      MembershipAmount: membership.MembershipAmount.toFixed(3),
      SchemesAmount: membership.SchemesAmount,
      TotalAmount: membership.TotalAmount.toFixed(3),
      Status: membership.Status,
      StatusName:
        membership.Status === "O"
          ? "Open"
          : membership.Status === "A"
          ? "Approved"
          : membership.Status === "C"
          ? "Closed"
          : membership.Status === "L"
          ? "Cancelled"
          : "Open",
      schemes: [],
    });
    this.createFormScheme(membership);
    this.Date = this.ngbDateAdapter.fromModel(new Date(membership.Date));
    this.FromDate = this.ngbDateAdapter.fromModel(
      new Date(membership.FromDate)
    );
    this.ToDate = this.ngbDateAdapter.fromModel(new Date(membership.ToDate));
    this.statusMenuVisible(membership.Id, membership.Status);
  }

  saveMembership(reset: boolean) {
    const membership: Membership = {
      Id: this.membershipForm.value.Id,
      Name: this.membershipForm.value.Name,
      Date: this.membershipForm.value.Date,
      FromDate: this.membershipForm.value.FromDate,
      ToDate: this.membershipForm.value.ToDate,
      Description: this.membershipForm.value.Description,
      CentralAmount: this.membershipForm.value.CentralAmount,
      ZoneAmount: this.membershipForm.value.ZoneAmount,
      BranchAmount: this.membershipForm.value.BranchAmount,
      UnitAmount: this.membershipForm.value.UnitAmount,
      MembershipAmount: this.membershipForm.value.MembershipAmount,
      SchemesAmount: this.membershipForm.value.SchemesAmount,
      TotalAmount: this.membershipForm.value.TotalAmount,
      Status: this.membershipForm.value.Status,
      CreatedBy: "",
      CreatedOn: new Date(),
      EditedBy: "",
      EditedOn: new Date(),
      Schemes: null, // this.membershipForm.value.schemes
    };

    const SchemesList = this.membershipForm.value.schemes;

    const schemes: MSSchemes[] = [];

    for (const scheme of SchemesList) {
      const c: MSSchemes = {
        MSId: scheme.MSId,
        MSScheme: scheme.MSScheme,
        MSSchemeName: scheme.MSSchemeName,
        MSMember: scheme.MSMember.Id,
        MSMemberName: scheme.MSMember.Name,
        MSAmount: scheme.MSAmount,
        MSIsOneTime: scheme.MSIsOneTime,
      };
      schemes.push(c);
    }
    membership.Schemes = schemes;

    if (membership.Id < 1) {
      console.log(membership);
      this.membershipService.addMembership(membership).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMembership();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["memberships"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.membershipService.modifyMembership(membership).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMembership();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["memberships"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  updateStatus(stat) {
    if (this.membershipForm.value.Id < 1) {
      this.displayFeedback(
        "error",
        "Error!",
        "Please select a membership to update status!"
      );
    } else {
      const transfer: StatusUpdate = {
        Id: this.membershipForm.value.Id,
        Status: stat,
        EditedBy: "",
      };

      this.membershipService.modifyStatus(transfer).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Status Updated Successfully!", "");
            this.membershipForm.patchValue({
              Status: stat,
              StatusName:
                stat === "O"
                  ? "Open"
                  : stat === "A"
                  ? "Approved"
                  : stat === "C"
                  ? "Closed"
                  : stat === "L"
                  ? "Cancelled"
                  : "Open",
            });
            this.statusMenuVisible(transfer.Id, stat);
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetMembership() {
    this.membershipForm.reset({
      Id: 0,
      Name: "",
      FromDate: new Date(),
      ToDate: new Date(),
      Description: "",
      CentralAmount: 0,
      ZoneAmount: 0,
      BranchAmount: 0,
      UnitAmount: 0,
      MembershipAmount: 0,
      SchemesAmount: 0,
      TotalAmount: 0,
      Status: "O",
      StatusName: "Open",
    });
    this.removeSchemesOnReset();
  }

  get schemeFormGroup() {
    return this.membershipForm.get("schemes") as FormArray;
  }

  createFormScheme(schemes: Membership) {
    // for (let i = 0; i < schemes.Schemes.length; i++) {
    //   this.schemeList.push(this.createSchemeWithValues(schemes.Schemes[i].MSId,
    //     schemes.Schemes[i].MSScheme, schemes.Schemes[i].MSSchemeName,
    //     schemes.Schemes[i].MSAmount, schemes.Schemes[i].MSIsOneTime));
    // }
    console.log(schemes);
    for (const scheme of schemes.Schemes) {
      this.schemeList.push(
        this.createSchemeWithValues(
          scheme.MSId,
          scheme.MSScheme,
          scheme.MSSchemeName,
          scheme.MSMember,
          scheme.MSMemberName,
          scheme.MSAmount,
          scheme.MSIsOneTime
        )
      );
    }
  }

  createSchemeWithValues(
    MSId: number,
    MSScheme: number,
    MSSchemeName: string,
    MSMember: number,
    MSMemberName: string,
    MSAmount: number,
    MSIsOneTime: boolean
  ): FormGroup {
    console.log(MSIsOneTime);
    return this.formBuilder.group({
      MSId: new FormControl(MSId),
      MSScheme: new FormControl(MSScheme),
      MSSchemeName: new FormControl(MSSchemeName),
      MSMember: new FormControl({ Id: MSMember, Name: MSMemberName }),
      MSMemberName: new FormControl(MSMemberName),
      MSAmount: new FormControl(MSAmount.toFixed(3)),
      MSIsOneTime: new FormControl(MSIsOneTime),
    });
  }

  createScheme(): FormGroup {
    return this.formBuilder.group({
      MSId: new FormControl(0),
      MSScheme: new FormControl(0),
      MSSchemeName: new FormControl(""),
      MSMember: new FormControl({ Id: 0, Name: "" }),
      MSMemberName: new FormControl(""),
      MSAmount: new FormControl(0),
      MSIsOneTime: new FormControl(true),
    });
  }

  addScheme() {
    this.schemeList.push(this.createScheme());
  }

  removeSchemesOnReset() {
    for (let i = this.membershipForm.value.schemes.length - 1; i >= 0; i--) {
      this.removeScheme(i);
    }
  }

  removeScheme(index) {
    this.schemeList.removeAt(index);
    this.calculateMembershipAmountTotal();
  }

  calculateAmount() {
    if (
      this.membershipForm.value.CentralAmount === null ||
      this.membershipForm.value.CentralAmount === "" ||
      this.membershipForm.value.CentralAmount === "."
    ) {
      this.membershipForm.value.CentralAmount = 0;
    }
    if (
      this.membershipForm.value.ZoneAmount === null ||
      this.membershipForm.value.ZoneAmount === "" ||
      this.membershipForm.value.ZoneAmount === "."
    ) {
      this.membershipForm.value.ZoneAmount = 0;
    }
    if (
      this.membershipForm.value.BranchAmount === null ||
      this.membershipForm.value.BranchAmount === "" ||
      this.membershipForm.value.BranchAmount === "."
    ) {
      this.membershipForm.value.BranchAmount = 0;
    }
    if (
      this.membershipForm.value.UnitAmount === null ||
      this.membershipForm.value.UnitAmount === "" ||
      this.membershipForm.value.UnitAmount === "."
    ) {
      this.membershipForm.value.UnitAmount = 0;
    }
    if (
      this.membershipForm.value.SchemesAmount === null ||
      this.membershipForm.value.SchemesAmount === "" ||
      this.membershipForm.value.SchemesAmount === "."
    ) {
      this.membershipForm.value.SchemesAmount = 0;
    }

    this.membershipForm.patchValue({
      MembershipAmount: (
        parseFloat(this.membershipForm.value.CentralAmount) +
        parseFloat(this.membershipForm.value.ZoneAmount) +
        parseFloat(this.membershipForm.value.BranchAmount) +
        parseFloat(this.membershipForm.value.UnitAmount)
      ).toFixed(3),
      TotalAmount: (
        parseFloat(this.membershipForm.value.CentralAmount) +
        parseFloat(this.membershipForm.value.ZoneAmount) +
        parseFloat(this.membershipForm.value.BranchAmount) +
        parseFloat(this.membershipForm.value.UnitAmount) +
        parseFloat(this.membershipForm.value.SchemesAmount)
      ).toFixed(3),
    });
  }

  calculateMembershipAmountTotal() {
    let schemesList: any[];
    let totalAmount: number;
    totalAmount = 0;
    schemesList = this.membershipForm.value.schemes;
    for (const scheme of schemesList) {
      if (
        scheme.MSAmount === null ||
        scheme.MSAmount === "" ||
        scheme.MSAmount === "."
      ) {
        scheme.MSAmount = 0;
      }
      totalAmount += parseFloat(scheme.MSAmount);
    }

    this.membershipForm.patchValue({
      SchemesAmount: totalAmount,
    });
    this.calculateAmount();
  }

  setMemberFormValues(row) {
    let schemesList: any[];
    let count: number;
    count = 0;
    schemesList = this.membershipForm.value.schemes;
    for (const scheme of schemesList) {
      if (count === row) {
        if (scheme.MSMember == null) {
          scheme.MSMember = { Id: 0, Name: "" };
        }
        // scheme.MSMember = $event.item.Id;
        // scheme.MSMemberName = $event.item.FullName;
      }
      count += 1;
    }
    this.membershipForm.patchValue({
      Schemes: schemesList,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  statusMenuVisible(id, status) {
    if (id < 0) {
      this.approvedStatus = false;
      this.closedStatus = false;
      this.cancelledStatus = false;
    } else {
      if (status === "O") {
        this.approvedStatus = true;
        this.cancelledStatus = true;
        this.closedStatus = false;
      } else if (status === "A") {
        this.approvedStatus = false;
        this.closedStatus = true;
        this.cancelledStatus = false;
      } else {
        this.approvedStatus = false;
        this.closedStatus = false;
        this.cancelledStatus = false;
      }
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.Menus.forEach((value) => {
      if (value.Id === this.menuId) {
        if (value.PrivilegeAdd && this.membershipForm.value.Id < 1)
          privilage = true;
        else if (value.PrivilegeModify && this.membershipForm.value.Id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
