import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { RoleComponent } from "./role/role.component";
import { RolesComponent } from "./roles/roles.component";
import { UserComponent } from "./user/user.component";
import { UsersComponent } from "./users/users.component";
import { SettingsComponent } from "./settings/settings.component";
import { ResetUserPasswordComponent } from "./reset-user-password/reset-user-password.component";
import { VendorComponent } from './vendor/vendor.component';
import { VendorsComponent } from './vendors/vendors.component';

@NgModule({
  declarations: [
    RoleComponent,
    RolesComponent,
    UserComponent,
    UsersComponent,
    SettingsComponent,
    ResetUserPasswordComponent,
    VendorComponent,
    VendorsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgbModule.forRoot(),
    NgSelectModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [RolesComponent, UsersComponent, SettingsComponent],
})
export class SystemModule {}
