import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { debounceTime, map, distinctUntilChanged, tap, switchMap, catchError} from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbStringAdapterService } from 'src/app/shared/services/ngb-string-adapter.service';
import { HttpResponse, HttpResult } from 'src/app/core/models/utils/http-response';
import { Observable, Subject, concat, of } from 'rxjs';
import Swal, { SweetAlertType } from 'sweetalert2';

import { Fbs, FBSchemes } from '../../core/models/schemes/fbs';
import { FbsService } from '../../core/services/schemes/fbs.service';
import { Scheme } from '../../core/models/masters/scheme';
import { SchemesService } from '../../core//services/masters/schemes.service';
import { Member } from '../../core/models/members/member';
import { MembersService } from '../../core//services/members/members.service';
import { MemberList } from 'src/app/core/models/members/member-List';
import { StatusUpdate } from 'src/app/core/services/members/status-update';
import { UserDetails } from 'src/app/core/models/system/user-details';

@Component({
  selector: 'app-fbs',
  templateUrl: './fbs.component.html',
  styleUrls: ['./fbs.component.css']
})
export class FbsComponent implements OnInit {

  userDetails: UserDetails;
  menuId: number;

  public fbsForm: FormGroup;
  public schemeList: FormArray;
  approvedStatus: boolean;
  closedStatus: boolean;
  cancelledStatus: boolean;

  schemes$: Observable<Scheme[]>;
  members$: Member[];

  Id: number;
  ZeroValue = 0;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(private fbsService: FbsService, private schemeService: SchemesService,
              private memberService: MembersService,
              private ngbDateAdapter: NgbStringAdapterService,
              private formBuilder: FormBuilder, private calendar: NgbCalendar,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.Id = 0;

    this.fbsForm = new FormGroup({
      Id: new FormControl(0),
      SchemeNumber: new FormControl(''),
      SchemeDate: new FormControl(new Date()),
      AsonDate: new FormControl(new Date()),
      Description: new FormControl(''),
      SchemePeriod: new FormControl(0),
      GracePeriod: new FormControl(0),
      Amount: new FormControl(this.ZeroValue.toFixed(3)),
      Status: new FormControl('O'),
      StatusName: new FormControl('Open'),
      schemes: this.formBuilder.array([])
  });
    this.schemeList = this.fbsForm.get('schemes') as FormArray;

    this.schemes$ = this.schemeService.getSchemes();

    this.loadMembers();
     this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.menuId = 40000;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      console.log(id);
      if (id) {
        this.getFbs(id);
      }
    });
  }

  cancelFbs() {
    this.router.navigate(['./', { outlets: { pages: ['fbss']} }], { relativeTo: this.route.parent });
  }

  getFbs(Id: number) {
    this.fbsService.getFbs(Id).subscribe(
      (fbs: Fbs) => this.editFbs(fbs),
      (err: any) => {
        this.displayFeedback('error', 'Error!', 'Something went wrong. Try again!');
        this.router.navigate(['./', { outlets: { pages: ['fbss']} }], { relativeTo: this.route.parent });
      }
    );
  }

  editFbs(fbs: Fbs) {
    this.fbsForm.patchValue({
      Id: fbs.Id,
      SchemeNumber: fbs.SchemeNumber,
      SchemeDate: fbs.SchemeDate,
      AsonDate: fbs.AsonDate,
      Description: fbs.Description,
      SchemePeriod: fbs.SchemePeriod,
      GracePeriod: fbs.GracePeriod,
      Amount: fbs.Amount.toFixed(3),
      Status: fbs.Status,
      StatusName: fbs.Status === 'O' ? 'Open' :
                  fbs.Status === 'A' ? 'Approved' :
                  fbs.Status === 'C' ? 'Closed' :
                  fbs.Status === 'L' ? 'Cancelled' : 'Open',
      schemes: []
    });
    this.createFormScheme(fbs);
    this.statusMenuVisible(fbs.Id, fbs.Status);
  }

  saveFbs(reset: boolean) {
    const fbs: Fbs = {
      Id: this.fbsForm.value.Id,
      SchemeNumber: this.fbsForm.value.SchemeNumber,
      SchemeDate: this.fbsForm.value.SchemeDate,
      AsonDate: this.fbsForm.value.AsonDate,
      Description: this.fbsForm.value.Description,
      SchemePeriod: this.fbsForm.value.SchemePeriod,
      GracePeriod: this.fbsForm.value.GracePeriod,
      Amount: this.fbsForm.value.Amount,
      Status: this.fbsForm.value.Status,
      CreatedBy: '',
      CreatedOn: new Date(),
      EditedBy: '',
      EditedOn: new Date(),
      Schemes: null // this.fbsForm.value.schemes
    };

    const SchemesList = this.fbsForm.value.schemes;

    const schemes: FBSchemes[] = [];

    for (const scheme of SchemesList) {

      const c: FBSchemes = {
        FBId: scheme.FBId,
        FBScheme: scheme.FBScheme,
        FBSchemeName: scheme.FBSchemeName,
        FBMember: scheme.FBMember.Id,
        FBMemberName: scheme.FBMember.Name,
        FBAmount: scheme.FBAmount
      };
      schemes.push(c);
    }
    fbs.Schemes = schemes;

    if (fbs.Id < 1) {
      console.log(fbs);
      this.fbsService.addFbs(fbs)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetFbs();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['fbss']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      } else {
      this.fbsService.modifyFbs(fbs)
        .subscribe (
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback('success', 'Saved Successfully!', '');
              if (reset) {
                this.resetFbs();
              } else {
                this.router.navigate(['./', { outlets: { pages: ['fbss']} }], { relativeTo: this.route.parent });
              }
            } else {
              this.displayFeedback('warning', response.result + '!', response.message);
            }
          },
          (error) => {
            this.displayFeedback('error', 'Error!', error);
          }
        );
      }
  }

  updateStatus(stat) {
    if (this.fbsForm.value.Id < 1) {
      this.displayFeedback('error', 'Error!', 'Please select a FBS to update status!');
    } else {
      const transfer: StatusUpdate = {
        Id: this.fbsForm.value.Id,
        Status: stat,
        EditedBy: ''
      };

      this.fbsService.modifyStatus(transfer)
      .subscribe (
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback('success', 'Status Updated Successfully!', '');
            this.fbsForm.patchValue({
              Status: stat,
              StatusName: stat === 'O' ? 'Open' :
                          stat === 'A' ? 'Approved' :
                          stat === 'C' ? 'Closed' :
                          stat === 'L' ? 'Cancelled' : 'Open'
            });
            this.statusMenuVisible(transfer.Id, stat);
          } else {
            this.displayHTMLFeedback('warning', response.result + '!', response.message);
          }
        },
        (error) => {
          this.displayFeedback('error', 'Error!', error);
        }
      );
    }
  }

  resetFbs() {
    this.fbsForm.reset({
      Id: 0,
      SchemeNumber: '',
      SchemeDate: new Date(),
      AsonDate: new Date(),
      Description: '',
      SchemePeriod: 0,
      GracePeriod: 0,
      Amount: this.ZeroValue.toFixed(3),
      Status: 'O',
      StatusName: 'Open'
    });
    this. removeSchemesOnReset();
  }

  get schemeFormGroup() {
    return this.fbsForm.get('schemes') as FormArray;
  }

  createFormScheme(schemes: Fbs) {
      for (const scheme of schemes.Schemes) {
        this.schemeList.push(this.createSchemeWithValues(
              scheme.FBId, scheme.FBScheme, scheme.FBSchemeName,
              scheme.FBMember, scheme.FBMemberName, scheme.FBAmount));
      }
  }

  createSchemeWithValues(FBId: number, FBScheme: number, FBSchemeName: string,
                         FBMember: number, FBMemberName: string, FBAmount: number): FormGroup {
    return this.formBuilder.group({
      FBId:  new FormControl(FBId),
      FBScheme: new FormControl(FBScheme),
      FBSchemeName: new FormControl(FBSchemeName),
      FBMember: new FormControl({Id: FBMember, Name: FBMemberName}),
      FBMemberName: new FormControl(FBMemberName),
      FBAmount: new FormControl(FBAmount.toFixed(3))
    });
  }

  createScheme(): FormGroup {
    return this.formBuilder.group({
      FBId:  new FormControl(0),
      FBScheme: new FormControl(0),
      FBSchemeName: new FormControl(''),
      FBMember: new FormControl({Id: 0, Name: ''}),
      FBMemberName: new FormControl(''),
      FBAmount: new FormControl(0)
    });
  }

  addScheme() {
    this.schemeList.push(this.createScheme());
  }

  removeSchemesOnReset() {
    for (let i = this.fbsForm.value.schemes.length - 1; i >= 0; i--) {
      this.removeScheme(i);
    }
  }

  removeScheme(index) {
    this.schemeList.removeAt(index);
    this.calculateTotal();
  }

  calculateTotal() {
    let schemesList: any[];
    let totalAmount: number;
    totalAmount = 0;
    schemesList = this.fbsForm.value.schemes;
    for (const scheme of schemesList) {
      if (scheme.FBAmount === null || scheme.FBAmount === '' || scheme.FBAmount === '.') {
        scheme.FBAmount = 0;
      }
      totalAmount += parseFloat(scheme.FBAmount);
    }
    this.fbsForm.patchValue({
      Amount: totalAmount.toFixed(3)
    });
  }

  setMemberFormValues(row) {
    let schemesList: any[];
    let count: number;
    count = 0;
    schemesList = this.fbsForm.value.schemes;
    for (const scheme of schemesList) {
      if (count === row) {
        if (scheme.FBMember == null) {
          scheme.FBMember = {Id: 0, Name: '' };
        }
      }
      count += 1;
    }
    this.fbsForm.patchValue({
      Schemes: schemesList
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.memberLoading = true),
        switchMap(
          term => this.memberService.getAllMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => this.memberLoading = false)
          )
        )
      )
    );
  }

  statusMenuVisible(id, status) {
    if (id < 0) {
      this.approvedStatus = false;
      this.closedStatus = false;
      this.cancelledStatus = false;
    } else {
      if (status === 'O') {
        this.approvedStatus = true;
        this.cancelledStatus = true;
        this.closedStatus = false;
      } else if (status === 'A') {
        this.approvedStatus = false;
        this.closedStatus = true;
        this.cancelledStatus = false;
      } else {
        this.approvedStatus = false;
        this.closedStatus = false;
        this.cancelledStatus = false;
      }
    }
  }

  hasAdd() {
   let privilage = false;

    this.userDetails.Menus.forEach(value => {
       if (value.Id === this.menuId) {
         if(value.PrivilegeAdd && this.fbsForm.value.Id < 1)
            privilage = true;
          else if(value.PrivilegeModify && this.fbsForm.value.Id > 0)
            privilage = true;
       }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
